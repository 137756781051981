@import "~/app/helpers/helpers";

.heading {
  font: var(--f1);
  text-transform: uppercase;
  color: var(--darkGold);

  &.light {
    color: var(--whiteFaded);
  }
}

h1.heading {
  letter-spacing: 5px;
}

h2.heading {
  font: normal 1.6rem/1.4 "Calibre", sans-serif;
  letter-spacing: 2px;
}

h3.heading {
  font: normal 1.5rem/1.4 "Calibre", sans-serif;
}

h4.heading {
  font: normal 1.4rem/1.4 "Calibre", sans-serif;
}

h5.heading {
  font: normal 1.3rem/1.4 "Calibre", sans-serif;
}

h6.heading {
  font: normal 1.2rem/1.4 "Calibre", sans-serif;
}
