import React from "react"
import { gsap } from 'gsap'

import Cx from "classnames"
import styles from "./styles.scss"
import { isMobile } from '~/app/helpers.js'

const Burger = () => {
  return (
    <svg className={Cx(styles.burger)} viewBox="0 0 42 18.5">
      <defs>
        <style>{'.cls-1{fill:none;stroke:#957a3f;stroke-miterlimit:10;}'}</style>
      </defs>
      <title>burger2</title>
      <line className="cls-1" x1="19.03" y1="0.5" x2="3.57" y2="0.5" />
      <line className="cls-1" x1="19.03" y1="6.33" y2="6.33" />
      <line className="cls-1" x1="19.03" y1="12.17" x2="5.95" y2="12.17" />
      <line className="cls-1" x1="19.03" y1="18" x2="2.38" y2="18" />
      <line className="cls-1" x1="22.97" y1="0.5" x2="38.43" y2="0.5" />
      <line className="cls-1" x1="22.97" y1="6.33" x2="42" y2="6.33" />
      <line className="cls-1" x1="22.97" y1="12.17" x2="36.05" y2="12.17" />
      <line className="cls-1" x1="22.97" y1="18" x2="39.62" y2="18" />
    </svg>
  )
}

class Description extends React.Component {
  state = {
    over: false
  }
  componentDidMount() {
    let delay = 4.25

    if (isMobile()) {
      delay = 1
    }
  
    this._el.style.pointerEvents = 'none'
    this._el.style.opacity = '0'

    gsap.to(
      this._el,
      {
        opacity: 1,
        duration: .4,
        delay,
        onComplete: this.onCompleteAnimate,
        onCompleteParams: [this._el]
      }
    )
    gsap.to(
      this._text,
      {
        opacity: 1,
        duration: .4,
        delay: (delay + 0.25)
      }
    )
    gsap.fromTo(
      this._text,
      {
        color: '#ffffff', // everywhere else is pulling this color value from global.css
      },
      {
        color: '#957a3f', // everywhere else is pulling this color value from global.css
        duration: 3,
        delay: (delay + 0.25 + 0.25),
      }
    )
  }

  onCompleteAnimate = (el) => {
    el.style.pointerEvents = 'auto'

    this.setState({
      animationComplete: true
    })
  }

  onMouseClick = () => {
    if (isMobile()) {
      this.props.onDescToggle()
    }
  }

  onMouseEnter = () => {
    if (!isMobile()) {
      this.props.onDescToggle()
    }
  }

  onMouseLeave = () => {
    if (!isMobile()) {
      this.props.onDescToggle()
    }
  }

  render() {
    return (
      <div
        className={Cx(this.props.className, styles.description, {
          [styles.open]: this.props.descOpen,
          [styles.animationComplete]: this.state.animationComplete
        })}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onMouseClick}
        ref={c=>{this._el=c}}
      >

        <Burger />
        <div
          ref={c=>{this._text=c}}
          className={Cx(styles.descriptionText)}
          dangerouslySetInnerHTML={{ __html: this.props.children }}
        />
      </div>
    )
  }
}

export default Description
