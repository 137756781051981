/* eslint-disable prettier/prettier */
import React from "react"
import ReactFullpage from "@fullpage/react-fullpage"
import { gsap } from "gsap"

import Cx from "classnames"
import styles from "./styles.scss"
import Navigation from "~/app/components/Navigation"
import Video from "~/app/components/Video"
import Arrow from "~/app/components/Arrow"
import LogoTribe from "~/app/components/LogoTribe"
import TextBox from "~/app/components/TextBox"
import ImgBg from "~/app/components/ImgBg"
import background from "~/app/assets/images/bg.jpg"
import { fullPageJsLicense } from "~/app/utils/fullPageJsLicense"

const frames = {
  0: { start: 0, stop: 2.2 },
  1: { start: 2.45, stop: 7.2 },
  2: { start: 7.45, stop: 12.22 },
  3: { start: 12.7, stop: 17.75 },
  // 4: { start: 7.82, stop: 7.82}
}

export default class extends React.Component {
  state = {
    donePlayingVideo: false,
    firstLoad: true,
  }

  componentDidMount() {
    gsap.fromTo(
      document.getElementById("fp-nav"),
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 2.3,
        duration: 0.5,
      }
    )
  }

  afterLoad(origin, dest, direction) {
    if (origin.index == 0 && dest.index == 0) {
      this.stopAt(frames[0])
    }
  }

  onLeave(origin, dest, direction) {
    this.stopAt(frames[dest.index])
    this.setState({firstLoad: false})
  }

  stopAt = (time) => {
    if (time) {
      this.setState({ donePlayingVideo: false })
      this._player.currentTime = time["start"]
      this._stopAtTime = time["stop"]
      this._player.play()
      this._player.ontimeupdate = () => {
        if (this._player && this._player.currentTime >= this._stopAtTime) {
          this._player.pause()
          this.setState({ donePlayingVideo: true })
          // re-enable scroll
        }
      }
      this._player.style.transition = ".5s opacity"
      this._player.style.opacity = 1
    } else {
      this._player.pause()
      this._player.style.transition = ".5s opacity"
      this._player.style.opacity = 0
    }
  }

  render() {
    return (
      <div>
        <Navigation />
        <LogoTribe />
        <ImgBg
          src={background}
          className={styles.bg}
          readyClassName={styles.bgReady}
        />
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: "-1",
          }}
        >
          <Video
            // poster='https://i.vimeocdn.com/video/821929072_1920x1080.jpg?r=pad'
            src="https://bwing-dev.s3.amazonaws.com/render.mp4"
            height="1080"
            width="1920"
            isCurrentOrNext={true}
            innerRef={(c) => {
              this._player = c
            }}
          />
        </div>

        <ReactFullpage
          //fullpage options
          // scrollingSpeed = {1000} /* Options here */
          licenseKey={fullPageJsLicense}
          onLeave={this.onLeave.bind(this)}
          afterLoad={this.afterLoad.bind(this)}
          navigation
          credits={{enabled: false}}
          render={({ state, fullpageApi }) => {
            if (fullpageApi)
              fullpageApi.setAllowScrolling(this.state.donePlayingVideo)
            if (fullpageApi && this.state.firstLoad)
              fullpageApi.silentMoveTo(1)
            let currentSlide = (state.destination && !state.firstLoad) ? state.destination.index : 0

            return (
              <ReactFullpage.Wrapper>
                <div className="section">
                  <div className={Cx(styles.firstSection)}>
                    <TextBox
                      slideNumber={0}
                      currentSlide={currentSlide}
                      position={0}
                    >
                      TUNABLE LARGE FORMAT CINE LENSES
                    </TextBox>
                  </div>

                  <span
                    className={Cx(styles.arrowWrap, {
                      [styles.visible]:
                        this.state.donePlayingVideo && currentSlide === 0,
                    })}
                    onClick={() => fullpageApi.moveSectionDown()}
                  >
                    <Arrow className={Cx(styles.arrow)} />
                  </span>
                </div>
                <div className="section">
                  <div className="">
                    <TextBox
                      slideNumber={1}
                      currentSlide={currentSlide}
                      position={3}
                      // className={Cx(styles.pageText)}
                    >
                      <p>
                        Purpose-designed for{" "}
                        <span>large format motion picture</span> imaging,{" "}
                        <span>
                          BLACKWING<sup>7</sup>
                        </span>{" "}
                        lenses from TRIBE<sup>7</sup> represent an entirely new
                        range of tuned optics for contemporary film makers to
                        use within their creative story telling.
                      </p>
                      <br />
                      <p>
                        BLACKWING<sup>7</sup> optics exhibit unique imaging
                        properties which arise from the introduction of
                        controlled distortion during the development and
                        manufacturing processes. This distortion is modular and
                        allows a level of tuneability over key parameters which
                        shape many creative characteristics of the lenses.
                      </p>
                    </TextBox>
                    <span
                      className={Cx(styles.arrowWrap, {
                        [styles.visible]:
                          this.state.donePlayingVideo && currentSlide === 1,
                      })}
                      onClick={() => fullpageApi.moveSectionDown()}
                    >
                      <Arrow className={Cx(styles.arrow)} />
                    </span>
                  </div>
                </div>
                <div className="section">
                  <div className="">
                    <TextBox
                      slideNumber={2}
                      currentSlide={currentSlide}
                      position={5}
                    >
                      <p>
                        The lens range comprises nine focal lengths:
                        a core set of seven plus two additional wide lenses,
                        configurable and customisable at point of manufacture.
                      </p>
                      <br />
                      <p>
                        BLACKWING<sup>7</sup> lens tuning allows parameters such
                        as sharpness, contrast, roll-off, spherical aberration,
                        field curvature, edge halation and flare to be modified
                        to provide sets of “curated” optics to suit the
                        personality and intent of the owner.
                      </p>
                      <br />
                      <p>
                        Many of the design cues for BLACKWING<sup>7</sup> primes
                        originate in optics manufactured during the 1930 -
                        1960s, but the real inspiration behind the lenses lies
                        in the evolutionary nature of experimental music and
                        audio synthesis, and the process of creating distortion
                        through sound amplification.
                      </p>
                    </TextBox>
                    <span
                      className={Cx(styles.arrowWrap, {
                        [styles.visible]:
                          this.state.donePlayingVideo && currentSlide === 2,
                      })}
                      onClick={() => fullpageApi.moveSectionDown()}
                    >
                      <Arrow className={Cx(styles.arrow)} />
                    </span>
                  </div>
                </div>
                <div className="section">
                  <div className="">
                    <TextBox
                      slideNumber={3}
                      currentSlide={currentSlide}
                      position={5}
                      shrink={true}
                    >
                      <p>
                        Conceptually, the ability to tune each BLACKWING
                        <sup>7</sup> lens is identical to how EQ adjustment is
                        used in music production - to control the range, or
                        shape of tonality in specific bands across the audio
                        frequency spectrum. The image produced by a BLACKWING
                        <sup>7</sup> lens can be described as having a musical
                        fidelity to it; with each focal length having an
                        underlying level of distortion which can be controlled
                        to produce strong artistic resonances including unique
                        flaring characteristics.
                      </p>
                      <br />
                      <p>
                        Ten sets of BLACKWING<sup>7</sup> BINARIES were created
                        by TRIBE<sup>7</sup> to help introduce the film making
                        community to the concept of audio merging into imaging.
                        These limited-edition BINARIES are named in recognition
                        of creative imaging not being a black or white process –
                        film makers accessing a wide choice of optics is
                        essential to serve creative storytelling. BLACKWING
                        <sup>7</sup> BINARIES have been widely used on
                        commercials, music videos, TV and feature productions
                        and all feedback from these unique lenses has
                        contributed to the production tuning of the BLACKWING
                        <sup>7</sup> prime lenses which are now shipping.
                      </p>
                    </TextBox>
                  </div>
                </div>
              </ReactFullpage.Wrapper>
            )
          }}
        />
      </div>
    )
  }
}
