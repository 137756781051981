import React from "react"
import { gsap } from "gsap"

import Cx from "classnames"
import styles from "./styles.scss"
import Navigation from "~/app/components/Navigation"
import LogoTribe from "~/app/components/LogoTribe"
import ImgBg from "~/app/components/ImgBg"
import background from "~/app/assets/images/bg.jpg"
import { Helmet } from "react-helmet"

export default class extends React.Component {
  componentDidMount() {
    gsap.to([this._header], {
      duration: 1.2,
      opacity: 1,
      stagger: '0.2',
    })

    gsap.to([this.list], {
      duration: .5,
      opacity: 1,
      y: 0,
      stagger: '0.03',
      delay: .35
    })

  }

  render() {
    const meta = {
      title: "Terms & Conditions",
      description: "Sales, shipping, warranty and returns terms. The small print."
    }

    return (
      <div>
        <Helmet>
          <title>{meta.title}</title>
          <meta name='description' content={meta.description} />
          <link rel="canonical" href={`${window.location.origin}/termsandconditions`} />
        </Helmet>
        <Navigation/>
        <ImgBg src={background} className={'bg'} readyClassName={'bgReady'} />
        <LogoTribe/>
        <div className={Cx(styles.page)}>

          <div ref={c=>{this._header=c}} className={Cx(styles.header)}>TRIBE<sup>7</sup> LLC TERMS AND CONDITIONS</div>
          <ol ref={c=>{this.list=c}} className={Cx(styles.list)}>
            <li>
              <h3>Prices and Quoting</h3>
              <p>Quotes are valid for one month from date of issue. Price changes will only apply to orders placed after the date of change. Quotes and invoices do not include VAT, shipping, storage, customs duties or local taxes (where applicable). All prices are subject to change.</p>
            </li>

            <li>
              <h3>Pre-orders and Order Acceptance</h3>
              <p>Under these terms, an order is an offer to buy products listed in a Quote. Orders will be accepted with a deposit. A set of lenses may be delivered in stages. Lead-time for the first delivery varies and a lead-time estimate will be provided at the time of quoting. Deposits will be held until the last shipment. Lead-times are subject to change.</p>
              <p>TRIBE<sup>7</sup> LLC requires, at minimum, a valid contact name, billing address, and email for all transactions. While we try to ensure accuracy, we reserve the right to correct any errors in pricing or descriptions, and to cancel or refuse any orders based on incorrect price or description. Such corrections may be made even after an order is accepted.</p>
            </li>

            <li>
              <h3>Property of Goods</h3>
              <p>All goods remain the property of TRIBE<sup>7</sup> LLC and/or its subsidiaries until full payment has been received.</p>
            </li>

            <li>
              <h3>Payment, Delivery and Insurance</h3>
              <p>Payment can be made via wire transfer or card. For all card payments, there is a small card handling surcharge fee. Full payment will be required prior to shipping.</p>
              <p>At point of shipment, goods become property of the buyer. Delivery will be ex-works under Incoterms 2020. All shipments require appropriate insurance coverage. It is the buyer’s responsibility to ensure there is insurance to cover goods during transit. TRIBE<sup>7</sup> LLC is not responsible for goods lost, stolen or damaged in transit. </p>
              <p>The buyer is obliged to take delivery of the goods at the time they are made available. When the buyer’s lenses are at sight of completion, the buyer will be issued a shipping invoice. TRIBE<sup>7</sup> LLC will hold the buyer’s lenses for 30 days from the date of the shipping invoice. If no payment or contact has been made within that time frame, the lenses may be re-allocated and a delay on the order could occur.</p>
              <p>TRIBE<sup>7</sup> LLC cannot advise on local import rules and taxes. Custom duties or local taxes (where applicable) are the responsibility of the buyer. TRIBE<sup>7</sup> LLC reserves the right to ship products from any office or subsidiary around the world.</p>
              <p>Shipments to customers in the USA will be shipped via TRIBE<sup>7</sup> LLC’s appointed customs broker to ensure smooth transition through customs - this may impact quoted shipping times.</p>
            </li>

            <li>
              <h3>Cancellation of Orders</h3>
              <p>Due to the configuration of the BLACKWING<sup>7</sup> lenses, up to 10% of the deposit is at risk if a cancellation occurs more than 8 weeks after the order is placed. Up to 25% of the deposit could be non-refundable 8 weeks before order shipment commences, depending on the nature of the order cancellation. Any refunds payable will be made on a timeline agreed with the buyer.</p>
              <p>Following receipt of a deposit payment to secure a buyer’s order, TRIBE7 LLC reserves the right to cancel the order without notice and without refund of the deposit payment if a mutually agreed delivery commitment and/or payment plan is not established with the buyer within twelve (12) calendar months from the date the deposit payment is received and acknowledged.</p>
              <p>Cancellations must be requested in writing to <a href="mailto:seven@7isatribe.com">seven@7isatribe.com</a>.</p>
            </li>

            <li>
              <h3>Warranty</h3>
              <p>TRIBE<sup>7</sup> LLC offers a twelve (12) month limited materials and workmanship warranty on all purchased products. Warranty begins from the day of shipment to the customer. Failure or performance degradation due to fair wear and tear or harsh usage is not covered under warranty and will be evaluated on a case-by-case basis. Warranty is considered void if a lens is partially or fully disassembled by unauthorized serviced persons.</p>
              <p>For warranty repairs, the customer is responsible for returning items that need servicing or repairs. TRIBE<sup>7</sup> LLC, or the regional distributor, will arrange the return shipment back to the customer after repair. For repairs out of warranty, the customer is responsible for all shipping.</p>
              <p>All service and repairs for goods in warranty must be requested in writing to  <a href="mailto:service@blackwing7.com">service@blackwing7.com</a> or <a href="mailto:seven@7isatribe.com">seven@7isatribe.com</a>. Warranty repairs with service partners will only be accepted once approved and following issuance of an RMA number by TRIBE<sup>7</sup> LLC. </p>
            </li>

            <li>
              <h3>Service and Repairs</h3>
              <p>All service and repairs for goods in warranty must be requested in writing to <a href="mailto:seven@7isatribe.com">seven@7isatribe.com</a>. Repairs with service partners will only be accepted once approved and following issuance of an RMA number by TRIBE<sup>7</sup> LLC.</p>
            </li>

            <li>
              <h3>Transfer of Order or Warranty</h3>
              <p>Buyers shall not assign, transfer or delegate any of its rights and obligations without the prior written consent of TRIBE<sup>7</sup> LLC.</p>
              <p>For any further details or questions, please email <a href="mailto:seven@7isatribe.com">seven@7isatribe.com</a>.</p>
            </li>

            <li>
              <h3>Returns and Exchanges</h3>
              <p>We do not accept returns or exchanges.</p>
            </li>

          </ol>
          <div className={Cx(styles.footer)}>
            <p>TRIBE<sup>7</sup> LLC is a company registered in the United States of America under company number EIN830912748. Registered office: 1013 Centre Road, Suite 403S, Wilmington, Delaware 19805, USA</p>
          </div>
        </div>
      </div>
    )
  }
}
