// Global Style
import "ress/ress.css"
import "./global.scss"
import "regenerator-runtime/runtime"

// App
import React from "react"
import { render } from "react-dom"
import { Provider, ErrorBoundary } from "@rollbar/react"
import { BrowserRouter } from "react-router-dom"
import Root from "~/app/views"

function init() {
  let router = (
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  )

  const current_env = process.env.ROLLBAR_ENVIRONMENT
  const shouldReportErrors = ["production", "staging"].includes(current_env)
  if (shouldReportErrors) {
    const rollbarConfig = {
      accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
      environment: current_env,
    }

    router = (
      <Provider config={rollbarConfig}>
        <ErrorBoundary>{router}</ErrorBoundary>
      </Provider>
    )
  }

  render(router, document.getElementById("root"))
}

init()
