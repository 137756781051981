@import "~/app/helpers/mq";

.logo {
  margin: 0 auto 32px;
  display: block;
  width: 190px;

  @include mq($from: xxwide) {
    width: calc(2.5 / 26 * 100vw);
    margin-bottom: 44px;
  }


  @include mq($until: desktop, $and: '(max-height: 420px)') {
    width: 80px;
    margin-bottom: 20px;
  }

}

.topPath, .circlePath {
  will-change: transform;
}

.hidden .topPath {
  display: block;
  margin: 0 auto;
  width: 100%;
  transform: scaleX(0);
  // transition: 1.2s transform;
  transform-origin: center center;
}
.hidden .topAnimating {
  transform: scaleX(1);
}

.hidden .circlePath {
  display: block;
  margin: 2px auto 0;
  width: 30.53%;
  transform: scale(0);
  transform-origin: 50% 68%;  
  // transition: 1.2s transform;
}
.hidden .circleAnimating {
  transform: scale(1);
}