import React from "react"
import ReactFullpage from "@fullpage/react-fullpage"
import { gsap } from "gsap"
import { CustomEase } from "gsap/CustomEase"
import { Link } from "react-router-dom"

import Cx from "classnames"
import styles from "./styles.scss"
import Navigation from "~/app/components/Navigation"
import LogoTribe from "~/app/components/LogoTribe"
import CirclesMobile from "../CirclesMobile"

import { Line } from "react-chartjs-2" // triggers console error 'Warning: componentWillMount has been renamed, and is not recommended for use.'
import { texts, chartData, initialState } from "../chartData"
import ImgBg from "~/app/components/ImgBg"
import background from "~/app/assets/images/bg.jpg"
import STXS from "~/app/assets/images/STX-S-crop.png"
import STXT from "~/app/assets/images/STX-T-crop.png"
import STXX from "~/app/assets/images/STX-X-crop.png"
import STXTblurred from "~/app/assets/images/STX-T.png"
import IconX from "~/app/components/IconX"

const options = {
  // Force height on xxwide screens
  maintainAspectRatio: window.innerWidth < 1800,
  // aspectRatio: 4,
  animation: {
    // duration: 10000
  },
  // tooltips: {
  //   mode: 'index',
  //   intersect: false,
  // },
  // hover: {
  //   mode: 'nearest',
  //   intersect: true
  // },
  // scales: {
  //   yAxes: [{
  //     ticks: {
  //       beginAtZero:true,
  //       stepSize: 2
  //     }
  //   }],
  //   xAxes: [{
  //     ticks: {

  //       stepSize: 4
  //     }
  //   }]
  // },
  tooltips: {
    enabled: false,
  },
  legend: {
    display: false,
    // position: 'right',
    // labels: {
    //     // This more specific font property overrides the global property
    //     fontColor: 'rgba(255,255,255,.7)',
    //     fontFamily: 'Calibre, sans-serif',
    //     useLineStyle: true
    // }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          // Include a dollar sign in the ticks
          fontColor: "rgba(255,255,255,.5)",
          fontFamily: "Calibre, sans-serif",
          fontSize: window.innerWidth < 1800 ? 10 : 12,
          min: 0,
          max: 1,
        },
        gridLines: {
          zeroLineColor: "rgba(255,255,255,.3)",
          color: "transparent",
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          fontColor: "rgba(255,255,255,.5)",
          fontFamily: "Calibre, sans-serif",
          fontSize: window.innerWidth < 2000 ? 9 : 11.5,
          stepSize: 1,
          min: 0,
          max: 23,
        },
        gridLines: {
          zeroLineColor: "rgba(255,255,255,.3)",
          color: "transparent",
        },
      },
    ],
  },
}

export default class extends React.Component {
  state = {
    currentLength: "37",
    currentVariant: "t", // t for Transient
    chartState: initialState,
    ready: false, // fade in first section after loading an important image
    open: false,
    closeButtonWasClicked: false,
  }

  handleLengthClick = (newLength) => {
    let newDataset =
      chartData[newLength]["data"][this.state.currentVariant] ||
      chartData[newLength]["data"]["t"]

    let el = document.querySelectorAll(`.${styles.popupInner}`)
    gsap.killTweensOf(el)

    gsap.fromTo(
      el,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        delay: 0.09,
      }
    )

    setTimeout(() => {
      this.setState({
        open: !this.state.open,
        currentLength: newLength,
        chartState: {
          ...initialState,
          datasets: newDataset,
        },
      })
    }, 100)
  }

  handleVariantClick = (newVariant) => {
    let newDataset =
      chartData[this.state.currentLength]["data"][newVariant] ||
      chartData[this.state.currentLength]["data"]["t"]

    if (this.state.currentVariant != newVariant) {
      let el = document.querySelectorAll(`.${styles.lengths}`)
      gsap.killTweensOf(el)
      gsap
        .timeline()
        .to(el, {
          opacity: 0,
          duration: 0,
        })
        .to(el, {
          opacity: 0.7,
          duration: 2.6,
          ease: CustomEase.create(
            "custom",
            "M0,0 C0,0 0.09116,0.26782 0.15086,0.43074 0.15694,0.44733 0.16234,0.45808 0.17283,0.47169 0.19642,0.50228 0.21314,0.52234 0.24249,0.54956 0.31205,0.61403 0.35449,0.65299 0.43055,0.71057 0.51315,0.77311 0.56472,0.80796 0.65357,0.86077 0.72937,0.90584 0.77865,0.92848 0.85807,0.96473 0.88838,0.97856 0.90924,0.98412 0.94124,0.99208 0.96291,0.99746 1,1 1,1"
          ),
        })
    }

    setTimeout(() => {
      this.setState({
        currentVariant: newVariant,
        chartState: {
          ...initialState,
          datasets: newDataset,
        },
      })
    }, 50)
  }

  closePopup = () => {
    this.setState({ open: false, closeButtonWasClicked: true })
  }

  componentDidMount() {
    gsap.registerPlugin(CustomEase)

    gsap.timeline().to(this._copy, {
      opacity: 0.9,
      duration: 1,
      delay: 1.75,
    })

    let delay = 0.2

    gsap.fromTo(
      [this._circlesLabel1, this._circlesLabel2, this._circlesLabel3],
      { opacity: 0 },
      { opacity: 0.9, delay: delay + 0.1, duration: 3, stagger: 0.4 }
    )
    gsap.to(this._circlesLabel1, { y: "-=15", delay, duration: 15 })
    gsap.to(this._circlesLabel3, { y: "+=15", delay, duration: 15 })

    // hide CirclesMobile until the main image is loaded, to prevent layout jumping
    let img = new Image()
    let that = this
    img.onload = function () {
      that.setState({ ready: true })
    }
    img.src = STXTblurred
  }

  render() {
    if (!this.state.open && this.state.closeButtonWasClicked) {
      document
        .querySelectorAll(`.${styles.chartSectionWrap}`)[0]
        .scrollIntoView()
    }
    gsap.to(this._chartText, {
      opacity: 0.7,
      duration: 0.3,
      delay: 0.1,
    })

    let that = this

    let lengthNumber = this.state.currentLength
      .replace("F", "")
      .replace(/7$/, "")

    let variantName = "STRAIGHT"

    switch (this.state.currentVariant) {
    case "t":
      variantName = "TRANSIENT"
      break
    case "x":
      variantName = "EXPRESSIVE"
      break
    }

    // make padding between copy and surrounding elements the same
    let distanceFromCirclesToCopy = 100

    if (this._circlesLabels) {
      distanceFromCirclesToCopy =
        this._copy.getBoundingClientRect().top -
        this._circlesLabels.getBoundingClientRect().bottom -
        62 -
        12

      if (distanceFromCirclesToCopy < 0) distanceFromCirclesToCopy = 0
      distanceFromCirclesToCopy = `${distanceFromCirclesToCopy}px`
    }

    return (
      <div
        className={Cx(
          { [styles.ready]: this.state.ready },
          styles[`variant${this.state.currentVariant}`]
        )}
      >
        <Navigation />
        <div className="bg" />
        <ImgBg src={background} className={"bg"} readyClassName={"bgReady"} />
        <LogoTribe />
        <div className={Cx(styles.sectionTuning)}>
          <div className={styles.circlesSectionWrapOuter}>
            <div className={styles.circlesSectionWrap}>
              <div className={Cx(styles.circlesSection)}>
                <CirclesMobile />
                <div
                  ref={(c) => {
                    this._circlesLabels = c
                  }}
                  className={Cx(styles.circlesLabels)}
                >
                  <div
                    ref={(c) => {
                      this._circlesLabel1 = c
                    }}
                    className={Cx(styles.circlesLabel)}
                  >
                    STRAIGHT
                  </div>
                  <div
                    ref={(c) => {
                      this._circlesLabel2 = c
                    }}
                    className={Cx(styles.circlesLabel)}
                  >
                    TRANSIENT
                  </div>
                  <div
                    ref={(c) => {
                      this._circlesLabel3 = c
                    }}
                    className={Cx(styles.circlesLabel)}
                  >
                    EXPRESSIVE
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            ref={(c) => {
              this._copy = c
            }}
            // style={{paddingBottom: distanceFromCirclesToCopy}}
            className={Cx(styles.pageText)}
          >
            Every BLACKWING<sup>7</sup> lens is hand-built, using proprietary
            production processes which allow the optical performance of each
            lens in the range to be uniquely configured and tuned to their
            owner’s wishes and creative style.
            <br />
            Three pre-set tonalities are available to&nbsp;order.
          </div>
        </div>

        <div className={styles.chartSectionWrap}>
          <div className={Cx(styles.chartSection)}>
            <div className={styles.moreInfo}>
              <span>*</span>TAP EACH FOR MORE INFO<span>*</span>
            </div>
            <div className={Cx(styles.chartVariants)}>
              <div
                className={Cx({
                  [styles.variantActive]: this.state.currentVariant == "s",
                })}
                onClick={() => that.handleVariantClick("s")}
              >
                <div
                  className={Cx(styles.chartVariant)}
                  style={{ textAlign: "center" }}
                >
                  <img src={STXS} />
                </div>
                <div className={Cx(styles.variantLabel)}>STRAIGHT</div>
              </div>
              <div
                className={Cx({
                  [styles.variantActive]: this.state.currentVariant == "t",
                })}
                onClick={() => that.handleVariantClick("t")}
              >
                <div
                  className={Cx(styles.chartVariant)}
                  style={{ textAlign: "center" }}
                >
                  <img src={STXT} />
                </div>
                <div className={Cx(styles.variantLabel)}>TRANSIENT</div>
              </div>
              <div
                className={Cx({
                  [styles.variantActive]: this.state.currentVariant == "x",
                })}
                onClick={() => that.handleVariantClick("x")}
              >
                <div
                  className={Cx(styles.chartVariant)}
                  style={{ textAlign: "center" }}
                >
                  <img src={STXX} />
                </div>
                <div className={Cx(styles.variantLabel)}>EXPRESSIVE</div>
              </div>
            </div>

            <div className={Cx(styles.lengths)}>
              {Object.keys(chartData).map((key, index) => (
                <div
                  className={Cx(styles.length, {
                    [styles.lengthActive]: this.state.currentLength == key,
                  })}
                  onClick={() => that.handleLengthClick(key)}
                  key={index}
                >
                  <span>
                    <b>{key.slice(0, -1)}</b>7
                  </span>
                  MM
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={Cx(styles.popup, { [styles.open]: this.state.open })}>
          <div className={styles.popupInner}>
            <div className={styles.x} onClick={this.closePopup}>
              <IconX />
            </div>

            <div
              className={Cx(styles.chartArea)}
              ref={(c) => {
                this._chartArea = c
              }}
            >
              <div className={Cx(styles.length)}>
                <span>
                  <b>{lengthNumber}</b>7
                </span>
                MM
              </div>

              <div className={Cx(styles.variantLabel)}>{variantName}</div>

              <div
                ref={(c) => {
                  this._chartText = c
                }}
                className={Cx(styles.chartText, {
                  [styles.longChartText]: this.state.currentLength == "77",
                })}
                dangerouslySetInnerHTML={{
                  __html:
                    texts[`F${this.state.currentLength}`][
                      this.state.currentVariant
                    ],
                }}
              />

              <div className={Cx(styles.chartWrapOuter)}>
                <div className={Cx(styles.chartWrap)}>
                  {this.state.chartState["datasets"] && (
                    <Line
                      width={440}
                      height={300}
                      data={this.state.chartState}
                      options={options}
                    />
                  )}
                </div>
                <div className={Cx(styles.legend)}>
                  <div>
                    <hr style={{ borderTop: "2px solid var(--gold)" }} />
                    <div>
                      5 LP/MM
                      <br />
                      SAGITTAL
                    </div>
                  </div>
                  <div>
                    <hr style={{ borderTop: "2px dashed var(--gold)" }} />
                    <div>
                      5 LP/MM
                      <br />
                      TANGENTIAL
                    </div>
                  </div>
                  <div>
                    <hr style={{ borderTop: "2px solid var(--red)" }} />
                    <div>
                      10 LP/MM
                      <br />
                      SAGITTAL
                    </div>
                  </div>
                  <div>
                    <hr style={{ borderTop: "2px dashed var(--red)" }} />
                    <div>
                      10 LP/MM
                      <br />
                      TANGENTIAL
                    </div>
                  </div>
                  <div>
                    <hr style={{ borderTop: "2px solid var(--blue)" }} />
                    <div>
                      20 LP/MM
                      <br />
                      SAGITTAL
                    </div>
                  </div>
                  <div>
                    <hr style={{ borderTop: "2px dashed var(--blue)" }} />
                    <div>
                      20 LP/MM
                      <br />
                      TANGENTIAL
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
