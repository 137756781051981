@import "~/app/helpers/mq";

.logo {
  margin: 0 auto;
  display: block;
  width: 122px;
  opacity: .8;

  @include mq($from: xxwide) {
    width: calc(2.5 / 26 * 100vw);
    margin-bottom: 44px;
  }
}

.hidden .topPath {
  display: block;
  margin: 0 auto;
  width: 122px;
  transform: scaleX(0);
  // transition: 1.2s transform;
  transform-origin: center center;
}
.hidden .topAnimating {
  transform: scaleX(1);
}

.hidden .circlePath {
  display: block;
  margin: 2px auto 0;
  width: 41px;
  transform: scale(0) translate(0, 0);
  transform-origin: 50% 68%;  
  // transition: 1.2s transform;
}
.hidden .circleAnimating {
  transform: scale(1);
}