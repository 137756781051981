@import "~/app/helpers/helpers";

.filter {
  margin: 28px 0 8px;

  @include mq($from: tablet) {
    width: 45%;
  }
}

.filterName {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: var(--gold);
  font-size: 14px;
  justify-content: space-between;

  hr {
    flex: 1;
    border: 0.5px solid var(--gold);
    margin-left: 10px;
  }
}

.options {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 10px 15px;

  @include mq($from: tablet) {
    gap: 15px;
  }

  button {
    margin-top: 20px;
    justify-self: left;
    letter-spacing: 0.3rem;
    text-transform: uppercase;
    color: white;
    opacity: 0.5;
  }

  button:hover,
  button.selected {
    color: var(--white);
    opacity: 1;
  }

  // We use this class to force a flex box to move to a new row
  .break {
    flex-basis: 100%;
    height: 0;
  }
}

.standardTuning {
  font-size: 24px;
}
