.wrapper {
  pointer-events: none;
}

.video,
.poster {
  width: 100%;
  height: 100%;
}

.poster {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0;
}
/* .poster.dissolve {
  visibility: hidden;
} */
.poster.showPoster {
  opacity: 1;
}

.hideVideo {
  opacity: 0;
  transition: 0.3s opacity;
}