import React from "react"

import LensesDesktop from "./LensesDesktop"
import LensesMobile from "./LensesMobile"
import { isMobile } from "~/app/helpers"
import { Helmet } from "react-helmet"

export default class extends React.Component {
  componentDidMount() {
    window.addEventListener("resize", this.onResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
  }

  onResize = () => {
    this.forceUpdate()
  }

  render() {
    const meta = {
      title: "Tunable Large Format Cine Lenses",
      description: "Explore a new range of creative tools with BLACKWING7 customizable large-format optics."
    }

    return (
      <div>
        <Helmet>
          <title>{meta.title}</title>
          <meta name='description' content={meta.description} />
          <link rel="canonical" href={`${window.location.origin}/lenses`} />
        </Helmet>
        {!isMobile() && <LensesDesktop />}
        {isMobile() && <LensesMobile />}
      </div>
    )
  }
}
