import useSWR from 'swr'
import { fetcher } from "./fetcher"
import { apiBaseUrl } from "../utils/apiConfig"

// this hook is used to fetch the different tuning types from Directus using useSWR
// It also returns an error and isLoading value which can be used to determine the current state
// of the request.
// Usage: const { tuningTypes, tuningTypesError, isLoadingTuningTypes } = useTuningTypes()
// Return Value:
//  On Success:
//    { data: [{id: 1, name: 'S',...},...]}
//  On Failure:
//    {undefined, error, false}
export const useTuningTypes = () => {
  const { data, error, isLoading } = useSWR(`${apiBaseUrl}/items/tuning_types`, fetcher)

  return {
    tuningTypes: data?.data,
    tuningTypesError: error,
    isLoadingTuningTypes: isLoading
  }
}
