import React from 'react';

const Logo = () => (
  <svg 
    viewBox="0 0 53.679 36"
    alt="Tribe7 logo"
    title=""
  >
    <g>
      <path d="M47.953,30.274a17.248,17.248,0,0,1-12.274,5.084,17.614,17.614,0,0,1-3.125-.28,18.016,18.016,0,0,0,0-34.156,17.521,17.521,0,0,1,3.125-.28A17.358,17.358,0,0,1,47.953,30.274m-42.227,0A17.358,17.358,0,0,1,18,.642a17.513,17.513,0,0,1,3.125.28,18.016,18.016,0,0,0,0,34.156,17.606,17.606,0,0,1-3.125.28A17.248,17.248,0,0,1,5.726,30.274M26.839,2.311A17.7,17.7,0,0,0,23.714.922a17.584,17.584,0,0,1,6.251,0,17.744,17.744,0,0,0-3.126,1.389m.641,31a18.142,18.142,0,0,0,3.248-2.58,18,18,0,0,0,0-25.456,18.142,18.142,0,0,0-3.248-2.58,17.115,17.115,0,0,1,3.779-1.484,17.363,17.363,0,0,1,7.854,29.066,17.254,17.254,0,0,1-7.854,4.518,17.115,17.115,0,0,1-3.779-1.484M22.951,5.272a18,18,0,0,0,0,25.456,18.142,18.142,0,0,0,3.248,2.58,17.115,17.115,0,0,1-3.779,1.484A17.363,17.363,0,0,1,14.566,5.726,17.254,17.254,0,0,1,22.42,1.208,17.115,17.115,0,0,1,26.2,2.692a18.142,18.142,0,0,0-3.248,2.58M18.325,17.679a17.245,17.245,0,0,1,5.08-11.953,17.51,17.51,0,0,1,3.434-2.672,17.487,17.487,0,0,1,3.435,2.672,17.237,17.237,0,0,1,5.08,11.953ZM26.518,32.75a17.52,17.52,0,0,1-3.113-2.476,17.245,17.245,0,0,1-5.08-11.953h8.193Zm.643-14.429h8.193a17.237,17.237,0,0,1-5.08,11.953,17.52,17.52,0,0,1-3.113,2.476Zm-.322,17.037a17.614,17.614,0,0,1-3.125-.28,17.775,17.775,0,0,0,3.125-1.389,17.817,17.817,0,0,0,3.126,1.389,17.617,17.617,0,0,1-3.126.28M48.407,5.272A17.882,17.882,0,0,0,35.679,0H18A18,18,0,0,0,5.272,30.728,17.882,17.882,0,0,0,18,36H35.736A18,18,0,0,0,48.407,5.272"/>
    </g>

  </svg>

);

export default Logo;

