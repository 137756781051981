import React from 'react'
import Cx from 'classnames'
import styles from './styles.css'


export default class extends React.Component {
  render() {
    return (
      <svg className={styles.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.599 39.599">
        <g>
          <line x1="0.708" y1="0.707" x2="38.892" y2="38.891"/>
          <line x1="0.707" y1="38.892" x2="38.891" y2="0.708"/>
        </g>
      </svg>
    )
  }
}
