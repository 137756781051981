@import "~/app/helpers/helpers";

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transform: translateY(0);
  transition: 1.3s transform;
}

.videoWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 1.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: -1;
}
.videoWrapper.ready {
  opacity: 1;
}
.videoWrapper.isSkippingMoreThanOneSlide {
  opacity: 1 !important;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
// position the video on the LensesMobile a little higher on landscape
.videoWrapper.coverCropReposition {
  @include mq($until: desktop, $and: '(orientation: landscape)') {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}
:global .fp-table:not(.active) {
  // mitigate the issue of the viewport height variation in various mobile browsers
  @include mq($until: desktop) {  
    opacity: 0!important;
    transition: .2s opacity;
  }
}
/* glitch where the second video appears when transitioning from the last slide to the first slide */
.videoWrapper.hideSecondSlideOnLoop { 
  opacity: 0;
  transition: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s cubic-bezier(0.165, 0.84, 0.44, 1),
    visibility 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hidden.visible {
  opacity: 1;
  visibility: visible;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10001;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1.05s cubic-bezier(0.13, 0.69, 0, 0.93), visibility 1.05s cubic-bezier(0.13, 0.69, 0, 0.93);
  -webkit-tap-highlight-color: transparent;
}

:global .black :local .overlay {
  background: rgba(0, 0, 0, 0.8);
}

.overlay.active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;

}

.playContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 100%;
}

.playBttn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 4rem;
  outline: none;
  text-transform: uppercase;
  font: normal 1.3rem/1 NeueHaasGrot55;
  letter-spacing: 0.215em;
  cursor: pointer;
}

.playBttn:hover {
  opacity: 0.5;
}

.playBttn svg {
  width: 3.125rem;
  height: 3.125rem;
  stroke: var(--white);
  stroke-width: 0.48;
  fill: none;
}

