import React from "react"

import LensRangeDesktop from "./LensRangeDesktop"
import LensRangeMobile from "./LensRangeMobile"
import { isMobile } from "~/app/helpers"
import { Helmet } from "react-helmet"

export default class extends React.Component {
  componentDidMount() {
    window.addEventListener("resize", this.onResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
  }

  onResize = () => {
    this.forceUpdate()
  }

  render() {
    const meta = {
      title: "Lens Range",
      description: "Ten focal lengths: 17mm - 137mm. Inspired by 20th century primes."
    }

    return (
      <div>
        <Helmet>
          <title>{meta.title}</title>
          <meta name='description' content={meta.description} />
          <link rel="canonical" href={`${window.location.origin}/lensrange`} />
        </Helmet>
        {!isMobile() && <LensRangeDesktop />}
        {isMobile() && <LensRangeMobile />}
      </div>
    )
  }
}
