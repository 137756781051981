import React from 'react'
import Cx from 'classnames'
import styles from './styles.css'


class Arrow extends React.Component {
   
  render() {
    return (
      <svg className={Cx(styles.arrow, this.props.className)} viewBox="0 0 50.91 26.16">
        <polyline className="cls-1" points="0.35 0.35 25.46 25.46 50.56 0.35" />
      </svg>
    )
  }
}

export default Arrow