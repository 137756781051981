import React from 'react'
import Cx from 'classnames'
import styles from './styles.scss'
import { gsap } from 'gsap'
import Logo from './logo'


class LogoTribe extends React.Component {
  componentDidMount() {
    if (this.props.landingOnLensed) {
      gsap.to(
        this._el,
        {
          opacity: .6,
          duration: .3,
          delay: 4.9
        }
      )
    }
  }

  render() {
    return (
      <a 
        href="https://7isatribe.com/"
        target="_blank" 
        className={Cx(styles.logo, {[styles.landingOnLensed]: this.props.landingOnLensed}, this.props.className)}
        ref={c=>{this._el=c}}
      >
        <Logo />
        <span className={styles.hover}>TRIBE<sup>7</sup></span>
      </a>
    )
  }
}

export default LogoTribe
