@import "~/app/helpers/helpers";

.posterWithTuning {
  place-self: center;
  @include mq($from: tablet, $until: desktop) {
    width: 346px;
  }
  @include mq($from: desktop) {
    width: 285px;
  }
  @include mq($from: xwide) {
    width: 220px;
  }
  @include mq($from: xxwide) {
    width: 275px;
  }
}

.poster {
  width: 100%;
  position: relative;
  object-fit: cover;
  background-color: black;
  overflow: hidden;
  aspect-ratio: 2 / 3;

  @include mq($from: tablet, $until: desktop) {
    height: 519px;
  }
  @include mq($from: desktop) {
    height: 428px;
  }
  @include mq($from: xwide) {
    height: 330px;
  }
  @include mq($from: xxwide) {
    height: 412px;
  }

}

.blurred {
  filter: blur(10px);
}

.clear {
  filter: none;
  transition: filter 0.4s ease-out;
}

.tuning {
  margin-top: 30px;
  color: var(--gold);
  font-size: 14px;
  padding: 2px;
  border: 1px solid var(--darkGoldFaded);
  background-color: var(--brown);
  text-transform: uppercase;
  span {
    color: var(--white);
  }
}
