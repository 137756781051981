@import "~/app/helpers/helpers";

.pageText {
  line-height: 1.3;
  letter-spacing: 0.6px;
  font-size: 17px;
  width: calc(7 / 26 * 100%);
  color: var(--white);
  
  transform: translateY(20px);
  opacity: 0;

  // transition: 3s opacity, 1.5s transform;

  @include mq($until: textBoxBreak) {
    width: 400px;
    margin-left: auto!important;
    margin-right: auto;
  }

  @include mq($from: xxwide) {
    font-size: 19px;
  }
}
.pageText span {
  color: rgba(255,255,255, .9);
}

.pageText.active {
  // opacity: .8;
  // transform: translateY(0);
}

.pageText.first {
  width: auto;
  @extend %header;
  color: var(--white);
  text-align: center;
}

.shrink {
  font-size: 16.5px;
  line-height: 1.25;
}