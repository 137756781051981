@import "~/app/helpers/helpers";

.sectionTuning {
  /* background: radial-gradient(circle, rgb(51, 37, 0) 0%, rgba(0,0,0,1) 100%); */
  min-height: 100vh;
  padding-top: 65px;
}


.circlesSectionWrapOuter {
  // min-height: calc(100vh - 85px);
}
.circlesSectionWrap {
  // height: calc(100vh - 200px);
  position: relative;
  min-height: 600px;
}
.circlesSection {
  width: 188px;
  margin: 0 auto;
  opacity: 0;
  transition: .5s opacity;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ready .circlesSection {
  opacity: 1;
}

.circlesLabels {
  display: flex;
  flex-direction: column;
}
.circlesLabel {
  @extend %header;
  margin-top: -50px;
  padding-top: 119px;
  width: 188px;
  height: 188px;
  text-align: center;
  font-size: 14px;
  opacity: 0;
}

.pageText {
  margin: 5px auto 0;
  line-height: 1.25;
  letter-spacing: 0.6px;
  color: var(--gold);
  opacity: 0;
  max-width: 950px;
  // padding: 0 30px;
  padding: 0 30px 25px;
  font-size: 16px;

  @include mq($until: desktop, $and: '(orientation: landscape)') {
    margin-top: 32px;
  }
}

.chartSectionWrap {
  min-height: 100vh;
  max-width: 100%;
  padding: 62px 25px 0;
}

.chartSection {
  opacity: 1;
  transition: 4s opacity, 4s transform;

  @include mq($from: xxwide) {
    width: calc( 18 / 26 * 100vw);
    max-width: 1580px;
  }
}

.chartVariants {
  display: flex;
  justify-content: space-between;
}

.chartVariants > div {
  width: 33.3333%;
  opacity: .5;
  transition: .3s opacity;
  animation-name: chartVariantFadeout;
  animation-duration: .5s;
}

.chartVariants > div:hover,
.chartVariants > div.variantActive
 {
  opacity: 1!important;
}

.chartVariant {
  color: var(--gold);
  font: var(--f2);
  font-weight: lighter;
  font-size: 92px;
  cursor: pointer;
}

.chartVariant img {
  height: 54px;
  display: block;
  margin: 0 auto 13px;
}

.variantLabel {
  @extend %header;
  opacity: 0;
  text-align: center;
  letter-spacing: 4px;
  font-size: 14px;
}
.chartVariants > div:hover .variantLabel,
.chartVariants > div.variantActive .variantLabel
 {
  opacity: 1;
}


.lengths {
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .variantt & {
    align-items: center;
  }
  .variantx & {
    align-items: flex-end;
  }
}


.length {
  color: var(--gold);
  opacity: 1;
  transition: .3s all;
  cursor: pointer;
  text-align: center;
  margin-top: 7px;
  width: 120px;

  .chartArea & {
    margin: 0 auto;
  }
}
.length span {
  color: var(--white);
  font: var(--f3);
  font-size: 49px;
  letter-spacing: 0;

  @include mq($until: desktop) {
    font-size: 36px;
  }

  @media (max-height: 768px) {
    font-size: 40px;
  }
}
.lengthActive, .length:hover {
  opacity: 1;
}

.chartArea {
  margin-top: 18px;
}

.header {
  margin-top: 17px;
  text-align: center;
  @extend %header;
}

.moreInfo {
  margin-top: 7px;
  margin-bottom: 14px;
  font-size: 14px;
  text-align: center;
  color: var(--gold);

  span {
    color: white;
  }
}


.chartText {
  line-height: 1.25;
  letter-spacing: 0.6px;
  font-size:15px;
  opacity: .7;
  margin-bottom: 30px;

}
.longChartText {
  line-height: 1.15;
  letter-spacing: 0.5px;
  margin-right: 55px;
}

.chartWrapOuter {
}

.chartWrap {
  width: 100%;
}
.chartWrap canvas {
  letter-spacing: 1px!important;
   /* width: 600px !important;
   height: 380px !important; */
}

.legend {
  column-count: 3;
  margin-top: 13px;
}
.legend > div {
  display: flex;
  justify-content: center;
  margin: 0 0 20px;
}

.legend > div > * {
}
.legend hr {
  height: 3px;
  width: 28px;
  border:none;
  border-top: 3px dotted var(--gold);
  margin: 7px 18px 0 0;
}
.legend > div div {
  font-size: 8px;
  flex-basis: 58px;
  letter-spacing: 1px;
  color: rgba(255,255,255,.5);
  cursor: default;
  user-select: none;
}




.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left:0;
  z-index: 110;

  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('~/app/assets/images/bg.jpg');
  background-position: 50% 50%;

  display: none;

  
  &.open {
    display: block;
  }

  .variantLabel {
    opacity: 1;
    margin: -4px 0 34px;
    text-align: center;
  }  
}

.popupInner {
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  padding: 52px 32px 20px;
}

.x {
  position: fixed;
  top: 15px;
  right: 18px;
  padding: 5px;
  color: var(--gold);
  opacity: .7;
  width: 38px;
}
