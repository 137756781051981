@import "~/app/helpers/helpers";

.page {
  padding: 150px calc(1 / 26 * 100vw) 0;
  // max-width: 1096px;
  margin: 0 auto;
  text-align: center;

  @include mq($until: tablet) {
    font-size: 14px;
    padding: 96px 26px 0;
  }
}

.header {
  @extend %header;
  text-align: center;
  opacity: 0;
}

.download {
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  margin: 40px 0;
  padding: 10px;
  text-align: center;
  color: var(--gold);
  transition: .3s color;
  opacity: 0;

  @include mq($until: tablet) {
    margin: 30px 0;
  }
}
.download:hover {
  color: white;
}
.downloadArrow {
  width: 34px;
  display: block;
  margin: 0 auto 10px;
}
.cls1 {
  fill: none;
  stroke: var(--gold);
  stroke-miterlimit: 10;
  transition: .3s stroke;
}
.download:hover .cls1 {
  stroke: white;
}

.chart {
  // padding: calc( 3 / 8 / 12 * 100%); // the gutter is 3/8 the size of the column width
  max-width: 1480px;
  margin: 0 auto 85px;
  line-height: 1.6;

  // yes desktop
  @include mq($until: desktop) {
    font-size: 14px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 68px;
  }

  @include mq($until: tablet) {
    font-size: 13px;
  }

  span {
    font-variant: small-caps;
  }

  &Row {
    margin: 0 auto;

    opacity: 0;
    display: flex;
    justify-content: space-between;
    width: 78.7%;
    min-width: 830px;

    @include mq($until: desktop) {
      width: auto;
      min-width: 617px;
    }

    @include mq($until: tablet) {
      width: auto;
      min-width: 0;
    }

    span {
      color: rgba(var(--goldValue), .8);
    }

    .label {
      line-height: 1;
      margin-bottom: 7px;

      div:first-child {
        @include mq($until: desktop) {
          margin-right: 4px;
        }
        @include mq($until: tablet) {
          margin-right: 18px;
        }
      }

      &:last-child div:first-child {
        @include mq($until: desktop) {
          margin-right: 6px;
        }
        @include mq($until: tablet) {
          margin-right: 14px;
        }

      }
    }

    & > div {
      width: calc(1 / 5 * 100%);

      // two columns
      & > div {
        width: 50%;
        display: inline-block;
        max-width: 60px;

        @include mq($until: tablet) {
          width: auto;
          letter-spacing: 1.5px;
        }

        @include mq($until: mobileWide) {
          letter-spacing: 1.2px;
          font-size: 10px;
        }

        &:first-child {
          @include mq($until: tablet) {
            margin-right: 4px;
          }
        }
      }
    }

    div {
      color: rgba(255,255,255, .55);

    }

  }

  &Labels.chartRow {

    @include mq($until: tablet) {
      display: flex;
      height: 156px;
      align-items: flex-end;
      margin-bottom: 10px;
    }


    & > div {
      display: flex;
      align-items: center;
      text-align: right;

      @include mq($until: tablet) {
        text-orientation: mixed;
        writing-mode: vertical-rl;
      }

      span {
        @include mq($from: tablet) {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }


}



.rows {
  max-width: 810px;
  margin: 0 auto 100px;
  text-transform: uppercase;
  text-align: left;
}

.row {
  display: flex;
  margin: 25px 0;
  transform: translateY(4px);
  opacity: 0;

  @include mq($from: xxwide) {
    font-size: 19px;
  }

  // @include mq($until: desktop) {
  //   display: block;
  // }
}

.row > div {
  width: 50%;

  @include mq($until: desktop) {
    // width: auto;
    // text-align: center;
  }
}

.row span {
  font-variant: small-caps;
  text-transform: none;
}

.row .serif {
  font: var(--f2);
  font-weight: bold;
}

.colLeft {
  display: flex;
}
.label {
  opacity: .7;

  @include mq($until: desktop) {
    // margin: 0 auto;
    // padding-right: 20px;
  }
  @include mq($until: tablet) {
    padding-right: 0;
  }
}
.label span {
  font-variant: small-caps;
}
.line {
  background: 1px var(--gold);
  position: relative;
  top: 13px;
  height: 1px;
  flex-grow: 1;
  margin: 0 28px;

  @include mq($until: desktop) {
    display: none;
  }
}

.colRight {
  opacity: .7;
}

.diagram {
  max-width: 1440px;
  width: 50vw;
  margin-bottom: 100px;
  opacity: 0;

  @include mq($until: desktop) {
    width: 66.666%;
  }

  @include mq($until: tablet) {
    width: 100%;
  }
}

.hideOnMobile {
  @include mq($until: tablet) {
    display: none;
  }
}

.hideOnDesktop {
  @include mq($from: tablet) {
    display: none!important;
  }
}