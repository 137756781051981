@import "~/app/helpers/helpers";

.pageTitle {
  text-transform: uppercase;
  color: var(--gold);
  margin-bottom: 10px;
  letter-spacing: 3px;
}

.page {
  padding-top: 150px;
  text-align: center;

  @include mq($until: tablet) {
    font-size: 14px;
    padding: 85px 26px 0;
  }

  hr {
    border: 0.5px solid var(--gold);
  }
}

.blue {
  color: var(--brightBlue);
}

.filterOptions {
  height: auto;

  @include mq($from: tablet) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
  }

  @include mq($from: tablet, $until: desktop) {
    width: 700px;
  }
  @include mq($from: desktop) {
    width: 900px;
  }
  @include mq($from: xwide) {
    width: 950px;
  }
  @include mq($from: xxwide) {
    width: 1170px;
  }
}

.posters {
  @include mq($from: tablet, $until: desktop) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
    margin: 0 auto;
    width: 700px;
  }
  @include mq($from: desktop) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin: 0 auto;
    width: 900px;
  }
  @include mq($from: xwide) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 0 auto;
    width: 950px;
  }
  @include mq($from: xxwide) {
    width: 1170px;
  }
}
