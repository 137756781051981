import React from "react"
import { gsap } from "gsap"

import Cx from "classnames"
import styles from "./styles.scss"
import Form from "~/app/components/Form"
import Heading from "~/app/components/Heading"
import Navigation from "~/app/components/Navigation"
import LogoTribe from "~/app/components/LogoTribe"
import LogoTRasterInquire from "~/app/components/LogoTRasterInquire"
import ImgBg from "~/app/components/ImgBg"
import background from "~/app/assets/images/bg.jpg"
import { contactForm } from "./contactForm"
import { Helmet } from "react-helmet"

export default class extends React.Component {
  state = {
    isFormSubmitted: false,
  }

  componentDidMount() {
    gsap.to(this._el, {
      opacity: 1,
      duration: 2,
    })
  }

  handleFormSuccess = () => {
    this.setState({
      isFormSubmitted: true
    })
  }

  renderForm = () => {
    if (!this.state.isFormSubmitted) {
      return (
        <Form
          fields={contactForm.fields}
          buttonLabel="Send"
          onSubmit={
            contactForm.callback
          }
          onSuccess={this.handleFormSuccess}
        />
      )
    }
    return (
      <div className={Cx(styles.submitMessage)}>
        Thanks for contacting us, we’ll be in touch
      </div>
    )
  }

  render() {
    const meta = {
      title: "Contact",
      description: "Contact us to configure, quote and customize your BLACKWING7 lens set."
    }
    
    return (
      <div>
        <Helmet>
          <title>{meta.title}</title>
          <meta name='description' content={meta.description} />
          <link rel="canonical" href={`${window.location.origin}/contact`} />
        </Helmet>
        <Navigation />
        <ImgBg src={background} className={"bg"} readyClassName={"bgReady"} />
        <LogoTribe />
        <div className="sectionInner">
          <div
            className={Cx(styles.page)}
            ref={(c) => {
              this._el = c
            }}
          >
            <section className={Cx(styles.body)}>
              <div>
                <Heading level="1" className={Cx(styles.contactFormHeader)}>
                  Contact us
                </Heading>
                {this.renderForm()}
              </div>
              <div>
                <LogoTRasterInquire />
                <div className={Cx(styles.socialMediaLinks)}>
                  <Heading level="1" light>
                    <a
                      href="https://www.instagram.com/7isatribe/"
                      target="_blank"
                    >
                      Instagram<sup>7</sup>
                    </a>
                  </Heading>
                </div>
              </div>
              <div className={styles.credits}>
                <div className={styles.creditsItems}>
                  <a href="/termsandconditions">TERMS & CONDITIONS</a>
                  <a href="https://www.instagram.com/osk.co" target="_blank">
                    SITE BY <span>OSK</span>
                  </a>
                </div>
              </div>
            </section>
          </div>
          <div className={Cx(styles.recaptchaLinks)}>
            <small>
              This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </small>
          </div>
        </div>
      </div>
    )
  }
}
