import React from 'react'
import Cx from 'classnames'
import styles from './styles.scss'
import { gsap } from 'gsap'


class TextBox extends React.Component {
  componentDidMount() {
  }

  componentDidUpdate() {
    let delay = this.props.slideNumber === 0 ? 1.3 : .7

    if (this.props.currentSlide === this.props.slideNumber) {
      gsap.to(
        this._el,
        {
          opacity: .7,
          y: 0,
          duration: 1.7,
          delay: delay,
          ease: "power2.out"
        }
      )
    } else {
      // make sure this doesn't run repeatedly
      gsap.to(
        this._el,
        {
          opacity: 0,
          y: 20,
          duration: 2,
          delay: 0
        }
      )

    }

  }

  render() {
    return (
      <div
        className={Cx(
          styles.pageText,
          {
            // [styles.active]: this.props.currentSlide == this.props.slideNumber,
            [styles.first]: this.props.slideNumber === 0
          },
          {
            [styles.shrink]: this.props.shrink
          })}
        ref={c=>{this._el=c}}
        style={{
          marginLeft: `calc(${this.props.position} / 26 * 100%)`
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

export default TextBox