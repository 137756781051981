export const emailSignUpForm = {
  fields: [
    {
      label: "Email",
      component: "input",
      name: "email",
      attrs: {
        type: "email",
        placeholder: "EMAIL",
      },
    },
  ],

  callback: (values) => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, {action: 'submit'}).then((token) => {
          values.token = token
          values.listId = process.env.MAILCHIMP_OBSCURA_SIGNUP_LIST_ID
          fetch("/api/submitEmailSignUpForm", {
            method: "POST",
            cache: "no-cache",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          })
            .then(resolve, reject)
        });
      });
    })
  },
}
