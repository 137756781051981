import React from 'react'
import Cx from 'classnames'
import styles from './styles.css'
import { gsap } from 'gsap'
import { isMobile } from '~/app/helpers.js'

class LogoT extends React.Component {
  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
  }

  onResize = () => {
    this.forceUpdate()
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize)

    // when optimizing, maybe try to disable glow selectively
    if (this.props.animated) {
      gsap.fromTo(
        this._topPath,
        { },
        {
          delay: .3,
          onStart: this.topAnimate,
          onStartParams: [this._topPath]
        }
      )
      gsap.fromTo(
        this._circlePath,
        { },
        {
          delay: .3,
          onStart: this.circleAnimate,
          onStartParams: [this._circlePath]
        }
      )
      gsap.fromTo(
        this._topPath,
        { },
        {
          delay: 3.5,
          onStart: this.topAnimateOut,
          onStartParams: [this._topPath]
        }
      )
      gsap.fromTo(
        this._circlePath,
        { },
        {
          delay: 3.5,
          onStart: this.circleAnimateOut,
          onStartParams: [this._circlePath]
        }
      )
    } else if (this.props.inquireAnimation) {
      gsap.fromTo(
        this._topPath,
        { },
        {
          delay: .3,
          onStart: this.topAnimate,
          onStartParams: [this._topPath]
        }
      )
      gsap.fromTo(
        this._circlePath,
        { },
        {
          delay: .3,
          onStart: this.circleAnimate,
          onStartParams: [this._circlePath]
        }
      )
    }

  }

  topAnimate(el) {
    el.classList.add(Cx(styles.topAnimating))
  }
  topAnimateOut(el) {
    el.classList.remove(Cx(styles.topAnimating))
  }
  circleAnimate(el) {
    el.classList.add(Cx(styles.circleAnimating))
  }
  circleAnimateOut(el) {
    el.classList.remove(Cx(styles.circleAnimating))
  }
  
  render() {
    return (
      <svg 
        viewBox="0 0 212.24 106.19"
        className={Cx(styles.logo, this.props.className, {[styles.hidden]: (this.props.animated || this.props.inquireAnimation)})}
        alt="Tribe7 logo"
        ref={c=>{this._el=c}}
      >
        <defs>
          <filter id="sofGlow" height="300%" width="300%" x="-75%" y="-75%">
            <feMorphology operator="dilate" radius="2" in="SourceAlpha" result="thicken" />
            <feGaussianBlur in="thicken" stdDeviation="2" result="blurred" />
            <feFlood floodColor="rgba(255,255,255,.2)" result="glowColor" />
            <feComposite in="glowColor" in2="blurred" operator="in" result="softGlow_colored" />
            <feMerge>
              <feMergeNode in="softGlow_colored"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>
        <g>
          <g>
            <path 
              filter={isMobile() ? "" : "url(#sofGlow)"}
              ref={c=>{this._topPath=c}}
              style={{willChange: 'transform'}} 
              className={Cx(styles.topPath)}
              d="M0,0v0.25c4.82,11.03,11.3,21.17,19.14,30.09h173.85c7.9-8.99,14.43-19.21,19.25-30.34H0z"
            />
            <path 
              filter={isMobile() ? "" : "url(#sofGlow)"}
              ref={c=>{this._circlePath=c}}
              style={{willChange: 'transform'}}
              className={Cx(styles.circlePath)}
              d="M136.46,75.85
              c0,16.76-13.58,30.34-30.34,30.34S75.78,92.6,75.78,75.85s13.58-30.34,30.34-30.34S136.46,59.09,136.46,75.85"
            />
          </g>
        </g>
      </svg>

    )
  }
}

export default LogoT