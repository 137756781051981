@import "~/app/helpers/helpers";

.links {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  display: flex;
  font-size: 19px;
  gap: 2vh 0;


  @include mq($until: tablet) {
    font-size: 17px
  }

  li {
    text-align: center;
    color: white;
    opacity: 1;
    letter-spacing: 5px;
    list-style: none;
    text-transform: uppercase;
    display: block;
    sup {
      top: -0.7em;
    }

    a {
      display: block;
      padding: 2vh 0;
    }

    a:hover {
      color: var(--gold);
    }
  }

}

