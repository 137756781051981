@import "~/app/helpers/helpers";

.videosWrapper {
  position: absolute;
  /* z-index: -1; */
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.videosWrapperInner {
  transform: translate(0,0);
  transition: transform .7s ease 0s;
}

.container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.containerRef {
  height: 100%;
}

.arrowWrap {
  transition: .4s opacity, .4s transform;
  opacity: 0;
  transform: scaleY(0);
  pointer-events:none;
}
.arrowFadeIn {
  opacity: 1;
  transform: scaleY(1);
  pointer-events:auto;
}
:global(.fp-section:not(.active)) .arrowWrap {
  transition:  .1s opacity;
  opacity: 0!important;
}

.arrowWrap .arrow polyline {
  stroke: white;
}
