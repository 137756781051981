import React from 'react'
import Cx from 'classnames'
import styles from './styles.scss'
import { gsap } from 'gsap'
import LogoTTop from '~/app/assets/images/logoT-top-inquire.png'
import LogoTCircle from '~/app/assets/images/logoT-circle-inquire.png'

class LogoTRasterInquire extends React.Component {

  componentDidMount() {

    let tl = gsap.timeline()

    tl.to(
      this._topPath,
      {
        scaleX: 1,
        duration: 1.2,
        delay: .3
      }
    )
    tl.to(
      this._circlePath,
      {
        scale: 1,
        duration: 1.2,
        delay: "-1.2"
      }
    )


  }
  
  render() {
    return (
      <div
        className={Cx(styles.logo, styles.hidden)}
        ref={c=>{this._el=c}}
      >
        <img
          ref={c=>{this._topPath=c}}
          className={Cx(styles.topPath)}
          src={LogoTTop}
        />
        <img
          ref={c=>{this._circlePath=c}}
          className={Cx(styles.circlePath)}
          src={LogoTCircle}
        />
      </div>
    )
  }
}

export default LogoTRasterInquire