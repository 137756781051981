export const texts = {
  F27: {
    s: `
      <div>
        The 27-S has the highest central sharpness of all BLACKWING<sup>7</sup> S-variants, with good rectilinear imaging, low breathing and good field illumination. Contrast is higher than T and X, with less roll-off across the fame.
      </div>
    `,
    t: `
      <div>
        The 27-T has the highest central sharpness of all BLACKWING<sup>7</sup> T-variants, with good rectilinear imaging, subtle halation, low breathing and good field illumination across the frame.
      </div>
    `,
    x: `
      <div>
        The 27-X has the same rectilinear imaging, breathing and field illumination as its S and T BLACKWING<sup>7</sup> counterparts. Contrast roll-off is more accentuated and diffused than T, particularly under flaring conditions.
      </div>
    `
  },
  F37: {
    s: `
      <div>
        The 37-S has tighter central contrast and a reduced resonant veiling flare characteristic compared to the 37-T BLACKWING<sup>7</sup> prime. The overall look of the 37-S is similar to the 27-S and 47-T primes in the BLACKWING<sup>7</sup> range.
      </div>
    `,
    t: `
      <div>
        The 37-T has a large image circle, suitable for 65mm format capture, has good central contrast and the strongest pupil flare characteristic of all the BLACKWING<sup>7</sup> T-tuned lenses. The overall look of the 37-T is unique within the BLACKWING<sup>7</sup> range.
      </div>
    `,
    x: `
      <div>
        The 37-X has lower central contrast and a strong but diffused resonant flare characteristic - the look of the 37-X when flared is milkier and softer than the 37-T BLACKWING<sup>7</sup> prime.
      </div>
    `
  },
  F47: {
    s: `
      <div>
        The 47-S is the workhorse BLACKWING<sup>7</sup> focal length for large format 35mm capture, with good central contrast and well controlled flare.
      </div>
    `,
    t: `
      <div>
        The 47-T has softer halation properties than the 57-T, with more controlled flare and shallower roll-off across the frame. The 47-T is the workhorse BLACKWING<sup>7</sup> focal length for large format 35mm capture.
      </div>
    `,
    x: `
      <div>
        The 47-X has softer central contrast than its T counterpart, with more veiling flare and roll-off across the frame. The 47-X is especially suited to medium-wide portraiture work.
      </div>
    `
  },
  F57: {
    s: `
      <div>
        The 57-S has sharper central contrast and a reduced resonant pupil flare characteristic compared to the 57-T BLACKWING<sup>7</sup> prime. The overall look of the 57-S is similar to the 77-S prime.
      </div>
    `,
    t: `
      <div>
        The 57-T has a high level of edge halation wide open, and a smooth, soft image with a creamy overall look as the lens is stopped down. The 57-T has a vibrant lenticular flare characteristic similar to the 37-T, and is a hero focal length for 65mm format capture.
      </div>
    `,
    x: `
      <div>
        The 57-X has reduced central contrast and a more defined resonant pupil flare characteristic compared to the 57-T BLACKWING<sup>7</sup> prime. Under hard flaring, the 57-X exhibits strong veiling characteristics but still produces a defined, impressionistic image.
      </div>
    `
  },
  F77: {
    s: `
      <div>
        The 77-S is a pure medium format portrait lens - with good central contrast and definition wide open, reducing in sharpness and smoothly rolling off to the edge of the image. The 77-S has more controlled pupil flare than its 77-T counterpart.
      </div>
    `,
    t: `
      <div>
        The 77-T offers beautiful, almost painterly imaging qualities. The lens has low contrast and is creamy-smooth wide open, with a subtle level of edge halation, and accentuated roll-off.
      </div>
    `,
    x: `
      <div>
        The 77-X prime has lower central contrast and a strong but diffused resonant flare characteristic compared to 77-T. The look of the 77-X is soft and flattering when used in medium format portraiture work.
      </div>
    `
  },
  F107: {
    s: `
      <div>
        The 107-S  has a characteristic similar to the 137-S BLACKWING<sup>7</sup> prime, well protected under flare conditions and with higher central contrast. Though well and truly a telephoto focal length, wide open the lens has a subtle softness and retains the BLACKWING<sup>7</sup> vintage 'look'.
      </div>
    `,
    t: `
      <div>
        The 107-T has relatively modest sharpness for a telephoto focal length — allowing flattering portraiture whilst still retaining good micro-contrast to render distant fine textures accurately. The lens has smooth halation at high contrast edges and a creamy bokeh.
      </div>
    `,
    x: `
      <div>
        The 107-X has a characteristic similar to the 77X BLACKWING<sup>7</sup> prime, with reduced contrast under flare conditions due to its uncoated element structures. Despite being semi-telephoto in nature, the lens has a subtle softness and produces a true vintage look.
      </div>
    `
  },
  F137: {
    s: `
      <div>
        The 137-S has good, but not high central sharpness despite its long focal length. In many ways the 137-S prime defies conventionality and has imaging properties unlike many other large format telephoto lenses. 
      </div>
    `,
    t: `
      <div>
        The 137-T has characteristics like the 107-T BLACKWING<sup>7</sup> prime, with good flare control and slightly higher contrast. Though well and truly a telephoto focal length, wide open the lens has a subtle softness and retains the BLACKWING<sup>7</sup> vintage “look”.
      </div>
    `,
    x: `
      <div>
        The 137-X has very modest sharpness for a telephoto focal length — designed for flattering portraiture work at longer ranges, the lens has smooth halation and a creamy “big” bokeh.
      </div>
    `
  }
}

const solid = {
  showLine: true,
  borderCapStyle: 'round',
  borderWidth: 2,
  pointRadius: 0,
  backgroundColor: 'transparent',
}

const dashed = {
  showLine: true,
  borderCapStyle: 'butt',
  borderDash: [6,6],
  borderWidth: 2,
  pointRadius: 0,
  backgroundColor: 'transparent',
}

const yellow = { borderColor: '#9b8235' }
const blue = { borderColor: '#1f525e' }
const red = { borderColor: '#b42000' }

export const chartData = {
  '27': {
    data: {
      's': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.980, 0.980, 0.980, 0.980, 0.982, 0.983, 0.985, 0.985, 0.985, 0.985, 0.983, 0.980, 0.975, 0.965, 0.955, 0.950, 0.950, 0.953, 0.960, 0.968, 0.980, 0.995, 0.980, 0.940
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.979, 0.978, 0.977, 0.976, 0.975, 0.966, 0.955, 0.945, 0.925, 0.910, 0.880, 0.850, 0.815, 0.770, 0.690, 0.600, 0.480, 0.380
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.950, 0.950, 0.9525, 0.955, 0.9575, 0.962, 0.965, 0.968, 0.963, 0.945, 0.925, 0.903, 0.880, 0.860, 0.840, 0.825, 0.822, 0.827, 0.845, 0.880, 0.927, 0.975, 0.940, 0.780
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.950, 0.95, 0.95, 0.95, 0.950, 0.950, 0.947, 0.945, 0.940, 0.925, 0.905, 0.880, 0.855, 0.815, 0.765, 0.700, 0.630, 0.550, 0.440, 0.330, 0.280, 0.270, 0.280, 0.275
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.880, 0.880, 0.882, 0.890, 0.900, 0.915, 0.920, 0.910, 0.880, 0.840, 0.785, 0.720, 0.650, 0.585, 0.525, 0.485, 0.470, 0.490, 0.560, 0.660, 0.780, 0.860, 0.810, 0.600
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.880, 0.880, 0.880, 0.880, 0.885, 0.890, 0.890, 0.880, 0.850, 0.810, 0.750, 0.680, 0.610, 0.520, 0.420, 0.380, 0.360, 0.360, 0.340, 0.300, 0.260, 0.220, 0.170, 0.130
          ],
        },      
      ],
      't': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.958, 0.958, 0.959, 0.960, 0.961, 0.962, 0.962, 0.961, 0.960, 0.959, 0.955, 0.946, 0.938, 0.925, 0.914, 0.901, 0.890, 0.880, 0.870, 0.857, 0.838, 0.804, 0.748, 0.655
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.958, 0.958, 0.959, 0.961, 0.963, 0.965, 0.969, 0.968, 0.966, 0.960, 0.956, 0.947, 0.940, 0.929, 0.916, 0.903, 0.884, 0.860, 0.819, 0.770, 0.698, 0.600, 0.495, 0.425
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.875, 0.883, 0.889, 0.894, 0.899, 0.903, 0.905, 0.906, 0.901, 0.881, 0.854, 0.822, 0.790, 0.763, 0.746, 0.735, 0.731, 0.734, 0.742, 0.756, 0.757, 0.709, 0.573, 0.386
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.875, 0.886, 0.894, 0.901, 0.907, 0.914, 0.916, 0.911, 0.903, 0.888, 0.869, 0.844, 0.814, 0.774, 0.731, 0.685, 0.638, 0.580, 0.493, 0.383, 0.280, 0.251, 0.258, 0.260
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.760, 0.764, 0.772, 0.785, 0.805, 0.816, 0.810, 0.786, 0.745, 0.690, 0.616, 0.533, 0.455, 0.398, 0.360, 0.346, 0.358, 0.400, 0.470, 0.555, 0.608, 0.590, 0.435, 0.173
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.760, 0.764, 0.771, 0.783, 0.799, 0.805, 0.796, 0.775, 0.745, 0.705, 0.655, 0.590, 0.528, 0.460, 0.398, 0.358, 0.330, 0.315, 0.303, 0.278, 0.230, 0.175, 0.125, 0.123
          ],
        },      
      ],
      'x': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.950, 0.951, 0.952, 0.953, 0.954, 0.955, 0.954, 0.953, 0.952, 0.950, 0.945, 0.935, 0.925, 0.912, 0.900, 0.885, 0.870, 0.855, 0.840, 0.820, 0.790, 0.740, 0.670, 0.560
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.950, 0.951, 0.952, 0.954, 0.957, 0.960, 0.965, 0.965, 0.962, 0.955, 0.950, 0.940, 0.935, 0.924, 0.913, 0.900, 0.885, 0.863, 0.820, 0.770, 0.700, 0.600, 0.500, 0.440
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.850, 0.860, 0.868, 0.873, 0.88, 0.883, 0.885, 0.885, 0.880, 0.860, 0.830, 0.795, 0.760, 0.730, 0.715, 0.705, 0.701, 0.703, 0.708, 0.714, 0.700, 0.620, 0.450, 0.255
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.850, 0.865, 0.875, 0.884, 0.893, 0.902, 0.905, 0.900, 0.890, 0.875, 0.857, 0.832, 0.800, 0.760, 0.720, 0.680, 0.640, 0.590, 0.510, 0.400, 0.280, 0.245, 0.250, 0.255
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.720, 0.725, 0.735, 0.750, 0.773, 0.783, 0.773, 0.745, 0.700, 0.640, 0.560, 0.470, 0.390, 0.335, 0.305, 0.300, 0.320, 0.370, 0.440, 0.520, 0.550, 0.500, 0.310, 0.030
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.720, 0.725, 0.735, 0.750, 0.770, 0.777, 0.765, 0.740, 0.710, 0.670, 0.623, 0.560, 0.500, 0.440, 0.390, 0.350, 0.320, 0.300, 0.290, 0.270, 0.220, 0.160, 0.110, 0.120
          ],
        },      
      ],
    }
  },
  '37': {
    data: {
      's': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.979, 0.978, 0.977, 0.976, 0.973, 0.968, 0.960, 0.953, 0.950, 0.950, 0.950, 0.953, 0.960, 0.970, 0.980, 0.985, 0.980, 0.950
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.980, 0.979, 0.978, 0.977, 0.976, 0.974, 0.972, 0.970, 0.965, 0.958, 0.950, 0.945, 0.940, 0.938, 0.940, 0.941, 0.942, 0.945, 0.950, 0.958, 0.960, 0.955, 0.948, 0.940
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.960, 0.960, 0.960, 0.958, 0.955, 0.950, 0.945, 0.935, 0.925, 0.913, 0.900, 0.885, 0.868, 0.850, 0.835, 0.827, 0.827, 0.840, 0.862, 0.890, 0.918, 0.935, 0.920, 0.850
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.960, 0.957, 0.950, 0.945, 0.937, 0.920, 0.900, 0.880, 0.860, 0.840, 0.820, 0.805, 0.790, 0.780, 0.780, 0.785, 0.805, 0.820, 0.845, 0.865, 0.880, 0.875, 0.865, 0.845
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.890, 0.890, 0.890, 0.887, 0.880, 0.865, 0.845, 0.820, 0.787, 0.750, 0.700, 0.650, 0.600, 0.553, 0.515, 0.490, 0.483, 0.500, 0.560, 0.640, 0.720, 0.780, 0.775, 0.710
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.890, 0.880, 0.865, 0.840, 0.800, 0.750, 0.700, 0.650, 0.600, 0.550, 0.510, 0.475, 0.453, 0.435, 0.425, 0.426, 0.445, 0.475, 0.530, 0.610, 0.650, 0.660, 0.640, 0.590
          ],
        },      
      ],
      't': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.973, 0.971, 0.969, 0.965, 0.961, 0.954, 0.946, 0.938, 0.931, 0.923, 0.918, 0.910, 0.900, 0.891, 0.883, 0.875, 0.871, 0.872, 0.878, 0.891, 0.905, 0.906, 0.898, 0.883
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.973, 0.971, 0.967, 0.962, 0.954, 0.941, 0.933, 0.925, 0.920, 0.915, 0.913, 0.915, 0.918, 0.921, 0.925, 0.929, 0.931, 0.934, 0.935, 0.933, 0.926, 0.914, 0.890, 0.865
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.855, 0.851, 0.848, 0.843, 0.839, 0.834, 0.827, 0.819, 0.809, 0.798, 0.788, 0.776, 0.765, 0.753, 0.740, 0.726, 0.709, 0.690, 0.666, 0.635, 0.605, 0.571, 0.530, 0.475
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.855, 0.847, 0.838, 0.829, 0.812, 0.793, 0.765, 0.738, 0.706, 0.683, 0.663, 0.651, 0.644, 0.638, 0.634, 0.631, 0.633, 0.633, 0.635, 0.636, 0.633, 0.624, 0.614, 0.601
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.673, 0.665, 0.658, 0.649, 0.640, 0.629, 0.616, 0.603, 0.587, 0.570, 0.552, 0.534, 0.518, 0.502, 0.489, 0.477, 0.466, 0.451, 0.433, 0.411, 0.394, 0.383, 0.374, 0.365
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.673, 0.633, 0.591, 0.555, 0.523, 0.488, 0.456, 0.425, 0.394, 0.370, 0.353, 0.340, 0.333, 0.326, 0.324, 0.324, 0.330, 0.340, 0.355, 0.376, 0.388, 0.388, 0.381, 0.365
          ],
        },      
      ],
      'x': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.970, 0.968, 0.965, 0.960, 0.955, 0.945, 0.935, 0.925, 0.915, 0.905, 0.900, 0.890, 0.880, 0.870, 0.860, 0.850, 0.845, 0.845, 0.850, 0.865, 0.880, 0.880, 0.870, 0.860
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.970, 0.968, 0.963, 0.957, 0.946, 0.930, 0.920, 0.910, 0.905, 0.900, 0.900, 0.905, 0.910, 0.915, 0.920, 0.925, 0.927, 0.930, 0.930, 0.925, 0.915, 0.900, 0.870, 0.840
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.820, 0.815, 0.810, 0.805, 0.800, 0.795, 0.788, 0.780, 0.770, 0.760, 0.750, 0.740, 0.730, 0.720, 0.708, 0.692, 0.670, 0.640, 0.600, 0.550, 0.500, 0.450, 0.400, 0.350
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.820, 0.81, 0.8, 0.79, 0.770, 0.750, 0.720, 0.690, 0.655, 0.630, 0.610, 0.600, 0.595, 0.590, 0.585, 0.580, 0.575, 0.570, 0.565, 0.560, 0.550, 0.540, 0.530, 0.520
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.600, 0.590, 0.580, 0.570, 0.560, 0.550, 0.540, 0.530, 0.520, 0.510, 0.502, 0.495, 0.490, 0.485, 0.480, 0.472, 0.460, 0.435, 0.390, 0.335, 0.285, 0.250, 0.240, 0.250
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.600, 0.550, 0.500, 0.460, 0.430, 0.400, 0.375, 0.350, 0.325, 0.310, 0.300, 0.295, 0.293, 0.290, 0.290, 0.290, 0.292, 0.295, 0.297, 0.298, 0.300, 0.297, 0.295, 0.290
          ],
        },      
      ],
    }
  },
  '47': {
    data: {
      's': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.978, 0.975, 0.970, 0.965, 0.960, 0.957, 0.955, 0.955, 0.955, 0.953, 0.950, 0.945, 0.930, 0.890, 0.830, 0.760
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.980, 0.980, 0.980, 0.980, 0.979, 0.978, 0.977, 0.976, 0.974, 0.972, 0.970, 0.967, 0.960, 0.945, 0.925, 0.910, 0.890, 0.875, 0.865, 0.850, 0.825, 0.790, 0.755, 0.690
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.960, 0.960, 0.960, 0.958, 0.956, 0.954, 0.950, 0.943, 0.935, 0.923, 0.910, 0.900, 0.889, 0.880, 0.875, 0.872, 0.870, 0.870, 0.869, 0.845, 0.790, 0.700, 0.570, 0.390
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.960, 0.96, 0.962, 0.96, 0.960, 0.960, 0.955, 0.950, 0.940, 0.930, 0.915, 0.900, 0.875, 0.830, 0.790, 0.750, 0.720, 0.690, 0.675, 0.660, 0.650, 0.625, 0.600, 0.525
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.910, 0.910, 0.904, 0.900, 0.890, 0.880, 0.860, 0.825, 0.790, 0.750, 0.710, 0.670, 0.635, 0.610, 0.595, 0.590, 0.592, 0.600, 0.605, 0.590, 0.520, 0.450, 0.400, 0.350
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.910, 0.910, 0.907, 0.902, 0.900, 0.895, 0.890, 0.875, 0.850, 0.815, 0.780, 0.730, 0.680, 0.640, 0.600, 0.550, 0.520, 0.500, 0.475, 0.445, 0.400, 0.350, 0.310, 0.230
          ],
        },      
      ],
      't': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.935, 0.935, 0.935, 0.935, 0.933, 0.931, 0.928, 0.924, 0.917, 0.913, 0.908, 0.901, 0.894, 0.887, 0.880, 0.869, 0.856, 0.838, 0.815, 0.789, 0.758, 0.725, 0.688, 0.655
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.935, 0.935, 0.931, 0.928, 0.924, 0.920, 0.912, 0.904, 0.900, 0.897, 0.895, 0.893, 0.890, 0.885, 0.878, 0.876, 0.871, 0.873, 0.876, 0.869, 0.844, 0.798, 0.714, 0.585
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.855, 0.855, 0.855, 0.851, 0.847, 0.841, 0.834, 0.827, 0.819, 0.808, 0.796, 0.784, 0.770, 0.756, 0.744, 0.728, 0.713, 0.698, 0.676, 0.646, 0.603, 0.550, 0.495, 0.435
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.855, 0.851, 0.848, 0.836, 0.818, 0.803, 0.794, 0.785, 0.775, 0.765, 0.754, 0.743, 0.729, 0.710, 0.695, 0.683, 0.679, 0.675, 0.662, 0.630, 0.568, 0.479, 0.375, 0.229
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.700, 0.700, 0.699, 0.698, 0.694, 0.687, 0.676, 0.660, 0.642, 0.620, 0.598, 0.573, 0.549, 0.528, 0.509, 0.493, 0.478, 0.458, 0.429, 0.380, 0.318, 0.270, 0.243, 0.223
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.700, 0.693, 0.677, 0.661, 0.645, 0.629, 0.616, 0.601, 0.588, 0.571, 0.555, 0.535, 0.515, 0.501, 0.488, 0.475, 0.460, 0.425, 0.344, 0.261, 0.198, 0.148, 0.115, 0.106
          ],
        },      
      ],
      'x': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.920, 0.920, 0.920, 0.920, 0.917, 0.915, 0.910, 0.905, 0.897, 0.893, 0.887, 0.880, 0.872, 0.863, 0.855, 0.840, 0.823, 0.800, 0.770, 0.737, 0.700, 0.670, 0.640, 0.620
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.920, 0.920, 0.915, 0.910, 0.905, 0.900, 0.890, 0.880, 0.875, 0.872, 0.870, 0.868, 0.867, 0.865, 0.862, 0.865, 0.865, 0.872, 0.880, 0.875, 0.850, 0.800, 0.700, 0.550
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.820, 0.820, 0.820, 0.815, 0.810, 0.803, 0.795, 0.788, 0.780, 0.770, 0.758, 0.745, 0.730, 0.715, 0.700, 0.680, 0.660, 0.640, 0.612, 0.580, 0.540, 0.500, 0.470, 0.450
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.820, 0.815, 0.81, 0.795, 0.770, 0.750, 0.740, 0.730, 0.720, 0.710, 0.700, 0.690, 0.680, 0.670, 0.663, 0.660, 0.665, 0.670, 0.657, 0.620, 0.540, 0.430, 0.300, 0.130
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.630, 0.630, 0.630, 0.630, 0.628, 0.623, 0.615, 0.605, 0.592, 0.576, 0.560, 0.540, 0.520, 0.500, 0.480, 0.460, 0.440, 0.410, 0.370, 0.310, 0.250, 0.210, 0.190, 0.180
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.630, 0.620, 0.600, 0.580, 0.560, 0.540, 0.525, 0.510, 0.500, 0.490, 0.480, 0.470, 0.460, 0.455, 0.450, 0.450, 0.440, 0.400, 0.300, 0.200, 0.130, 0.080, 0.050, 0.065
          ],
        },      
      ],
    }
  },
  '57': {
    data: {
      's': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.979, 0.977, 0.974, 0.970, 0.965, 0.960, 0.955, 0.952, 0.950, 0.950, 0.950, 0.950, 0.948, 0.945, 0.930, 0.900, 0.850
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.978, 0.975, 0.970, 0.960, 0.950, 0.940, 0.930, 0.925, 0.915, 0.905, 0.895, 0.880, 0.870, 0.860, 0.850, 0.840, 0.820, 0.790
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.950, 0.950, 0.948, 0.945, 0.94, 0.933, 0.925, 0.918, 0.910, 0.900, 0.888, 0.875, 0.860, 0.848, 0.837, 0.828, 0.822, 0.820, 0.819, 0.818, 0.815, 0.800, 0.750, 0.640
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.950, 0.949, 0.948, 0.945, 0.940, 0.927, 0.920, 0.905, 0.890, 0.875, 0.860, 0.845, 0.830, 0.810, 0.800, 0.790, 0.780, 0.760, 0.750, 0.730, 0.715, 0.690, 0.650, 0.580
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.870, 0.865, 0.862, 0.855, 0.840, 0.820, 0.792, 0.760, 0.725, 0.690, 0.650, 0.610, 0.570, 0.530, 0.500, 0.485, 0.480, 0.480, 0.485, 0.490, 0.492, 0.490, 0.477, 0.450
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.870, 0.860, 0.845, 0.830, 0.815, 0.795, 0.775, 0.745, 0.720, 0.690, 0.670, 0.650, 0.630, 0.610, 0.580, 0.560, 0.535, 0.510, 0.485, 0.460, 0.430, 0.390, 0.320, 0.225
          ]
        }
      ],
      't': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.958, 0.958, 0.958, 0.958, 0.955, 0.954, 0.948, 0.942, 0.934, 0.926, 0.918, 0.909, 0.900, 0.891, 0.885, 0.881, 0.879, 0.877, 0.879, 0.880, 0.883, 0.885, 0.885, 0.880
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.958, 0.958, 0.958, 0.958, 0.958, 0.958, 0.957, 0.956, 0.955, 0.953, 0.950, 0.945, 0.941, 0.936, 0.930, 0.920, 0.906, 0.888, 0.866, 0.843, 0.813, 0.769, 0.715, 0.666
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.823, 0.823, 0.818, 0.812, 0.805, 0.796, 0.786, 0.777, 0.768, 0.758, 0.747, 0.735, 0.721, 0.706, 0.689, 0.672, 0.656, 0.640, 0.625, 0.610, 0.594, 0.579, 0.563, 0.539
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.823, 0.800, 0.777, 0.754, 0.726, 0.704, 0.684, 0.665, 0.650, 0.643, 0.635, 0.635, 0.635, 0.634, 0.635, 0.633, 0.626, 0.610, 0.593, 0.569, 0.543, 0.514, 0.481, 0.445
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.578, 0.569, 0.559, 0.544, 0.525, 0.505, 0.485, 0.468, 0.454, 0.443, 0.431, 0.421, 0.410, 0.398, 0.389, 0.380, 0.374, 0.364, 0.350, 0.333, 0.318, 0.307, 0.303, 0.300
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.578, 0.553, 0.519, 0.493, 0.466, 0.435, 0.411, 0.389, 0.375, 0.364, 0.360, 0.361, 0.368, 0.385, 0.402, 0.406, 0.393, 0.368, 0.339, 0.310, 0.280, 0.248, 0.208, 0.154
          ]
        }
      ],
      'x': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.950, 0.950, 0.950, 0.950, 0.947, 0.945, 0.937, 0.930, 0.920, 0.910, 0.900, 0.890, 0.880, 0.870, 0.863, 0.858, 0.855, 0.853, 0.855, 0.857, 0.862, 0.870, 0.880, 0.890
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.950, 0.950, 0.950, 0.950, 0.950, 0.950, 0.950, 0.950, 0.950, 0.950, 0.950, 0.947, 0.945, 0.940, 0.935, 0.925, 0.910, 0.890, 0.865, 0.837, 0.800, 0.745, 0.680, 0.625
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.780, 0.780, 0.775, 0.768, 0.76, 0.750, 0.740, 0.730, 0.720, 0.710, 0.700, 0.688, 0.675, 0.658, 0.640, 0.620, 0.600, 0.580, 0.560, 0.540, 0.520, 0.505, 0.500, 0.505
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.780, 0.75, 0.72, 0.69, 0.655, 0.630, 0.605, 0.585, 0.570, 0.565, 0.560, 0.565, 0.570, 0.575, 0.580, 0.580, 0.575, 0.560, 0.540, 0.515, 0.485, 0.455, 0.425, 0.400
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.480, 0.470, 0.458, 0.440, 0.420, 0.400, 0.383, 0.370, 0.363, 0.360, 0.358, 0.358, 0.356, 0.354, 0.352, 0.345, 0.338, 0.325, 0.305, 0.280, 0.260, 0.246, 0.245, 0.250
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.480, 0.450, 0.410, 0.380, 0.350, 0.315, 0.290, 0.270, 0.260, 0.255, 0.257, 0.265, 0.280, 0.310, 0.342, 0.355, 0.345, 0.320, 0.290, 0.260, 0.230, 0.200, 0.170, 0.130
          ]
        }
      ]
    }
  },
  '77': {
    data: {
      's': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.980, 0.980, 0.980, 0.980, 0.980, 0.977, 0.973, 0.970, 0.965, 0.960, 0.955, 0.950, 0.945, 0.943, 0.942, 0.943, 0.945, 0.950, 0.955, 0.960, 0.970, 0.968, 0.950, 0.900
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.980, 0.977, 0.975, 0.971, 0.968, 0.963, 0.960, 0.955, 0.950, 0.945, 0.940
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.960, 0.960, 0.960, 0.958, 0.955, 0.950, 0.940, 0.930, 0.917, 0.900, 0.880, 0.860, 0.845, 0.830, 0.820, 0.815, 0.815, 0.825, 0.840, 0.863, 0.880, 0.870, 0.820, 0.700
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.960, 0.960, 0.960, 0.959, 0.957, 0.955, 0.950, 0.945, 0.940, 0.938, 0.935, 0.930, 0.925, 0.920, 0.917, 0.915, 0.915, 0.912, 0.910, 0.905, 0.900, 0.885, 0.870, 0.820
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.880, 0.877, 0.873, 0.865, 0.850, 0.833, 0.815, 0.790, 0.757, 0.715, 0.670, 0.623, 0.578, 0.540, 0.505, 0.490, 0.490, 0.500, 0.530, 0.570, 0.610, 0.610, 0.560, 0.430
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.880, 0.880, 0.877, 0.870, 0.860, 0.850, 0.840, 0.820, 0.800, 0.790, 0.780, 0.770, 0.760, 0.755, 0.750, 0.745, 0.740, 0.730, 0.720, 0.705, 0.690, 0.660, 0.620, 0.530
          ],
        },
      ],
      't': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.958, 0.955, 0.953, 0.950, 0.946, 0.942, 0.936, 0.930, 0.922, 0.917, 0.910, 0.905, 0.900, 0.897, 0.896, 0.896, 0.897, 0.901, 0.904, 0.905, 0.905, 0.898, 0.886, 0.864
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.958, 0.957, 0.956, 0.955, 0.955, 0.954, 0.953, 0.952, 0.952, 0.951, 0.951, 0.952, 0.952, 0.953, 0.953, 0.953, 0.952, 0.952, 0.950, 0.950, 0.948, 0.947, 0.945, 0.943
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.870, 0.866, 0.860, 0.851, 0.839, 0.823, 0.805, 0.788, 0.769, 0.750, 0.730, 0.714, 0.703, 0.695, 0.693, 0.694, 0.701, 0.715, 0.735, 0.761, 0.788, 0.803, 0.790, 0.738
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.870, 0.862, 0.861, 0.860, 0.859, 0.858, 0.856, 0.853, 0.852, 0.850, 0.850, 0.849, 0.849, 0.848, 0.848, 0.848, 0.848, 0.848, 0.847, 0.846, 0.845, 0.841, 0.836, 0.823
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.625, 0.621, 0.614, 0.603, 0.584, 0.561, 0.530, 0.494, 0.452, 0.408, 0.366, 0.325, 0.291, 0.263, 0.243, 0.237, 0.243, 0.260, 0.298, 0.353, 0.434, 0.550, 0.598, 0.595
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.625, 0.621, 0.615, 0.613, 0.605, 0.599, 0.593, 0.588, 0.586, 0.588, 0.593, 0.601, 0.610, 0.620, 0.626, 0.629, 0.628, 0.621, 0.611, 0.600, 0.587, 0.570, 0.553, 0.515
          ],
        },
      ],
      'x': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.950, 0.947, 0.944, 0.940, 0.935, 0.930, 0.923, 0.916, 0.908, 0.902, 0.895, 0.890, 0.885, 0.882, 0.881, 0.880, 0.881, 0.884, 0.887, 0.887, 0.883, 0.875, 0.864, 0.852
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.950, 0.949, 0.948, 0.947, 0.946, 0.945, 0.944, 0.943, 0.942, 0.941, 0.941, 0.942, 0.943, 0.944, 0.945, 0.946, 0.946, 0.946, 0.946, 0.946, 0.946, 0.946, 0.945, 0.944
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.840, 0.835, 0.827, 0.815, 0.800, 0.780, 0.760, 0.740, 0.720, 0.700, 0.680, 0.665, 0.655, 0.650, 0.650, 0.654, 0.663, 0.678, 0.700, 0.727, 0.757, 0.780, 0.780, 0.750
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.840, 0.829, 0.828, 0.827, 0.826, 0.825, 0.824, 0.823, 0.822, 0.821, 0.821, 0.822, 0.823, 0.824, 0.825, 0.826, 0.826, 0.826, 0.826, 0.826, 0.826, 0.826, 0.825, 0.824
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.540, 0.535, 0.527, 0.515, 0.495, 0.470, 0.435, 0.395, 0.350, 0.305, 0.265, 0.226, 0.195, 0.170, 0.156, 0.153, 0.160, 0.180, 0.220, 0.280, 0.375, 0.530, 0.610, 0.650
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.540, 0.535, 0.528, 0.527, 0.520, 0.515, 0.510, 0.510, 0.515, 0.521, 0.530, 0.545, 0.560, 0.575, 0.585, 0.590, 0.590, 0.585, 0.575, 0.565, 0.553, 0.540, 0.530, 0.510
          ],
        },
      ],
    }
  },
  '107': {
    data: {
      's': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.975, 0.975, 0.975, 0.975, 0.974, 0.973, 0.972, 0.971, 0.970, 0.967, 0.960, 0.953, 0.943, 0.935, 0.930, 0.925, 0.920, 0.917, 0.915, 0.917, 0.920, 0.927, 0.940, 0.960
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.975, 0.975, 0.970, 0.965, 0.960, 0.955, 0.950, 0.945, 0.940, 0.935, 0.930, 0.925, 0.920, 0.917, 0.915, 0.915, 0.915, 0.913, 0.910, 0.890, 0.860, 0.800, 0.700, 0.600
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.925, 0.925, 0.925, 0.925, 0.925, 0.923, 0.920, 0.913, 0.900, 0.880, 0.860, 0.840, 0.820, 0.797, 0.775, 0.752, 0.730, 0.715, 0.712, 0.720, 0.742, 0.775, 0.815, 0.860
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.925, 0.925, 0.922, 0.91, 0.900, 0.890, 0.870, 0.855, 0.840, 0.820, 0.805, 0.790, 0.780, 0.770, 0.765, 0.760, 0.755, 0.750, 0.740, 0.730, 0.720, 0.700, 0.640, 0.520
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.775, 0.775, 0.775, 0.772, 0.764, 0.750, 0.730, 0.705, 0.670, 0.630, 0.580, 0.530, 0.483, 0.440, 0.397, 0.360, 0.335, 0.325, 0.330, 0.350, 0.390, 0.443, 0.510, 0.580
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.775, 0.765, 0.750, 0.725, 0.690, 0.650, 0.600, 0.550, 0.505, 0.460, 0.425, 0.400, 0.380, 0.365, 0.355, 0.360, 0.365, 0.375, 0.385, 0.385, 0.380, 0.360, 0.300, 0.230
          ]
        }
      ],
      't': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.956, 0.956, 0.956, 0.954, 0.952, 0.948, 0.944, 0.938, 0.933, 0.924, 0.915, 0.906, 0.896, 0.887, 0.879, 0.874, 0.871, 0.871, 0.874, 0.882, 0.892, 0.896, 0.891, 0.885
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.956, 0.956, 0.955, 0.954, 0.953, 0.951, 0.950, 0.949, 0.948, 0.946, 0.945, 0.942, 0.939, 0.934, 0.930, 0.923, 0.911, 0.896, 0.876, 0.850, 0.815, 0.778, 0.753, 0.735
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.809, 0.809, 0.809, 0.809, 0.809, 0.807, 0.804, 0.794, 0.779, 0.756, 0.733, 0.705, 0.678, 0.649, 0.624, 0.601, 0.584, 0.576, 0.576, 0.585, 0.606, 0.636, 0.681, 0.740
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.809, 0.801, 0.793, 0.775, 0.754, 0.740, 0.720, 0.701, 0.683, 0.663, 0.644, 0.633, 0.619, 0.609, 0.604, 0.603, 0.601, 0.593, 0.575, 0.546, 0.503, 0.445, 0.385, 0.318
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.636, 0.629, 0.621, 0.613, 0.602, 0.585, 0.564, 0.536, 0.501, 0.465, 0.422, 0.380, 0.346, 0.313, 0.283, 0.260, 0.245, 0.239, 0.240, 0.252, 0.281, 0.321, 0.375, 0.445
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.636, 0.623, 0.608, 0.586, 0.563, 0.538, 0.510, 0.475, 0.434, 0.398, 0.363, 0.340, 0.320, 0.309, 0.299, 0.293, 0.283, 0.274, 0.261, 0.246, 0.223, 0.195, 0.158, 0.118
          ]
        }
      ],
      'x': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.950, 0.950, 0.950, 0.947, 0.945, 0.940, 0.935, 0.927, 0.920, 0.910, 0.900, 0.890, 0.880, 0.871, 0.862, 0.857, 0.854, 0.855, 0.860, 0.870, 0.883, 0.885, 0.875, 0.860
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.950, 0.950, 0.950, 0.950, 0.950, 0.950, 0.950, 0.950, 0.950, 0.950, 0.950, 0.947, 0.945, 0.940, 0.935, 0.925, 0.910, 0.890, 0.865, 0.837, 0.800, 0.770, 0.770, 0.780
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.770, 0.770, 0.77, 0.770, 0.77, 0.768, 0.765, 0.755, 0.738, 0.715, 0.690, 0.660, 0.630, 0.600, 0.573, 0.550, 0.535, 0.530, 0.530, 0.540, 0.560, 0.590, 0.636, 0.700
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.770, 0.76, 0.75, 0.73, 0.705, 0.690, 0.670, 0.650, 0.630, 0.610, 0.590, 0.580, 0.565, 0.555, 0.550, 0.550, 0.550, 0.540, 0.520, 0.485, 0.430, 0.360, 0.300, 0.250
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.590, 0.580, 0.570, 0.560, 0.548, 0.530, 0.508, 0.480, 0.445, 0.410, 0.369, 0.330, 0.300, 0.270, 0.245, 0.227, 0.215, 0.210, 0.210, 0.219, 0.245, 0.280, 0.330, 0.400
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.590, 0.575, 0.560, 0.540, 0.520, 0.500, 0.480, 0.450, 0.410, 0.377, 0.342, 0.320, 0.300, 0.290, 0.280, 0.270, 0.255, 0.240, 0.220, 0.200, 0.170, 0.140, 0.110, 0.080
          ]
        }
      ],
    }
  },
  '137': {
    data: {
      's': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.970, 0.970, 0.970, 0.970, 0.970, 0.967, 0.965, 0.960, 0.953, 0.945, 0.940, 0.935, 0.930, 0.925, 0.920, 0.915, 0.910, 0.907, 0.905, 0.903, 0.902, 0.903, 0.908, 0.915
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.970, 0.968, 0.965, 0.960, 0.953, 0.948, 0.940, 0.930, 0.920, 0.910, 0.905, 0.900, 0.895, 0.893, 0.891, 0.887, 0.883, 0.875, 0.867, 0.860, 0.850, 0.845, 0.830, 0.810
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.875, 0.875, 0.875, 0.875, 0.875, 0.873, 0.870, 0.863, 0.855, 0.843, 0.825, 0.800, 0.775, 0.750, 0.725, 0.700, 0.675, 0.653, 0.640, 0.635, 0.645, 0.665, 0.695, 0.740
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.875, 0.87, 0.865, 0.857, 0.845, 0.820, 0.800, 0.770, 0.745, 0.720, 0.700, 0.680, 0.660, 0.650, 0.645, 0.640, 0.637, 0.635, 0.633, 0.630, 0.625, 0.627, 0.635, 0.648
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.690, 0.690, 0.690, 0.688, 0.682, 0.668, 0.645, 0.615, 0.580, 0.540, 0.495, 0.450, 0.400, 0.350, 0.305, 0.270, 0.242, 0.225, 0.220, 0.230, 0.260, 0.300, 0.365, 0.460
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.690, 0.680, 0.670, 0.640, 0.585, 0.530, 0.470, 0.400, 0.342, 0.285, 0.245, 0.210, 0.180, 0.170, 0.165, 0.170, 0.180, 0.190, 0.210, 0.220, 0.230, 0.250, 0.270, 0.305
          ],
        },      
      ],
      't': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.933, 0.933, 0.934, 0.936, 0.938, 0.939, 0.939, 0.938, 0.936, 0.932, 0.925, 0.916, 0.908, 0.899, 0.890, 0.881, 0.873, 0.866, 0.862, 0.861, 0.863, 0.867, 0.876, 0.889
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.933, 0.932, 0.931, 0.930, 0.928, 0.927, 0.925, 0.917, 0.909, 0.899, 0.894, 0.889, 0.885, 0.884, 0.883, 0.882, 0.881, 0.879, 0.875, 0.868, 0.858, 0.849, 0.834, 0.810
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.804, 0.805, 0.806, 0.808, 0.809, 0.811, 0.810, 0.806, 0.800, 0.788, 0.769, 0.744, 0.719, 0.690, 0.661, 0.633, 0.611, 0.596, 0.588, 0.588, 0.600, 0.620, 0.654, 0.703
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.804, 0.803, 0.801, 0.798, 0.789, 0.771, 0.755, 0.736, 0.721, 0.700, 0.685, 0.667, 0.653, 0.643, 0.638, 0.635, 0.632, 0.628, 0.623, 0.619, 0.614, 0.611, 0.609, 0.608
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.641, 0.638, 0.636, 0.633, 0.628, 0.617, 0.596, 0.563, 0.520, 0.478, 0.433, 0.389, 0.342, 0.300, 0.264, 0.233, 0.211, 0.203, 0.203, 0.217, 0.245, 0.281, 0.336, 0.415
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.641, 0.631, 0.618, 0.588, 0.544, 0.500, 0.451, 0.400, 0.348, 0.300, 0.264, 0.233, 0.210, 0.196, 0.191, 0.193, 0.199, 0.211, 0.229, 0.239, 0.245, 0.250, 0.263, 0.286
          ],
        },      
      ],
      'x': [
        {
          label: '1',
          ...yellow,
          ...solid,
          data: [
            null, 0.920, 0.920, 0.922, 0.925, 0.927, 0.930, 0.930, 0.930, 0.930, 0.927, 0.920, 0.910, 0.900, 0.890, 0.880, 0.870, 0.860, 0.852, 0.847, 0.847, 0.850, 0.855, 0.865, 0.880
          ],
        },
        {
          label: '2',
          ...yellow,
          ...dashed,
          data: [
            null, 0.920, 0.920, 0.920, 0.920, 0.920, 0.920, 0.920, 0.913, 0.905, 0.895, 0.890, 0.885, 0.882, 0.881, 0.880, 0.880, 0.880, 0.880, 0.877, 0.870, 0.860, 0.850, 0.835, 0.810
          ],
        },
        {
          label: '3',
          ...red,
          ...solid,
          data: [
            null, 0.780, 0.782, 0.783, 0.785, 0.787, 0.790, 0.790, 0.787, 0.782, 0.770, 0.750, 0.725, 0.700, 0.670, 0.640, 0.610, 0.590, 0.577, 0.571, 0.572, 0.585, 0.605, 0.640, 0.690
          ],
        },
        {
          label: '4',
          ...red,
          ...dashed,
          data: [
            null, 0.780, 0.78, 0.78, 0.778, 0.770, 0.755, 0.740, 0.725, 0.713, 0.693, 0.680, 0.663, 0.650, 0.640, 0.635, 0.633, 0.630, 0.625, 0.620, 0.615, 0.610, 0.605, 0.600, 0.595
          ],
        },
        {
          label: '5',
          ...blue,
          ...solid,
          data: [
            null, 0.625, 0.620, 0.618, 0.615, 0.610, 0.600, 0.580, 0.545, 0.500, 0.457, 0.412, 0.368, 0.323, 0.283, 0.250, 0.220, 0.200, 0.195, 0.197, 0.212, 0.240, 0.275, 0.326, 0.400
          ],
        },
        {
          label: '6',
          ...blue,
          ...dashed,
          data: [
            null, 0.625, 0.615, 0.600, 0.570, 0.530, 0.490, 0.445, 0.400, 0.350, 0.305, 0.270, 0.240, 0.220, 0.205, 0.200, 0.200, 0.205, 0.218, 0.235, 0.245, 0.250, 0.250, 0.260, 0.280
          ],
        },      
      ],
    }
  },
}



export const initialState = {
  labels: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
  datasets: [
    {
      label: '1',
      ...yellow,
      ...solid,
      data: [
        null, 0.970, 0.968, 0.965, 0.960, 0.955, 0.945, 0.930, 0.920, 0.910, 0.905, 0.900, 0.895, 0.890, 0.885, 0.877, 0.870, 0.865, 0.860, 0.860, 0.870, 0.883, 0.880, 0.864, 0.860
      ],
    },
    {
      label: '2',
      ...yellow,
      ...dashed,
      data: [
        null, 0.970, 0.968, 0.963, 0.957, 0.946, 0.930, 0.920, 0.910, 0.905, 0.900, 0.900, 0.905, 0.910, 0.915, 0.920, 0.925, 0.927, 0.930, 0.930, 0.925, 0.915, 0.900, 0.870, 0.840
      ],
    },
    {
      label: '3',
      ...red,
      ...solid,
      data: [
        null, 0.820, 0.815, 0.810, 0.805, 0.800, 0.795, 0.790, 0.780, 0.770, 0.760, 0.750, 0.740, 0.730, 0.720, 0.710, 0.695, 0.670, 0.640, 0.600, 0.550, 0.500, 0.450, 0.400, 0.350
      ],
    },
    {
      label: '4',
      ...red,
      ...dashed,
      data: [
        null, 0.820, 0.81, 0.8, 0.79, 0.770, 0.750, 0.720, 0.690, 0.655, 0.630, 0.610, 0.600, 0.595, 0.590, 0.585, 0.580, 0.575, 0.570, 0.565, 0.560, 0.550, 0.540, 0.530, 0.520
      ],
    },
    {
      label: '5',
      ...blue,
      ...solid,
      data: [
        null, 0.600, 0.590, 0.580, 0.570, 0.560, 0.550, 0.540, 0.530, 0.520, 0.510, 0.502, 0.495, 0.490, 0.487, 0.480, 0.470, 0.460, 0.430, 0.380, 0.320, 0.280, 0.260, 0.250, 0.250
      ],
    },
    {
      label: '6',
      ...blue,
      ...dashed,
      data: [
        null, 0.600, 0.550, 0.500, 0.460, 0.430, 0.400, 0.375, 0.350, 0.325, 0.310, 0.300, 0.295, 0.293, 0.290, 0.290, 0.290, 0.292, 0.295, 0.297, 0.298, 0.300, 0.297, 0.295, 0.290
      ],
    },
  ]
}

