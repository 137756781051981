import React from "react"
import { gsap } from "gsap"

import Cx from "classnames"
import styles from "./styles.scss"
import ImgBg from "~/app/components/ImgBg"
import background from "~/app/assets/images/bg.jpg"
import LinktreeList from "~/app/components/LinktreeList"
import { NavLink } from "react-router-dom"
import LogoT from "~/app/components/LogoT"
import LogoTribe from "~/app/components/LogoTribe/logo"
import Form from "~/app/components/Form"
import { emailSignUpForm } from "./emailSignUpForm"
import Heading from "~/app/components/Heading"

import { apiBaseUrl } from "~/app/utils/apiConfig"
import { Helmet } from "react-helmet"
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormSubmitted: false,
      links: [],
    };
    this.formRef = React.createRef();
  }

  async componentDidMount() {
    await this.fetchLinktreeData()

    // Animate the form if the ref is set
    if (this.formRef.current) {
      window.setTimeout(() => {
        gsap.to(this.formRef.current, {
          opacity: 1,
          duration: 1,
          ease: "power3.in",
        })
      }, 1000)
    }
  }

  // We need to fetch data on Links collection from Directus
  // This is later used to render links on Linktree page
  fetchLinktreeData = async () => {
    const res = await fetch(`${apiBaseUrl}/items/links`)
    const parsedLinksData = await res.json()
    this.setState({ links: parsedLinksData.data })
  }

  handleFormSuccess = () => {
    this.setState({
      isFormSubmitted: true,
    })
  }

  renderForm = () => {
    let formTemplate = null;
    if (!this.state.isFormSubmitted) {
      formTemplate = <Form
        fields={emailSignUpForm.fields}
        buttonLabel="Subscribe"
        onSubmit={emailSignUpForm.callback}
        onSuccess={this.handleFormSuccess}
        classNameButton={Cx(styles.formButton)}
        classNameField={Cx(styles.formField)}
      />
    } else {
      formTemplate = <div className={Cx(styles.submitMessage)}>Thanks for signing up!</div>
    }
    return (
      <div ref={this.formRef} style={{ opacity: 0 }} className={Cx(styles.formContainer)}>
        <Heading level={2} className={Cx(styles.formHeading)}>Sign up to newsletter</Heading>
        {formTemplate}
      </div>
    )
  }

  render() {
    const { links } = this.state
    const meta = {
      title: "Linktree",
      description: "",
    }

    return (
      <div className={Cx(styles.gridContainer)}>
        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <link rel="canonical" href={`${window.location.origin}/specs`} />
        </Helmet>
        <ImgBg
          src={background}
          className={Cx(styles.bg)}
          readyClassName={Cx(styles.bgReady)}
        />
        <div className={Cx(styles.topLogoContainer)}>
          <NavLink exact to="/">
            <LogoT className={Cx(styles.logoT)} />
          </NavLink>
        </div>
        <div className={Cx(styles.container)}>
          {this.state.links.length !== 0 && <LinktreeList links={links} />}
        </div>
        <div className={Cx(styles.container)}>{this.renderForm()}</div>
        <div className={Cx(styles.bottomLogoContainer)}>
          <div className={Cx(styles.bottomLogo)}>
            <LogoTribe />
          </div>
        </div>
      </div>
    )
  }
}
