import React from "react"

import TuningDesktop from "./TuningDesktop"
import TuningMobile from "./TuningMobile"
import { isMobile } from "~/app/helpers"
import { Helmet } from "react-helmet"

export default class extends React.Component {
  componentDidMount() {
    window.addEventListener("resize", this.onResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
  }

  onResize = () => {
    this.forceUpdate()
  }

  render() {
    const meta = {
      title: "Tuning",
      description: "Individually tune your set to enhance or distort elements of color and light."
    }

    return (
      <div>
        <Helmet>
          <title>{meta.title}</title>
          <meta name='description' content={meta.description} />
          <link rel="canonical" href={`${window.location.origin}/tuning`}  />
        </Helmet>
        {!isMobile() && <TuningDesktop />}
        {isMobile() && <TuningMobile />}
      </div>
    )
  }
}
