.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 550px;
  margin: 0 auto;
  min-width: 275px;
  color: var(--white);
}

.formField {
  display: flex;
  flex-direction: column;

  label {
    font: var(--f1);
    color: var(--gold);
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 0.5rem;
    font-size: 15px;
  }

  input,
  textarea {
    border: none;
    border-bottom: 2px solid var(--gold);
    // Represents 30 tracking, which is what the style guide suggests for
    // Calibre.
    letter-spacing: 0.48px;
    padding: 0.5rem;
    padding-left: 0;
    color: var(--white);
    font-size: 16px;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }

  input::placeholder,
  textarea::placeholder {
    color: var(--white);
    opacity: 0.3;
  }

  textarea {
    resize: vertical;
  }

  &.error {
    input,
    textarea {
      border-color: var(--red);
    }
  }

  .errorText {
    color: var(--red);
    font-size: 16px;
    letter-spacing: normal;
    text-align: left;
    margin-top: 0.5rem;
  }
}

.formButton {
  font: var(--f1);
  font-size: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: rgba(var(--brown), 0.2);
  border: 1px solid var(--gold);
  color: var(--white);
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 50%;
  align-self: center;
  flex-shrink: 0;

  &:hover {
    background-color: var(--gold);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: rgba(var(--whiteFaded), 0.2);
  }
}

.baseErrorText {
  color: var(--red);
  font-size: 16px;
  letter-spacing: normal;
  text-align: center;
}

.line {
  border-bottom: 1px solid var(--whiteFaded);
  transform: scaleX(0);
  transition: transform 1.4s;
  margin-bottom: 40px;
}

.lineShow {
  transform: scaleX(1);
}