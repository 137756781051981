@import "~/app/helpers/helpers";

.description {
  display: flex;
  position: absolute;
  left: 30px;
  bottom: 30px;
  padding: 30px 30px 10px 10px;
  z-index: 10002;
  color: var(--gold);
  font-size: 14px;
  cursor: pointer;
  opacity: 0;

  @include mq($from: xxwide) {
    font-size: 16px;
  }

  @include mq($until: tablet) {
    transform: translateX(40px) rotate(-90deg);
    transform-origin: bottom left;
    padding-bottom: 30px;
    left: 40px;
  }
}

// hide Description while sliding
:global(.fp-section) .description.animationComplete {
  opacity: 0!important;
  transition: .3s opacity ease-in-out;
}
:global(.fp-section.active.fp-completely) .description.animationComplete {
  opacity: 1!important;
}

.description.open {
  color: white!important;
  fill: white;
}

// hide description when hovering on mobile
:global(.fp-section.active.fp-completely) .description.animationComplete.open {
  @include mq($until: tablet ){
    opacity: 0 !important;
    transition: .3s opacity ease-in-out;
  }
}

.descriptionText {
  text-transform: uppercase;
  opacity: 0;
  font-weight: bold;
}
.description.open .descriptionText {
  transition: .3s color ease-in-out;
  color: white!important;
}


.burger {
  width: 32px;
  height: 19px;
  margin-right: 20px;
}
.burger line {
  transition: .3s stroke;
}
.description.open .burger line {
  stroke: white;
}