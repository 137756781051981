import React from "react"
import Cx from "classnames"
import styles from "./styles.css"

class VideoWrapper extends React.Component {
  static defaultProps = {
    innerRef: () => {},
    hidePosterOnPlaying: false
  }

  state = {
    videoReady: false,
    posterReady: false
  }

  componentDidMount() {
    // Auto-play
    // this.play()
  }

  play() {
    let p = this._player.play()
    if (p) {
      p.catch(e => {
        if (this.props.hidePosterOnPlaying) {
          this.setState({
            videoReady: false
          })
        }
        if (this.props.onCannotPlay) this.props.onCannotPlay()
      })
    }
  }

  onPosterLoaded = () => {
    this.setState({
      posterReady: true
    })
    if (this.props.onPosterLoaded) this.props.onPosterLoaded()
  }

  onLoadedData = () => {
    if (!this.props.hidePosterOnPlaying) {
      this.setState({
        videoReady: true
      })
    }
    if (this.props.onLoadedData) this.props.onLoadedData()
  }

  onPlay = () => {
    if (this.props.hidePosterOnPlaying) {
      this.setState({
        videoReady: true
      })
    }
    if (this.props.onPlay) this.props.onPlay()
  }

  handleOnEnded = () => {
    if (this.props.onEnded) this.props.onEnded()
  }

  render() {
    if (this.props.isCurrent && this._player && this._player.paused) {
      this._player.currentTime = 0
      this.play()
    } else if (this._player && this.props.isOrigin) {
      this._player.pause()
    }

    let showPoster =
      this.props.isSkippingMoreThanOneSlide &&
      !this.props.isCurrent &&
      !this.props.isOrigin

    return (
      <div
        className={Cx(styles.wrapper, this.props.className)}
        style={this.props.style}
        ref={c => {
          this._video = c
        }}
      >
        {(this.props.isCurrentOrNext || this.props.isPrevious || this.props.isOrigin) && (
          <video
            width={this.props.width}
            height={this.props.height}
            src={this.props.src}
            muted
            playsInline
            loop={this.props.loop}
            onLoadedData={this.onLoadedData}
            onEnded={() => this.handleOnEnded()}
            onPlay={this.onPlay}
            className={Cx(
              styles.video,
              {[styles.hideVideo]: this.props.isSkippingMoreThanOneSlide && !this.props.isCurrent}
            )}
            // data-autoplay='true' // fullpagejs can handle play/pause
            ref={c => {
              this._player = c
              this.props.innerRef(c)
            }}
          />
        )}
        {(this.props.logoAnimationComplete) && (
          <img
            className={Cx(
              styles.poster,
              {[styles.showPoster]: showPoster}
            )}
            src={this.props.poster}
          />
        )}
      </div>
    )
  }
}

export default VideoWrapper
