import React from "react";

import Cx from "classnames";
import styles from "./styles.scss";
import { Helmet } from "react-helmet";
import Navigation from "~/app/components/Navigation";
import LogoTribe from "~/app/components/LogoTribe";
import image from "~/app/assets/images/bg-obscura37-big.jpg";
import Img from "~/app/components/Img";
import Form from "~/app/components/Form";
import { emailSignUpForm } from "./emailSignUpForm";
import Heading from "~/app/components/Heading";
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFormSubmitted: false,
    };
  }

  handleFormSuccess = () => {
    this.setState({
      isFormSubmitted: true,
    });
  }

  displaySubmitButton = (event) => {
    const submitButton = document.querySelector('button[type="submit"]');

    if (event.target.value) {
      submitButton.classList.add(styles.formButtonVisible);
    } else {
      submitButton.classList.remove(styles.formButtonVisible);
    }
  }

  renderForm = () => {
    let formTemplate = null;
    if (!this.state.isFormSubmitted) {
      formTemplate = <Form
        fields={emailSignUpForm.fields}
        buttonLabel="submit"
        onSubmit={emailSignUpForm.callback}
        onSuccess={this.handleFormSuccess}
        onChange={this.displaySubmitButton}
        classNameButton={Cx(styles.formButton)}
        classNameField={Cx(styles.formField)}
        classNameContainer={Cx(styles.form)}
        withHiddenSubmitButton={true}
        withExpandingLine={true}
      />
    } else {
      formTemplate = <div className={Cx(styles.submitMessage)}>Thanks for registering your interest in OBSCURA<span>37</span>, we'll be in touch soon</div>
    }
    return (
      <>
        <Heading level={2} className={Cx(styles.formHeading)}>
          <span className={Cx(styles.formHeadingAccent)}>Pre-Register</span> your interest
        </Heading>
        {formTemplate}
      </>
    )
  }

  render() {
    const meta = {
      title: "Obscura37 by Blackwing7",
      description: ""
    }

    return (
      <div>
        <Helmet>
          <title>{meta.title}</title>
          <meta name='description' content={meta.page} />
          <link rel="canonical" href={`${window.location.origin}/obscura`} />
        </Helmet>
        <Navigation />
        <LogoTribe />
        <div className={Cx(styles.page)}>
          <div className={Cx(styles.container)}>
            <div className={Cx(styles.header)}>
              <div className={Cx(styles.headerObscura)}>OBSCURA</div>
              <div className={Cx(styles.headerThirtySeven)}>37</div>
            </div>
            <div className={Cx(styles.imageContainer)}>
              <Img src={image} className={Cx(styles.image)} readyClassName={Cx(styles.imageReady)} />
            </div>
            <div className={Cx(styles.description)}>
              OBSCURe/a is our attempt to hand those who we love the most,
              our supporters, a tool that will allow them to reconnect to their
              childhood senses. The origin story of their first tastes, their first
              smells. As cinematographers, a reconnection to the origin of our
              art form, the first lens.
            </div>
            <div className={Cx(styles.signUp)}>
              {this.renderForm()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
