/* eslint-disable react/jsx-no-bind */
import React from "react"
import { withRouter } from "react-router-dom"
import ReactFullpage from "@fullpage/react-fullpage"
import { gsap } from "gsap"
import Cx from "classnames"

import styles from "./styles.scss"
import Video from "~/app/components/Video"
import Arrow from "~/app/components/Arrow"
import Credit from "~/app/components/Credit"
import { isMobile, isTouch } from "~/app/helpers.js"
import { apiBaseUrl } from "~/app/utils/apiConfig.js"
import { fullPageJsLicense } from "~/app/utils/fullPageJsLicense"

// Video dimensions are hardcoded.
const VIDEO_HEIGHT = "1080"
const VIDEO_WIDTH = "1920"

class Videos extends React.Component {
  state = {
    currentSlide: 0,
    logoAnimationComplete: false,
    videosData: [],
    firstLoad: true,
  }

  async componentDidMount() {
    window.addEventListener("resize", this.resizeFullpageNav)

    let delay = 4450

    if (isMobile()) {
      delay = 1000
    }

    setTimeout(() => {
      this.setState({ logoAnimationComplete: true })
    }, delay)

    // video credits need to be fetched before animating navigation dots
    // because the number of dots depends on the number of videos
    await this.fetchVideosData()
    this.revealNavigationDots(delay)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFullpageNav)
  }

  // fetch video credits from Directus
  fetchVideosData = async () => {
    const res = await fetch(`${apiBaseUrl}/items/featured_videos`)
    const parsedVideosData = await res.json()
    this.setState({ videosData: parsedVideosData.data })
  }

  // finds the navigation dots and changes the opacity from 0 to 1
  revealNavigationDots = (delay) => {
    gsap.fromTo(
      document.getElementById("fp-nav"),
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: delay / 1000,
      }
    )
  }

  handleClickNext = () => {
    if (this.state.currentSlide < this.state.videosData.length - 1) {
      this.setState({
        currentSlide: this.state.currentSlide + 1,
      })
      if (this.props.descOpen) this.props.onVideoDescToggle()
    }
  }

  onLeave = (_origin, destination) => {
    this.setState({
      currentSlide: destination.index,
      slideHasChanged: this.state.currentSlide !== destination.index,
      firstLoad: false
    })
  }

  resizeFullpageNav = () => {
    if (isTouch()) {
      const fullpageNav = document.querySelector("#fp-nav")
      if (fullpageNav) {
        fullpageNav.style.height = `${window.innerHeight}!important`
      }
    }
  }

  render() {
    const { descOpen, onVideoDescToggle, onLandingPage, landingOnLensed } =
      this.props
    const { logoAnimationComplete, currentSlide, videosData, slideHasChanged } =
      this.state

    // only try to render fullpage videos if the videos were fetched
    if (videosData.length === 0) {
      return <></>
    }
    return (
      <div>
        <div className={Cx(styles.videosWrapper)}>
          <div className={Cx(styles.videosWrapperInner)}>
            <ReactFullpage
              licenseKey={fullPageJsLicense}
              onLeave={this.onLeave}
              navigation
              credits={{ enabled: false }}
              // scrollingSpeed = {15000}
              render={({ state, fullpageApi }) => {
                // Destructure the state passed down from fullpage.js
                const { destination, origin } = state
                if (fullpageApi && this.state.firstLoad)
                  fullpageApi.silentMoveTo(1)
                if (fullpageApi)
                  // allow scrolling only after the landing page animations complete
                  fullpageApi.setAllowScrolling(logoAnimationComplete)
                return (
                  <ReactFullpage.Wrapper>
                    {videosData.map((slide, slideIndex) => {
                      const isCurrent = slideIndex === currentSlide
                      const isPrevious = slideIndex === currentSlide - 1
                      const isNext = slideIndex === currentSlide + 1
                      const isCurrentOrNext = isCurrent || isNext

                      const isSkippingMoreThanOneSlide =
                        destination &&
                        origin &&
                        Math.abs(destination.index - origin.index) > 1
                      // makes sure that the current video pauses when skipping to another slide, as opposed to just disappearing
                      const isOrigin = origin && origin.index === slideIndex

                      return (
                        <div className="section" key={slideIndex}>
                          <div className={Cx(styles.container)}>
                            <div className={Cx(styles.containerRef)}>
                              <Video
                                {...slide}
                                height={VIDEO_HEIGHT}
                                width={VIDEO_WIDTH}
                                descOpen={descOpen}
                                onVideoDescToggle={onVideoDescToggle}
                                isPrevious={isPrevious}
                                isCurrent={isCurrent}
                                isCurrentOrNext={isCurrentOrNext}
                                slideHasChanged={slideHasChanged}
                                slideNumber={slideIndex}
                                isSkippingMoreThanOneSlide={
                                  isSkippingMoreThanOneSlide
                                }
                                isOrigin={isOrigin}
                                onLandingPage={onLandingPage}
                                logoAnimationComplete={logoAnimationComplete}
                                onEnded={() => {
                                  if (
                                    isCurrent &&
                                    destination &&
                                    destination.isLast
                                  ) {
                                    fullpageApi.moveTo(1) // first slide
                                  } else if (isCurrent) {
                                    fullpageApi.moveSectionDown()
                                  }
                                }}
                              />
                              <Credit
                                onVideoDescToggle={onVideoDescToggle}
                                descOpen={descOpen}
                                landingOnLensed={landingOnLensed}
                                credit={slide}
                                currentSlide={currentSlide}
                              />
                            </div>

                            {((destination && !destination.isLast) ||
                              !destination) && (
                              <span
                                onClick={() => {
                                  this.handleClickNext()
                                  fullpageApi.moveSectionDown()
                                }}
                                className={Cx(styles.arrowWrap, {
                                  [styles.arrowFadeIn]:
                                    this.state.logoAnimationComplete,
                                })}
                              >
                                <Arrow className={styles.arrow} />
                              </span>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </ReactFullpage.Wrapper>
                )
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Videos)
