import useSWR from 'swr'
import { fetcher } from "./fetcher"
import { apiBaseUrl } from "../utils/apiConfig"

// this hook is used to fetch the credits data from directus
// It also returns an error and isLoading value which can be used to determine the current state
// of the request.
// Usage: const { credits, creditsError, isLoadingCredits } = useCredits()
// Return Value:
//  On Success:
//    { data: [{id: 1, title: 'Assassin',.... }, ...]}
//  On Failure:
//    {undefined, error, false}

export const useCredits = () => {
  const { data, error, isLoading } = useSWR(`${apiBaseUrl}/items/credits?sort=-release_date&limit=300`, fetcher)

  return {
    credits: data?.data,
    creditsError: error,
    isLoadingCredits: isLoading
  }
}
