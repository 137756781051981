export const contactForm = {
  fields: [
    {
      label: "Name",
      component: "input",
      name: "name",
      attrs: {
        placeholder: "Name",
      },
    },
    {
      label: "Email",
      component: "input",
      name: "email",
      attrs: {
        type: "email",
        placeholder: "Email",
      },
    },
    {
      label: "Location",
      component: "input",
      name: "location",
      attrs: {
        type: "location",
        placeholder: "City, Country"
      }
    },
    {
      label: "Message",
      component: "textarea",
      name: "message",
      attrs: {
        placeholder: "How can we help?",
        rows: 5,
      },
    },
  ],

  callback: (values) => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, {action: 'submit'}).then((token) => {
          values.token = token
          fetch("/api/submitContactForm", {
            method: "POST",
            cache: "no-cache",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          })
            .then(resolve, reject)
        });
      });
    })
  },
}