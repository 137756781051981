@import "~/app/helpers/helpers";

/* .lensesArea {
  background: black;

  background-image: radial-gradient(circle, rgba(149,122,63,.3) 0%, rgba(0,0,0,1) 56%);
  background-size: 80vw 80vw;
  background-position: 55% 50%;
  background-repeat: no-repeat;

}
.lensesAreaNoise {
  height: 100vh;

  background-image: url(/assets/images/noise.png);
  background-position: left top;
  background-repeat: repeat;
  opacity: .1;
} */

.firstSection {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 140px;
}

.header {
  text-align: center;
  @extend %header;
}

.lengths {
  display: flex;
  justify-content: space-between;
  margin-top: 42px;
}

.length {
  color: var(--gold);
  opacity: .3;
  transition: .3s all;
  cursor: pointer;

  @media (max-height: 768px) {
    font-size: 17px;
  }
}

.length span {
  color: var(--white);
  font: var(--f3);
  font-size: 49px;
  letter-spacing: 0;

  @include mq($until: xwide) {
    font-size: 40px;
  }

  @include mq($until: desktop) {
    font-size: 24px;
  }

  @media (max-height: 768px) {
    font-size: 40px;
  }
}
.lengthActive, .length:hover {
  opacity: 1;
}

.diagram {
  width:calc(11 / 18 * 100%);
  margin-left: calc(1 / 18 * 100%);
  
  @include mq($until: wide) {
    width:calc(17 / 18 * 100%);
    margin: 0 auto;
  }
}
.diagram img { 
  width: 100%;
  opacity: .8;

  @include mq($from: desktop) {
    position: relative;
    top: -10px;
  }

  @include mq($from: xwide) {
    top: -30px;
  }
}

.pageBody {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  align-items: center;

  @include mq($until: wide) {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.diagramSectionOuter {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  opacity: 0;
  margin: 180px auto 0 auto;

  @include mq($until: desktop) {
    margin-top: 100px;
  }
}

.diagramSectionInner {
  margin: 0 auto;
  transition: 4s opacity, 4s transform;
  padding: 0 calc(1 / 26 * 100%);
  width: 100%;
}

.diagramText {
  line-height: 1.25;
  letter-spacing: 0.6px;
  width: 33%;
  min-height: 322px;
  min-width: 382px;
  @include mq($until: wide) {
    min-height: 0;
    width: 50%;
  }
}

.inspiration {
  ul {
    list-style-type: none;
    font-size: 17px;
    letter-spacing: 0.6px;
    li::before {
      content: "—";
      margin-right: 5px;
    }
  }
  margin-bottom: 20px;
  opacity: 1;
  sup {
    top: -0.7em;
  }
}

.inspirationHeader {
  margin-bottom: 10px;
  font-size: 19px;
  letter-spacing: 1.6px;
  font-weight: bold;
}

.testimonialQuote {
  font: var(--f1);
  margin-top: 40px;
  letter-spacing: 0.6px;
  line-height: 1.25;

  @include mq($from: xxwide) {
    font-size: 18px;
  }
  @include mq($until: xxwide, $and: '(max-height: 630px)') {
    font-size: 18px;
  }
}

.author {
  text-align: right;
  text-transform: uppercase;
  margin-top: 12px;
  letter-spacing: 1.6px;
  .authorName {
    font-weight: bold;
    letter-spacing: 2.5px;
  }
  .authorCompany {
    font-style: italic;
  }
}

.bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('~/app/assets/images/bg.jpg');
  background-position: 50% 50%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0;
  transition: .5s opacity;
}
.bgReady {
  opacity: 1;
}

.lensesBg {
  position: absolute;
}


.arrowWrap {
  opacity: 0;
  transition: .3s opacity;
}
.arrowWrap.visible {
  opacity: 1;
}
