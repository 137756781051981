import React from "react"
import Cx from "classnames"
import styles from "./styles.scss"

const FormButton = ({ label, color, disabled, className }) => {
  const buttonStyles = Cx(styles.formButton, {
    [styles.disabled]: disabled,
  }, className)

  return (
    <button
      className={buttonStyles}
      type="submit"
      disabled={disabled}
      style={{ backgroundColor: color }}
    >
      {label}
    </button>
  )
}

export default FormButton
