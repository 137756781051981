import React from "react"
import Cx from "classnames"
import styles from "./styles.scss"
import VideoWrapper from "~/app/components/VideoWrapper"
import { isTouch, isMobile, isLandscape, getCoverDimensions, getContainDimensions } from "~/app/helpers.js"
const IS_TOUCH = isTouch()

class Video extends React.Component {
  static defaultProps = {
  }

  state = {
    ready: false,
    width: 0,
    height: 0,
    over: false,
    cannotPlay: false
  }

  componentDidMount() {
    document.documentElement.classList.add("black")
    window.addEventListener("resize", this.onResize)

    this.resizeTimer = setTimeout(() => this.onResize(), 500)

    if (IS_TOUCH) {
      this.open()
      this.iconTimer = setTimeout(() => this.close(), 1000)
    }

    // if (this.props.audio) bus.on("sound:mute", this.onMuteChange)
  }

  componentDidUpdate(prevProps) {
    if (this.props.isCurrentOrNext !== prevProps.isCurrentOrNext && !this.state.slideHasChanged) {
      this.setState({slideHasChanged: true})
    } else if (this.props.isCurrentOrNext === prevProps.isCurrentOrNext && this.state.slideHasChanged ) {
      this.setState({slideHasChanged: false})
    }
  }
  

  componentWillUnmount() {
    this._unmounted = true
    clearTimeout(this.timer)
    clearTimeout(this.iconTimer)
    clearTimeout(this.resizeTimer)
    document.documentElement.classList.remove("black")
    window.removeEventListener("resize", this.onResize)
  }

  onResize = () => {
    let rect = this._el.getBoundingClientRect()
    let sizingFunc = this.props.contain
      ? getContainDimensions
      : getCoverDimensions

    let dimens
    if (this.props.coverCropReposition && isMobile() && isLandscape()) {
      dimens = sizingFunc(
        this.props.height / this.props.width,
        rect.height,
        rect.width
      )
    } else {
      dimens = sizingFunc(
        this.props.width / this.props.height,
        rect.width,
        rect.height
      )
    }
    this.setState({
      width: dimens[0],
      height: dimens[1]
    })
  }

  onReady = () => {
    this.setState({
      ready: true
    })
  }

  open() {
    clearTimeout(this.iconTimer)
    this.setState({
      over: true
    })
  }

  close() {
    this.setState({
      over: false
    })
  }

  onDescToggle = () => {
    this.props.onVideoDescToggle()
  }

  onOverlayClick = () => {
    this.props.onVideoDescToggle()
  }

  getOffset = () => {
    if (window.innerWidth > 600) return "320px"
    return "70px"
  }

  onPlay = () => {
    this.setState({ cannotPlay: false })
  }

  onPlayClick = () => {
    this._playerWrapper.play()
  }

  onCannotPlay = () => {
    if (this._unmounted) return
    this.setState({ cannotPlay: true })
  }

  render() {
    let wrapperStyle = {
      width: this.state.width + "px",
      height: this.state.height + "px"
    }

    return (
      <div
        className={Cx(styles.container)}
        onTouchEnd={this.onTouchEnd}
        ref={c => {
          this._el = c
        }}
      >
        <VideoWrapper
          style={wrapperStyle}
          src={this.props.src}
          poster={this.props.poster}
          width={this.props.width}
          height={this.props.height}
          innerRef={c => {
            this._player = c
            if (this.props.innerRef) this.props.innerRef(c)
          }}
          ref={c => {
            this._playerWrapper = c
          }}
          onPlay={this.onPlay}
          onEnded={this.props.onEnded}
          onLoadedData={this.onReady}
          className={Cx(
            styles.videoWrapper,
            { [styles.coverCropReposition]: this.props.coverCropReposition },
            { [styles.ready]: this.state.ready },
            { [styles.isSkippingMoreThanOneSlide]: this.props.isSkippingMoreThanOneSlide }
          )}
          onCannotPlay={this.onCannotPlay}
          isCurrent={this.props.isCurrent}
          isCurrentOrNext={this.props.isCurrentOrNext}
          isPrevious={this.props.isPrevious}
          isSkippingMoreThanOneSlide={this.props.isSkippingMoreThanOneSlide}
          isOrigin={this.props.isOrigin}
          loop={this.props.loop}
          logoAnimationComplete={this.props.logoAnimationComplete}
          coverCropPosition={this.props.coverCropReposition}
        />

        <div
          className={Cx(styles.overlay, {
            [styles.active]: this.state.socialOpen || this.props.descOpen
          })}
          onClick={this.onOverlayClick}
        />
      </div>
    )
  }
}

export default Video
