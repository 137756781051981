import React, { useEffect, useRef, useState } from "react"
import Cx from "classnames"
import FormField from "./FormField"
import FormButton from "./FormButton"
import styles from "./styles.scss"
import ScrollMagic from 'scrollmagic';

const Form = ({
  buttonLabel,
  classNameButton,
  classNameField,
  classNameContainer=styles.form,
  fields,
  onSubmit,
  onSuccess,
  onChange,
  withHiddenSubmitButton=false,
  withExpandingLine=false
}) => {
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState([])
  const [submitting, setSubmitting] = useState(false)

  const line = useRef();

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })

    const hasError = errorDetail(event.target.name) !== null
    if (hasError) {
      setErrors({
        ...errors,
        [event.target.name]: null,
      })
    }

    if (withHiddenSubmitButton) {
      onChange(event);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors([])
    setSubmitting(true)
    onSubmit(values)
      .then((response) => {
        if (response.ok) {
          setValues({})
          onSuccess()
          return
        }

        response?.json().then((data) => {
          if (data.errors.length > 0) {
            setErrors(data.errors)
          }
        })
      })
      .catch((err) => {
        console.error({ err })
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const errorDetail = (id) => {
    if (errors.length > 0) {
      const error = errors.find((error) => error.id === id)
      if (error) {
        return error.detail
      }
    }

    return null
  }

  useEffect(() => {
    if (withExpandingLine) {
      const controller = new ScrollMagic.Controller();
      const scene = new ScrollMagic.Scene({ triggerElement: line.current, offset: 0 - window.innerHeight/2 })
        .setClassToggle(line.current, Cx(styles.lineShow))
        .addTo(controller)
    }
  } , []);

  return (
    <form className={Cx(classNameContainer)} onSubmit={handleSubmit}>
      {fields.map((field) => (
        <FormField
          key={field.name}
          {...field}
          value={values[field.name] || ""}
          error={errorDetail(field.name)}
          onChange={handleChange}
          className={classNameField}
        />
      ))}
      {withExpandingLine && <div className={Cx(styles.line)} ref={line} />}
      {errorDetail(undefined) && <p className={styles.baseErrorText}>Something went wrong, please try again.</p>}
      <FormButton
        label={buttonLabel}
        disabled={submitting}
        className={classNameButton}
      />
    </form>
  )
}

export default Form
