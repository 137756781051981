import React, {useCallback, useEffect, useRef} from "react"
import Cx from "classnames"
import { gsap } from 'gsap'

import styles from "./styles.scss"

function LinktreeList({ links }) {

  const listRefs = useRef([]);

  useEffect(() => {
    if (listRefs.current.length !== 0) {
      gsap.to(listRefs.current, {
        opacity: 1,
        duration: 1,
        stagger: 0.2, // This will animate each item one after the other with a 0.2s delay
        ease: 'power3.in'
      });
    }
  }, [links]);

  const upgradeIfEndsInSeven = useCallback((title) => {
    return title.endsWith('7') ? <span >{title.slice(0, -1)}<sup>7</sup></span> : title
  }, []);

  return (
    <ul className={Cx(styles.links)}>
      {links.map((link, i) => {
        return (
          <li 
            key={link.id} 
            ref={el => listRefs.current[i] = el} 
            style={{ opacity: 0 }}
          >
            <a href={link.url}>
              {upgradeIfEndsInSeven(link.title)}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export default LinktreeList
