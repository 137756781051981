import React from "react"
import Cx from "classnames"
import styles from "./styles.scss"

// This component shows the filter options for the credits
const Filter = (props) => {
  return (
    <div className={Cx(styles.filter)}>
      <div className={Cx(styles.filterName)}>
        {props.filterName}
        <hr />
      </div>
      <div className={Cx(styles.options)}>
        {props.options.map((type) => {
          const isHighlighted = type === props.selected
          // Standard tuning types are all one letter
          const isStandardTuning = type.name.length === 1
          return (
            <>
              <button
                onClick={() => props.onSelect(type)}
                key={type.id}
                className={Cx(
                  { [styles.selected]: isHighlighted },
                  { [styles.standardTuning]: isStandardTuning }
                )}
              >
                {type.name}
              </button>
            </>
          )
        })}
      </div>
    </div>
  )
}

export default Filter
