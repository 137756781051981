@import "~/app/helpers/helpers";

.sectionTuning {
  /* background: radial-gradient(circle, rgb(51, 37, 0) 0%, rgba(0,0,0,1) 100%); */
}

.circlesSection {
  width: calc(17 / 26 * 100%);
  max-width: 1440px;
  margin: 0 auto;
  opacity: 0;
  transition: .5s opacity;
  position: relative;
}
.ready .circlesSection {
  opacity: 1;
}

.letters {
  display: flex;
  max-width: 820px;
  margin: 0 auto;
  position: relative;
  top: -205px;
  height: 0;
  text-align: center;
}
.letters > div {
  width: 33%;
  font: var(--f2);
  color: var(--gold);
  font-size: 32px;
}

.circlesLabels {
  // margin: 30px auto 40px;
  margin: 0 auto;
  display: flex;
  text-align: center;
  width: 100%;

  @include mq($from: xwide) {
    // margin-top: 45px;
  }

}
.circlesLabel {
  @extend %header;
  top: 62.5%;
  position: absolute;
  width: 41.5%;  
  opacity: 0;

  @include mq($until: 1024px) {
    font-size: 15.5px;
  }

  @include mq($until: desktop) {
    font-size: 15px;
  }

  @include mq($from: xxwide) {
    top: 61%;
  }


}

.pageText {
  margin: 40px auto 0;
  text-align: center;
  line-height: 1.25;
  letter-spacing: 0.6px;
  color: var(--gold);
  opacity: 0;
  max-width: 950px;
  padding: 0 40px;

  @include mq($until: tuningPageText) {
    max-width: 670px;
  }


  @include mq($from: xxwide) {
    font-size: 19px;
    margin-top: 7vh;
    padding: 0;
  }
}


.chartSection {
  opacity: 0;
  transform: scaleY(.98);
  transition: 4s opacity, 4s transform;

  @include mq($from: xxwide) {
    width: calc( 18 / 26 * 100vw);
    max-width: 1580px;
  }
}
.chartSectionActive {
  opacity: 1;
  transform: scaleY(1);
}

.chartVariants {
  display: flex;
  justify-content: space-between;
}

.chartVariants > div {
  transition: .3s opacity;
  animation-name: chartVariantFadeout;
  animation-duration: .5s;
}

.chartVariants > div:hover,
.chartVariants > div.variantActive
 {
  opacity: 1!important;
}

.chartVariant {
  color: var(--gold);
  font: var(--f2);
  font-weight: lighter;
  font-size: 92px;
  cursor: pointer;
}

.chartVariant img {
  height: 68px;
  display: block;
  margin: 0 auto 30px;  
}
@media only screen and (max-height: 768px) {
  .chartVariant img {
    height: 48px;
    margin-bottom: 13px;
  }
}


.variantLabel {
  @extend %header;
  opacity: 0;

  @include mq($from: xxwide) {
    font-size: 19px;
  }

}
.chartVariants > div:hover .variantLabel,
.chartVariants > div.variantActive .variantLabel
 {
  opacity: 1;
}


.lengths {
  display: flex;
  justify-content: space-between;
  margin-top: 34px;

  @include mq($from: xxwide) {
    margin: 55px 0 50px;
  }
}
@media only screen and (max-height: 768px) {
  .lengths {
    margin-top: 30px;
  }
}

.length {
  color: var(--gold);
  opacity: .3;
  transition: .3s all;
  cursor: pointer;
  font-size: 14px;

  @include mq($from: xxwide) {
    font-size: 17px;
  }
}
.length span {
  color: var(--white);
  font: var(--f3);
  font-size: 30px;
  letter-spacing: 0;

  @include mq($from: xxwide) {
    font-size: 40px;
  }

}
@media only screen and (max-width: 600px) {
  .length span {

  }
}


.lengthActive, .length:hover {
  opacity: 1;
}

.chartArea {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
}
@media only screen and (max-height: 768px) {
  .chartArea {
    margin-top: 28px;
  }
}

.chartText {
  margin: 0 60px 0 0;
  max-width: 500px;
  width: calc(46% - 60px);

  line-height: 1.25;
  letter-spacing: 0.6px;
  font-size:17px;
  opacity: .7;

  @include mq($from: xxwide) {
    font-size: 19px;
  }
}
.longChartText {
  line-height: 1.15;
  letter-spacing: 0.5px;
  margin-right: 55px;
}

.chartWrapOuter {
  width: 57%;
  display: flex;
}
@media only screen and (max-height: 768px) {
  .chartWrapOuter {
    width: 54%;
  }
}

.chartWrap {
  width: calc(100% - 150px);
  height: 300px;
}
.chartWrap canvas {
  letter-spacing: 1px!important;
   /* width: 600px !important;
   height: 380px !important; */
}

.legend {
  width: 130px;
  margin-left: 20px;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}
.legend > div {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px;
}

.legend > div > * {
}
.legend hr {
  height: 3px;
  width: 40px;
  border:none;
  border-top: 3px dotted var(--gold);
  margin: 7px 18px 0 0;
}
.legend > div div {
  font-size: 10px;
  flex-grow: 1;
  color: rgba(255,255,255,.5);
  cursor: default;
  user-select: none;
}
