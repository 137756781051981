@import "~/app/helpers/helpers";

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.page {
  padding: 120px calc(1 / 26 * 100vw) 0;
  margin: 0 auto;
  overflow: hidden;

  @include mq($from: tablet) {
    font-size: 14px;
    padding: 96px 26px 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: relative;

  @include mq($from: tablet) {
    padding-top: 10px;
  }
}

.header {
  display: flex;
  color: #D9D9D9;
  text-align: center;
  font-family: var(--f1);
  align-items: flex-start;
  position: absolute;

  // Get it on top of the image
  z-index: 99;

  @include mq($from: tablet) {
    top: 70px;
  }

  @include mq($from: desktop) {
    top: 100px;
  }
}

.headerObscura {
  font-size: 25px;
  letter-spacing: 5px;
  font-weight: 300;
  vertical-align: top;
}

.headerThirtySeven {
  font-weight: 400;
  font-size: 15px;
}

.image {
  width: 172vw;
  max-width: 850px;
  height: calc(172vw * 0.692);
  max-height: calc(850px * 0.692);
  animation: fadeInAnimation ease 6s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @include mq($from: tablet) {
    width: 123vw;
    max-width: none;
    height: calc(123vw * 0.692);
    max-height: none;
  }

  @include mq($from: desktop) {
    width: 102vw;
    max-width: 1318px;
    height: calc(102vw * 0.692);
    max-height: 886px;
  }
}

.description {
  color: #D9D9D9;
  font-family: var(--f1);
  font-size: 16px;
  line-height: 18.75px;
  letter-spacing: 0.6px;
  text-align: left;
  font-weight: 400;
  padding: 20px 10px;
  margin-bottom: 70px;

  @include mq($from: tablet) {
    width: 470px;
    font-size: 17px;
    line-height: 21.25px;
  }
}

.formContainer {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 70px;

  > *+* {
    margin-top: 20px;
  }

  width: 100%;
}

.form {
  width: 100%;
  // So the form isn't flush with the bottom of the screen
  padding-bottom: 100px;
}

.formHeading {
  color: var(--white);
  font-size: 18px;
}

.formHeadingAccent {
  font-weight: 700;
}

.formField {
  margin: 40px 0 0;

  label {
    display: none;
  }
  input {
    border-bottom-width: 0px;
    text-align: center;

    &::placeholder {
      opacity: 1;
    }
  }
}

.formButton {
  background-color: var(--white);
  font-weight: 700;
  color: var(--black);
  border: 0;
  padding: 12px 30px;
  font-size: 18px;
  // Reset min-width
  min-width: 0;
  opacity: 0;
  pointer-events: none;
}

.formButtonVisible {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 1;
  pointer-events: auto;
}

.submitMessage {
  margin-top: 40px;
  margin-bottom: 100px;

  // matches .description
  font-size: 16px;
  line-height: 18.75px;
  letter-spacing: 0.6px;

  span {
    font-size: 12px;
    vertical-align: super;
  }

  @include mq($from: tablet) {
    padding: 0 80px;

    // matches .description
    font-size: 17px;
    line-height: 21.25px;
  }
}

.signUp {
  text-align: center;
  width: 100%;
  padding: 0px 10px;

  @include mq($from: tablet) {
    width: 600px;
  }
}
