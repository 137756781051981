@import "~/app/helpers/helpers";

.page {
  max-width: 1096px;
  padding-top: 100px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  opacity: 0;
  @extend %header;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.contactFormHeader {
  margin-bottom: 25px;
  color: var(--gold);
}

.credits {
  display: flex;
  justify-content: center;
  bottom: 30px;
  width: 100%;

  @include mq($until: desktop, $and: '(max-height: 420px)') {
    bottom: 10px;
  }

  &Items {
    a {
      color: var(--gold);
      display: block;
      padding: 5px;
      font-size: 11px;
      letter-spacing: 2px;
      transition: .3s opacity;
      font-weight: normal;
  
      &:hover {
        opacity: .5;
      }
  
      span {
        font-weight: bold;
      }
    }
  }
}

.submitMessage {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
}

.socialMediaLinks {
  margin-top: 20px;
}

.recaptchaLinks{
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  small {
    color: var(--gold);
    font: var(--f1);
    font-size: 10px;
    text-align: center;
  }
}
