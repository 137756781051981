import useSWR from 'swr'
import { fetcher } from "./fetcher"
import { apiBaseUrl } from "../utils/apiConfig"

// this hook is used to fetch the different project types from Directus using useSWR
// It also returns an error and isLoading value which can be used to determine the current state
// of the request.
// Usage: const { projectTypes, projectTypesError, isLoadingTuningTypes } = useProjectTypes()
// Return Value:
//  On Success:
//    { data: [{id: 1, name: 'Custom',...},...]}
//  On Failure:
//    {undefined, error, false}

export const useProjectTypes = () => {
  const { data, error, isLoading } = useSWR(`${apiBaseUrl}/items/project_types`, fetcher)

  return {
    projectTypes: data?.data,
    projectTypesError: error,
    isLoadingProjectTypes: isLoading
  }
}
