.arrow {
  padding: 20px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  transition: transform .4s cubic-bezier(0.13, 0.68, 0, 0.97);
  width: 84px;
  fill: var(--gold);
  cursor: pointer;
}
.arrow:hover {
  transform:translateX(-50%) scaleX(1.2);
}
.arrow polyline {
    stroke-width: 2px;
    fill: none;
    stroke: #957a3f;
    stroke-miterlimit: 10;    
}