import React from 'react'
import Cx from 'classnames'
import styles from './styles.scss'
import { gsap } from 'gsap'
import LogoTTop from '~/app/assets/images/logoT-top.png'
import LogoTCircle from '~/app/assets/images/logoT-circle.png'

class LogoTRaster extends React.Component {

  componentDidMount() {

    let tl = gsap.timeline()
    if (this.props.animated) {
      tl.to(
        this._topPath,
        {
          scaleX: 1,
          duration: 1.2,
          delay: .8
        }
      )
      tl.to(
        this._circlePath,
        {
          scale: 1,
          duration: 1.2,
          delay: "-1.2"
        }
      )

      if(!this.props.persist) {
        tl.to(
          this._topPath,
          {
            scaleX: 0,
            duration: 1.2,
            delay: 1.5
          }
        )
        tl.to(
          this._circlePath,
          {
            scale: 0,
            duration: 1.2,
            delay: "-1.2",
            onComplete: this.props.logoAnimationsComplete
          }
        )
      }
    }

  }

  topAnimate(el) {
    el.classList.add(Cx(styles.topAnimating))
  }
  topAnimateOut(el) {
    el.classList.remove(Cx(styles.topAnimating))
  }
  circleAnimate(el) {
    el.classList.add(Cx(styles.circleAnimating))
  }
  circleAnimateOut(el) {
    el.classList.remove(Cx(styles.circleAnimating))
  }
  
  render() {
    return (
      <div 
        className={Cx(styles.logo, this.props.className, {[styles.hidden]: (this.props.animated || this.props.inquireAnimation)})}
        ref={c=>{this._el=c}}
      >
        <img 
          ref={c=>{this._topPath=c}}
          className={Cx(styles.topPath)}
          src={LogoTTop}
        />
        <img 
          ref={c=>{this._circlePath=c}}
          className={Cx(styles.circlePath)}
          src={LogoTCircle}
        />
      </div>
    )
  }
}

export default LogoTRaster