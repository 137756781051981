@import "~/app/helpers/helpers";

.circlesWrap {
  position: relative;
  /* max-width: 880px; */
  margin: 0 auto;
}

.circles {
  opacity: .9;
  height: 80vh;
  margin-bottom: -80vh;
}


.letters {
  transform: translateY(-9px);
  display: flex;
  flex-direction: column;
  height: 80vh;
  margin-bottom: -80vh;
}

.circle {
  position: static;
  margin-top: -50px;
  width: 188px;
  height: 188px;
}
.circlesFill {
  stroke: none;
  fill: rgba(0,0,0,.2);
}
.circleStroke {
  stroke: var(--gold);
  fill: none;
  stroke-width: 0.2px;
}

.letter {
  position: static;
  margin-top: -50px;
  width: 188px;
  height: 188px;
  opacity: 1;
}