import React from "react"
import ReactFullpage from "@fullpage/react-fullpage"
import { gsap } from "gsap"

import Cx from "classnames"
import styles from "./styles.scss"
import Navigation from "~/app/components/Navigation"
import Arrow from "~/app/components/Arrow"
import LogoTribe from "~/app/components/LogoTribe"
import Circles from "../Circles"

import { Line } from "react-chartjs-2" // triggers console error 'Warning: componentWillMount has been renamed, and is not recommended for use.'
import { texts, chartData, initialState } from "../chartData"
import ImgBg from "~/app/components/ImgBg"
import background from "~/app/assets/images/bg.jpg"
import STXS from "~/app/assets/images/STX-S-crop.png"
import STXT from "~/app/assets/images/STX-T-crop.png"
import STXX from "~/app/assets/images/STX-X-crop.png"
import STXTblurred from "~/app/assets/images/STX-T.png"
import { fullPageJsLicense } from "~/app/utils/fullPageJsLicense"

const options = {
  // Force height on xxwide screens
  maintainAspectRatio: window.innerWidth < 1800,
  // aspectRatio: 4,
  animation: {
    // duration: 10000
  },
  // tooltips: {
  //   mode: 'index',
  //   intersect: false,
  // },
  // hover: {
  //   mode: 'nearest',
  //   intersect: true
  // },
  // scales: {
  //   yAxes: [{
  //     ticks: {
  //       beginAtZero:true,
  //       stepSize: 2
  //     }
  //   }],
  //   xAxes: [{
  //     ticks: {

  //       stepSize: 4
  //     }
  //   }]
  // },
  tooltips: {
    enabled: false,
  },
  legend: {
    display: false,
    // position: 'right',
    // labels: {
    //     // This more specific font property overrides the global property
    //     fontColor: 'rgba(255,255,255,.7)',
    //     fontFamily: 'Calibre, sans-serif',
    //     useLineStyle: true
    // }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          // Include a dollar sign in the ticks
          fontColor: "rgba(255,255,255,.5)",
          fontFamily: "Calibre, sans-serif",
          fontSize: window.innerWidth < 1800 ? 10 : 12,
          min: 0,
          max: 1,
        },
        gridLines: {
          zeroLineColor: "rgba(255,255,255,.3)",
          color: "transparent",
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          fontColor: "rgba(255,255,255,.5)",
          fontFamily: "Calibre, sans-serif",
          fontSize: window.innerWidth < 2000 ? 9 : 11.5,
          stepSize: 1,
          min: 0,
          max: 23,
        },
        gridLines: {
          zeroLineColor: "rgba(255,255,255,.3)",
          color: "transparent",
        },
      },
    ],
  },
}

export default class extends React.Component {
  state = {
    currentLength: "37",
    currentVariant: "t", // t for Transient
    chartState: initialState,
    ready: false, // fade in first section after loading an important image
    firstLoad: true,
  }

  handleLengthClick = (newLength) => {
    let newDataset =
      chartData[newLength]["data"][this.state.currentVariant] ||
      chartData[newLength]["data"]["t"]

    if (this.state.currentLength != newLength) {
      gsap.killTweensOf(this._chartText)

      gsap.to(this._chartText, {
        opacity: 0,
        duration: 0.2,
      })
    }

    setTimeout(() => {
      this.setState({
        currentLength: newLength,
        chartState: {
          ...initialState,
          datasets: newDataset,
        },
      })
    }, 100)
  }

  handleVariantClick = (newVariant) => {
    let newDataset =
      chartData[this.state.currentLength]["data"][newVariant] ||
      chartData[this.state.currentLength]["data"]["t"]

    if (this.state.currentVariant != newVariant) {
      gsap.killTweensOf(this._chartText)

      gsap.to(this._chartText, {
        opacity: 0,
        duration: 0.2,
      })
    }

    setTimeout(() => {
      this.setState({
        currentVariant: newVariant,
        chartState: {
          ...initialState,
          datasets: newDataset,
        },
      })
    }, 100)
  }

  onLeave(origin, dest, direction) {
    if (dest && dest.index === 1) {
      let els = document.querySelectorAll(`.${styles.chartVariants} > div`)
      gsap.fromTo(
        els,
        { opacity: 1 },
        { opacity: 0.5, delay: 2.5, duration: 1 }
      )
    }
    this.setState({firstLoad: false})
  }

  componentDidMount() {
    gsap.timeline().to(this._copy, {
      opacity: 0.9,
      duration: 1,
      delay: 1.75,
    })

    let delay = 0.2

    gsap.fromTo(
      [this._circlesLabel1, this._circlesLabel2, this._circlesLabel3],
      { opacity: 0 },
      { opacity: 0.9, delay: delay + 0.1, duration: 3, stagger: 0.2 }
    )
    gsap.to(this._circlesLabel1, { x: "-=50", delay, duration: 15 })
    gsap.to(this._circlesLabel3, { x: "+=50", delay, duration: 15 })

    // hide Circles until the main image is loaded, to prevent layout jumping
    let img = new Image()
    let that = this
    img.onload = function () {
      that.setState({ ready: true })
    }
    img.src = STXTblurred
  }

  render() {
    gsap.to(this._chartText, {
      opacity: 0.7,
      duration: 0.3,
      delay: 0.1,
    })

    let that = this
    return (
      <div className={Cx({ [styles.ready]: this.state.ready })}>
        <Navigation />
        <div className="bg" />
        <ImgBg src={background} className={"bg"} readyClassName={"bgReady"} />
        <LogoTribe />
        <ReactFullpage
          licenseKey={fullPageJsLicense}
          onLeave={this.onLeave.bind(this)}
          credits={{enabled: false}}
          render={({ state, fullpageApi }) => {
            if (fullpageApi && this.state.firstLoad)
              fullpageApi.silentMoveTo(1)

            return (
              <ReactFullpage.Wrapper>
                <div className={Cx("section", styles.sectionTuning)}>
                  <div className={Cx(styles.circlesSection)}>
                    <Circles />
                    <div
                      ref={(c) => {
                        this._circlesLabels = c
                      }}
                      className={Cx(styles.circlesLabels)}
                    >
                      <div
                        style={{ left: 0 }}
                        ref={(c) => {
                          this._circlesLabel1 = c
                        }}
                        className={Cx(styles.circlesLabel)}
                      >
                        STRAIGHT
                      </div>
                      <div
                        style={{ left: "50%", transform: "translateX(-50%)" }}
                        ref={(c) => {
                          this._circlesLabel2 = c
                        }}
                        className={Cx(styles.circlesLabel)}
                      >
                        TRANSIENT
                      </div>
                      <div
                        style={{ right: 0 }}
                        ref={(c) => {
                          this._circlesLabel3 = c
                        }}
                        className={Cx(styles.circlesLabel)}
                      >
                        EXPRESSIVE
                      </div>
                    </div>
                  </div>

                  <div
                    ref={(c) => {
                      this._copy = c
                    }}
                    className={Cx(styles.pageText)}
                  >
                    Every BLACKWING<sup>7</sup> lens is hand-built, using
                    proprietary production processes which allow the optical
                    performance of each lens in the range to be uniquely
                    configured and tuned to their owner’s wishes and creative
                    style.
                    <br />
                    Three pre-set tonalities are available to order.
                  </div>

                  <span onClick={() => fullpageApi.moveSectionDown()}>
                    <Arrow />
                  </span>
                </div>
                <div className="section">
                  <div
                    className={Cx("sectionInner", styles.chartSection, {
                      [styles.chartSectionActive]:
                        state.destination && state.destination.isLast,
                    })}
                  >
                    <div className={Cx(styles.chartVariants)}>
                      <div
                        className={Cx({
                          [styles.variantActive]:
                            this.state.currentVariant == "s",
                        })}
                        onMouseEnter={() => that.handleVariantClick("s")}
                      >
                        <div
                          className={Cx(styles.chartVariant)}
                          style={{ textAlign: "center" }}
                        >
                          <img src={STXS} />
                        </div>
                        <div className={Cx(styles.variantLabel)}>STRAIGHT</div>
                      </div>
                      <div
                        className={Cx({
                          [styles.variantActive]:
                            this.state.currentVariant == "t",
                        })}
                        onMouseEnter={() => that.handleVariantClick("t")}
                      >
                        <div
                          className={Cx(styles.chartVariant)}
                          style={{ textAlign: "center" }}
                        >
                          <img src={STXT} />
                        </div>
                        <div className={Cx(styles.variantLabel)}>TRANSIENT</div>
                      </div>
                      <div
                        className={Cx({
                          [styles.variantActive]:
                            this.state.currentVariant == "x",
                        })}
                        onMouseEnter={() => that.handleVariantClick("x")}
                      >
                        <div
                          className={Cx(styles.chartVariant)}
                          style={{ textAlign: "center" }}
                        >
                          <img src={STXX} />
                        </div>
                        <div className={Cx(styles.variantLabel)}>
                          EXPRESSIVE
                        </div>
                      </div>
                    </div>

                    <div className={Cx(styles.lengths)}>
                      {Object.keys(chartData).map((key, index) => (
                        <div
                          className={Cx(styles.length, {
                            [styles.lengthActive]:
                              this.state.currentLength == key,
                          })}
                          onMouseEnter={() => that.handleLengthClick(key)}
                          key={index}
                        >
                          <span>
                            <b>{key.slice(0, -1)}</b>7
                          </span>
                          MM
                        </div>
                      ))}
                    </div>

                    <div
                      className={Cx(styles.chartArea)}
                      ref={(c) => {
                        this._chartArea = c
                      }}
                    >
                      <div
                        ref={(c) => {
                          this._chartText = c
                        }}
                        className={Cx(styles.chartText, {
                          [styles.longChartText]:
                            this.state.currentLength == "77",
                        })}
                        dangerouslySetInnerHTML={{
                          __html:
                            texts[`F${this.state.currentLength}`][
                              this.state.currentVariant
                            ],
                        }}
                      />

                      <div className={Cx(styles.chartWrapOuter)}>
                        <div className={Cx(styles.chartWrap)}>
                          {this.state.chartState["datasets"] && (
                            <Line
                              width={440}
                              height={300}
                              data={this.state.chartState}
                              options={options}
                            />
                          )}
                        </div>
                        <div className={Cx(styles.legend)}>
                          <div>
                            <hr
                              style={{ borderTop: "2px solid var(--gold)" }}
                            />
                            <div>
                              5 LP/MM
                              <br />
                              SAGITTAL
                            </div>
                          </div>
                          <div>
                            <hr
                              style={{ borderTop: "2px dashed var(--gold)" }}
                            />
                            <div>
                              5 LP/MM
                              <br />
                              TANGENTIAL
                            </div>
                          </div>
                          <div>
                            <hr style={{ borderTop: "2px solid var(--red)" }} />
                            <div>
                              10 LP/MM
                              <br />
                              SAGITTAL
                            </div>
                          </div>
                          <div>
                            <hr
                              style={{ borderTop: "2px dashed var(--red)" }}
                            />
                            <div>
                              10 LP/MM
                              <br />
                              TANGENTIAL
                            </div>
                          </div>
                          <div>
                            <hr
                              style={{ borderTop: "2px solid var(--blue)" }}
                            />
                            <div>
                              20 LP/MM
                              <br />
                              SAGITTAL
                            </div>
                          </div>
                          <div>
                            <hr
                              style={{ borderTop: "2px dashed var(--blue)" }}
                            />
                            <div>
                              20 LP/MM
                              <br />
                              TANGENTIAL
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ReactFullpage.Wrapper>
            )
          }}
        />
      </div>
    )
  }
}
