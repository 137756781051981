// these should be the same as the breakpoints defined at the bottom of mq.scss
const breakpoints = {
  desktop:      980,
  mobile:       320,
  mobileWide:   375,
  tablet:       740,
  desktopWide:  1327,
  wide:         1300
}

export function getCoverDimensions(aspect, win_w, win_h) {
  let width,
    height,
    offset_left,
    offset_top,
    win_a = win_w / win_h
  if (win_a < aspect) {
    width = win_h * aspect
    height = win_h
    offset_left = (win_w - width) * 0.5
    offset_top = 0
  } else {
    width = win_w
    height = win_w / aspect
    offset_top = (win_h - height) * 0.5
    offset_left = 0
  }
  return [width, height, offset_left, offset_top]
}

export function getContainDimensions(aspect, win_w, win_h) {
  let width,
    height,
    offset_left,
    offset_top,
    win_a = win_w / win_h
  if (win_a < aspect) {
    width = win_w
    height = win_w / aspect
    offset_top = (win_h - height) * 0.5
    offset_left = 0
  } else {
    width = win_h * aspect
    height = win_h
    offset_left = (win_w - width) * 0.5
    offset_top = 0
  }
  return [width, height, offset_left, offset_top]
}

// Detect WebKit touch
export function isTouch() {
  return !!(
    "ontouchstart" in window ||
    (window.DocumentTouch && document instanceof window.DocumentTouch)
  )
}
if (isTouch()) {
  document.documentElement.classList.add("touch")
} else {
  document.documentElement.classList.add("no-touch")
}

export function isMobile() {
  return window.innerWidth < breakpoints["tablet"] || window.innerHeight < 420
}

export function isLandscape() {
  return Math.abs(window.orientation) === 90
}
