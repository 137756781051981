import React from "react"
import Cx from "classnames"
import styles from "./styles.scss"
import LogoTRaster from "~/app/components/LogoTRaster"
import Logo from "~/app/components/Logo"
import Videos from "./Videos"
import Navigation from "~/app/components/Navigation"
import LogoTribe from "~/app/components/LogoTribe"
import { isMobile } from "~/app/helpers.js"
import { Helmet } from "react-helmet"

import { gsap } from "gsap"

class Lensed extends React.Component {
  state = {
    descOpen: false,
    logoAnimationsComplete: false,
  }

  componentDidMount() {
    let tl = gsap.timeline()

    tl.to(this._el, {
      opacity: 0.7,
      duration: 1.2,
      delay: 0.4,
    })
    tl.to(this._el, {
      opacity: 0,
      duration: 1.2,
      delay: 2.3,
    })
  }

  componentWillUnmount() {}

  handleLogoAnimationsComplete = () => {
    this.setState({ logoAnimationsComplete: true })
  }

  handleVideoDescToggle = () => {
    const navigationDots = document.getElementById("fp-nav")
    if (!this.state.descOpen) {
      gsap.timeline().to(navigationDots, {
        opacity: 0,
        duration: 0.25,
      })
    } else {
      gsap.timeline().to(navigationDots, {
        opacity: 1,
        duration: 0.25,
      })
    }

    this.setState({
      descOpen: !this.state.descOpen,
    })
  }

  render() {
    const meta = {
      title: "BLACKWING7 lenses by TRIBE7",
      description: "Bring a greater expressive range, intentionality and unique visual authorship to your story."
    }

    return (
      <div className="descOpenGlobal">
        <Helmet>
          <title>{meta.title}</title>
          <meta name='description' content={meta.description} />
          <link rel="canonical" href={window.location.origin} />
        </Helmet>
        <Navigation
          descOpen={this.state.descOpen}
          logoAnimating={this.props.landingOnLensed}
          logoAnimationsComplete={this.state.logoAnimationsComplete}
        />
        <LogoTribe
          descOpen={this.state.descOpen}
          landingOnLensed={this.props.landingOnLensed}
        />
        <div>
          {!this.state.descOpen && !this.state.logoAnimationsComplete && (
            <div
              className={Cx(styles.logoArea, {
                [styles.hidden]: this.props.descOpen,
              })}
            >
              <LogoTRaster
                animated={true}
                logoAnimationsComplete={this.handleLogoAnimationsComplete}
              />
              <Logo />
              <div
                ref={(c) => {
                  this._el = c
                }}
                className={Cx(styles.logoAreaCaption)}
              >
                TUNABLE LARGE FORMAT
                {isMobile() && <br />}
                {!isMobile() && <span> </span>}
                CINE LENSES
              </div>
            </div>
          )}
          {(!isMobile() ||
            (isMobile() && this.state.logoAnimationsComplete)) && (
            <Videos
              onVideoDescToggle={this.handleVideoDescToggle}
              descOpen={this.state.descOpen}
              clickedNext={this.state.clickedNext}
              landingOnLensed={this.props.landingOnLensed}
            />
          )}
        </div>
      </div>
    )
  }
}

export default Lensed
