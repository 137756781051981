import React from "react"
import Cx from "classnames"
import Description from "~/app/components/Description"
import styles from "./styles.scss"

//  This component displays the credits for a video
class Credit extends React.Component {
  render() {
    const { descOpen, onLandingPage, onVideoDescToggle, credit } = this.props

    return (
      <div>
        <Description
          onDescToggle={onVideoDescToggle}
          descOpen={descOpen}
          onLandingPage={onLandingPage}
        >
          {credit.title}
        </Description>
        <div
          className={Cx(styles.videoDetail, {
            [styles.open]: descOpen,
          })}
        >
          <h3
            dangerouslySetInnerHTML={{
              __html: credit.title,
            }}
          />
          <div className={Cx(styles.videoDetailRow)}>
            <div className={Cx(styles.videoDetailLeft)}>Lenses</div>
            <div className={Cx(styles.videoDetailLenses)}>
              Blackwing<sup>7</sup> {credit.lenses}
            </div>
          </div>
          <div className={Cx(styles.videoDetailRow)}>
            <div className={Cx(styles.videoDetailLeft)}>Director</div>
            <div>{credit.director}</div>
          </div>
          <div className={Cx(styles.videoDetailRow)}>
            <div className={Cx(styles.videoDetailLeft)}>Cinematographer</div>
            <div>{credit.dop}</div>
          </div>
          <div className={Cx(styles.videoDetailRow)}>
            <div className={Cx(styles.videoDetailLeft)}>Format</div>
            <div>{credit.format}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Credit
