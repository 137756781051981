@import "~/app/helpers/helpers";

.page {
  padding: 150px calc(1 / 26 * 100vw) 0;
  // max-width: 1096px;
  margin: 0 auto;
  text-align: center;

  @include mq($until: tablet) {
    font-size: 14px;
    padding: 96px 26px 0;
  }
}

.gridContainer {
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 60px;
  height: 100vh;
  padding: 30px;
}

.bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('~/app/assets/images/bg.jpg');
  background-position: 50% 50%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0;
  transition: .5s opacity;
}
.bgReady {
  opacity: 1;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
}

.topLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoT {
  display: block;
  width: 44px;
}

.formContainer {
  text-align: center;

  > *+* {
    margin-top: 20px;
  }
}

.formHeading {
  color: var(--gold);
}

.formField {
  label {
    display: none;
  }
  input {
    border-bottom-width: 3px;
  }
}

.formButton {
  margin-top: 28px;
  letter-spacing: 5px;
}

.submitMessage {
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 2px;
}

.bottomLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomLogo {
  display: block;
  width: 44px;

  path {
    fill: var(--gold);
  }
}
