import React from "react"
import { Redirect, Route, Switch, withRouter } from "react-router-dom"
import Cx from "classnames"
import styles from "./styles.css"
import Lensed from "./Lensed"
import Lenses from "./Lenses"
import Tuning from "./Tuning"
import LensRange from "./LensRange"
import Specs from "./Specs"
import Credits from "./Credits"
import Inquire from "./Inquire"
import TermsAndConditions from "./TermsAndConditions"
import Linktree from "./Linktree"
import Obscura from "./Obscura"

class Root extends React.Component {
  state = {
    landingOnLensed: true, // when user is viewing their first page on the website
  }

  componentDidMount() {
    if (this.props.location.pathname !== "/") {
      this.setState({
        landingOnLensed: false,
      })
    }
  }
  componentDidUpdate(prevProps) {
    // Scroll to top on URL Change
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0)

      if (this.state.landingOnLensed === true) {
        this.setState({
          landingOnLensed: false,
        })
      }
    }
  }

  render() {
    return (
      <div className={Cx(styles.wrap)}>
        <Switch>
          <Route path="/lenses" component={Lenses} />
          <Route path="/tuning" component={Tuning} />
          <Route path="/lensrange" component={LensRange} />
          <Route path="/specs" component={Specs} />
          <Route path="/contact" component={Inquire} />
          <Route path="/credits" component={Credits} />
          <Route path="/termsandconditions" component={TermsAndConditions} />
          <Route path="/linktree" component={Linktree} />
          <Route path="/obscura37" component={Obscura} />
          <Route path="/obscura">
            <Redirect to="/obscura37" />
          </Route>
          <Route
            render={(props) => (
              <Lensed {...props} landingOnLensed={this.state.landingOnLensed} />
            )}
          />
        </Switch>
      </div>
    )
  }
}

export default withRouter(Root)
