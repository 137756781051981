import React from "react"
import { gsap } from "gsap"

import Cx from "classnames"
import styles from "./styles.scss"
import Navigation from "~/app/components/Navigation"
import LogoTribe from "~/app/components/LogoTribe"
import ImgBg from "~/app/components/ImgBg"
import background from "~/app/assets/images/bg.jpg"
import techSpecDiagram1 from "~/app/assets/images/specs-diagram-1.png"
import techSpecDiagram2 from "~/app/assets/images/specs-diagram-2.png"
import { apiBaseUrl } from "~/app/utils/apiConfig"
import { Helmet } from "react-helmet"

const DownloadArrow = () => (
  <svg className={Cx(styles.downloadArrow)} viewBox="0 0 36 37.83">
    <g>
      <polyline className={Cx(styles.cls1)} points="0.351 16.618 18 34.06 35.648 16.618"/>
      <line className={Cx(styles.cls1)} x1="0.322" y1="37.33" x2="35.677" y2="37.33"/>
      <line className={Cx(styles.cls1)} x1="18" x2="18" y2="34.295"/>
    </g>
  </svg>
)

export default class extends React.Component {
  state = {
    specificationData: [],
  }

  async componentDidMount() {
    const specs = document.querySelectorAll(`.${styles.chartRow}, .${styles.row}`)

    gsap.to([this._header, this._download], {
      duration: 1.2,
      opacity: 1,
      stagger: '0.2',
    })

    gsap.to([this._diagram, this._diagram2], {
      duration: 1,
      opacity: .7,
      delay: .7
    })

    gsap.to(specs, {
      duration: .5,
      opacity: 1,
      y: 0,
      stagger: '0.03',
      delay: .35
    })

    await this.fetchSpecificationsData()
  }

  // We need to fetch data on Specifications collection from Directus to get pdf_id
  // This is later used to access and download the file from Directus CMS
  fetchSpecificationsData = async () => {
    const res = await fetch(`${apiBaseUrl}/items/specifications`)
    const parsedSpecificationsData = await res.json()
    this.setState({ specificationData: parsedSpecificationsData.data })
  }


  render() {
    const { specificationData } = this.state
    const meta = {
      title: "Specifications",
      description: "Technical specifications for BLACKWING7 lenses."
    }

    return (
      <div>
        <Helmet>
          <title>{meta.title}</title>
          <meta name='description' content={meta.description} />
          <link rel="canonical" href={`${window.location.origin}/specs`} />
        </Helmet>
        <Navigation/>
        <ImgBg src={background} className={'bg'} readyClassName={'bgReady'} />
        <LogoTribe/>
        <div className={Cx(styles.page)}>

          <div ref={c=>{this._header=c}} className={Cx(styles.header)}>{'TECHNICAL SPECS'}</div>
          <div ref={c=>{this._download=c}} className={Cx(styles.download)}>
            <a href={`${apiBaseUrl}/assets/${specificationData.pdf_id}?download`} download>
              <DownloadArrow/>
              DOWNLOAD
            </a>
          </div>
          <div ref={c=>{this._chart=c}} className={Cx(styles.chart)}>

            <div className={Cx(styles.chartRow, styles.chartLabels)}>
              <div><span>FOCAL LENGTH</span></div>
              <div><span>IMAGE CIRCLE
                <span className={styles.hideOnDesktop}>
                  <br />
                    MAX ILLUMINATION
                </span>
              </span></div>
              <div><span>CLOSE FOCUS</span></div>
              <div><span>WEIGHT</span></div>
            </div>
            <div className={Cx(styles.chartRow)}>
              <div className={styles.label}><span>mm</span></div>
              <div className={styles.label}><span>mm<span className={styles.hideOnMobile}> max illumination</span></span></div>
              <div className={styles.label}>
                <div><span>in</span></div><div><span>cm</span></div>
              </div>
              <div className={styles.label}>
                <div><span>lb</span></div><div><span>kg</span></div>
              </div>
            </div>

            <div className={Cx(styles.chartRow)}>
              <div>17</div>
              <div>52</div>
              <div>
                <div>8.7</div>&nbsp;<div>22.0</div>
              </div>
              <div>
                <div>3.8</div>&nbsp;<div>1.75</div>
              </div>
            </div>

            <div className={Cx(styles.chartRow)}>
              <div>20.7</div>
              <div>50</div>
              <div>
                <div>9.0</div>&nbsp;<div>23.0</div>
              </div>
              <div>
                <div>3.2</div>&nbsp;<div>1.5</div>
              </div>
            </div>

            <div className={Cx(styles.chartRow)}>
              <div>23.7</div>
              <div>50</div>
              <div>
                <div>10.3</div>&nbsp;<div>26.0</div>
              </div>
              <div>
                <div>3.2</div>&nbsp;<div>1.5</div>
              </div>
            </div>

            <div className={Cx(styles.chartRow)}>
              <div>27</div>
              <div>55</div>
              <div>
                <div>13.0</div>&nbsp;<div>34.0</div>
              </div>
              <div>
                <div>3.6</div>&nbsp;<div>1.7</div>
              </div>
            </div>

            <div className={Cx(styles.chartRow)}>
              <div>37</div>
              <div>60</div>
              <div>
                <div>18.0</div>&nbsp;<div>45.7</div>
              </div>
              <div>
                <div>3.4</div>&nbsp;<div>1.6</div>
              </div>
            </div>

            <div className={Cx(styles.chartRow)}>
              <div>47</div>
              <div>60</div>
              <div>
                <div>17.5</div>&nbsp;<div>44.5</div>
              </div>
              <div>
                <div>3.2</div>&nbsp;<div>1.5</div>
              </div>
            </div>

            <div className={Cx(styles.chartRow)}>
              <div>57</div>
              <div>60</div>
              <div>
                <div>19.0</div>&nbsp;<div>48.3</div>
              </div>
              <div>
                <div>3.5</div>&nbsp;<div>1.6</div>
              </div>
            </div>

            <div className={Cx(styles.chartRow)}>
              <div>77</div>
              <div>60</div>
              <div>
                <div>26.0</div>&nbsp;<div>66.0</div>
              </div>
              <div>
                <div>3.0</div>&nbsp;<div>1.4</div>
              </div>
            </div>

            <div className={Cx(styles.chartRow)}>
              <div>107</div>
              <div>62</div>
              <div>
                <div>27.0</div>&nbsp;<div>68.5</div>
              </div>
              <div>
                <div>3.2</div>&nbsp;<div>1.4</div>
              </div>
            </div>

            <div className={Cx(styles.chartRow)}>
              <div>137</div>
              <div>62</div>
              <div>
                <div>32.0</div>&nbsp;<div>81.3</div>
              </div>
              <div>
                <div>3.2</div>&nbsp;<div>1.5</div>
              </div>
            </div>

          </div>

          <div className={Cx(styles.rows)}>

            <div className={Cx(styles.row)}>
              <div className={Cx(styles.colLeft)}>
                <div className={Cx(styles.label)}>APERTURE</div>
                <div className={Cx(styles.line)}/>
              </div>
              <div className={Cx(styles.colRight)}>
                T1.8 to T22 for 77 & 107<span>mm</span><br/>
                T1.9 to T22 for all other focal lengths
              </div>
            </div>

            <div className={Cx(styles.row)}>
              <div className={Cx(styles.colLeft)}>
                <div className={Cx(styles.label)}>IRIS SYSTEM</div>
                <div className={Cx(styles.line)}/>
              </div>
              <div className={Cx(styles.colRight)}>non-linear, 14 blade</div>
            </div>

            <div className={Cx(styles.row)}>
              <div className={Cx(styles.colLeft)}>
                <div className={Cx(styles.label)}>Image Distortion</div>
                <div className={Cx(styles.line)}/>
              </div>
              <div className={Cx(styles.colRight)}>less than -4% / +0.5%</div>
            </div>

            <div className={Cx(styles.row)}>
              <div className={Cx(styles.colLeft)}>
                <div className={Cx(styles.label)}>Transmission waveband</div>
                <div className={Cx(styles.line)}/>
              </div>
              <div className={Cx(styles.colRight)}>420<span>nm</span> to <span>680nm</span></div>
            </div>

            <div className={Cx(styles.row)}>
              <div className={Cx(styles.colLeft)}>
                <div className={Cx(styles.label)}>Color and tonality</div>
                <div className={Cx(styles.line)}/>
              </div>
              <div className={Cx(styles.colRight)}>matched across the range</div>
            </div>

            <div className={Cx(styles.row)}>
              <div className={Cx(styles.colLeft)}>
                <div className={Cx(styles.label)}>Magnification Shift</div>
                <div className={Cx(styles.line)}/>
              </div>
              <div className={Cx(styles.colRight)}>less than 4% (from ∞ to close focus)</div>
            </div>

            <div className={Cx(styles.row)}>
              <div className={Cx(styles.colLeft)}>
                <div className={Cx(styles.label)}>Focus and Iris Backlash</div>
                <div className={Cx(styles.line)}/>
              </div>
              <div className={Cx(styles.colRight)}>negligible</div>
            </div>

            <div className={Cx(styles.row)}>
              <div className={Cx(styles.colLeft)}>
                <div className={Cx(styles.label)}>Flange system</div>
                <div className={Cx(styles.line)}/>
              </div>
              <div className={Cx(styles.colRight)}>PL mount</div>
            </div>

            <div className={Cx(styles.row)}>
              <div className={Cx(styles.colLeft)}>
                <div className={Cx(styles.label)}>
                  Focus Scale and Drive<br/>
                  17 <span>to</span> 137<span>mm</span>
                </div>
                <div className={Cx(styles.line)}/>
              </div>
              <div className={Cx(styles.colRight)}>
                windowed<br/>
                left/right in feet (meters on request)<br/>
                270-degree rotation<br/>
                gearing: 142 teeth, 0.8<span>mm</span> mod, 6<span>mm</span> width, 115.2<span>mm</span> dia
              </div>
            </div>

            <div className={Cx(styles.row)}>
              <div className={Cx(styles.colLeft)}>
                <div className={Cx(styles.label)}>Aperture Scale and Drive</div>
                <div className={Cx(styles.line)}/>
              </div>
              <div className={Cx(styles.colRight)}>
                non-windowed left/right<br/>
                90-degree rotation<br/>
                gearing: 134 teeth, 0.8<span>mm</span> mod, 3<span>mm</span> width, 108.8<span>mm</span> dia
              </div>
            </div>

            <div className={Cx(styles.row)}>
              <div className={Cx(styles.colLeft)}>
                <div className={Cx(styles.label)}>Operating Temperature Range</div>
                <div className={Cx(styles.line)}/>
              </div>
              <div className={Cx(styles.colRight)}>
              -20 to +45 C
              </div>
            </div>
          </div>

          <img ref={c=>{this._diagram=c}} className={Cx(styles.diagram)} src={techSpecDiagram1}/>
          <br />
          <img ref={c=>{this._diagram2=c}} className={Cx(styles.diagram)} src={techSpecDiagram2}/>
        </div>
      </div>
    )
  }
}
