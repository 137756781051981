import React from "react"
import Cx from "classnames"
import { withRouter, NavLink } from 'react-router-dom'
import { gsap } from 'gsap'

import { isMobile } from "~/app/helpers"
import styles from "./styles.scss"
import LogoT from "~/app/components/LogoT"
import LogoTribe from "~/app/components/LogoTribe"
import IconX from "~/app/components/IconX"

class Navigation extends React.Component {
  state = {
    mobileOpen: false
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize)

    this.animateNavComponent();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
  }

  onResize = () => {
    this.toggleDesktopMobileNav()
  }

  // change menu opacity for mobile & desktop when the window is resized
  toggleDesktopMobileNav = () => {
    const navDesktop = document.querySelectorAll(`.${styles.item}`)
    const navMobile = document.querySelectorAll(`.${styles.navMobile}`)

    if (isMobile()) {
      navDesktop.forEach(e => e.style.opacity="0");
      navMobile.forEach(e => e.style.opacity="1");
    } else {
      navDesktop.forEach(e => e.style.opacity="1");
      navMobile.forEach(e => e.style.opacity="0");
    }
  }

  animateNavComponent = () => {
    if (this.props.logoAnimating) {
      let tl = gsap.timeline()

      if (isMobile()) {
        let el = [].slice.call(document.querySelectorAll(`.${styles.navMobile}`))

        tl.to(el, {
          opacity: 1,
          duration: 0.4,
          delay: 5
        })

      } else {
        // load all the nav items, convert Nodelist to array
        let els = [].slice.call(
          this._el.querySelectorAll(`.${styles.item}`)
        )
        
        tl.to(
          els,
          {
            opacity: 1,
            duration: .3,
            stagger: {
              amount: .2,
            },
            delay: 1.4
          }
        )
      }
    }
  }

  handleMobileClick = () => {
    if (this.state.mobileOpen) {
      gsap.timeline()
        .to(document.querySelectorAll(`.${styles.navMobile} ul`), {
          opacity: 0,
          duration: 0.3
        })
        .to(document.querySelectorAll(`.${styles.navMobile} li`), {
          opacity: 0,
          duration: 0,
          delay: 0.1
        })

      this.closeTimer = setTimeout(() => {
        this.setState({
          mobileOpen: false
        })
      }, 310)
    } else {
      gsap.timeline()
        .to(document.querySelectorAll(`.${styles.navMobile} ul`), {
          opacity: 1,
          duration: 0.3
        })
        .to(document.querySelectorAll(`.${styles.navMobile} li`), {
          opacity: 1,
          duration: 0.3,
          stagger: {
            amount: 0.2
          },
          delay: -0.3
        })

      this.setState({
        mobileOpen: true
      })
    }

  }

  render() {
    if (this.props.hideLinks) {
      if (!isMobile) {
        return (
          <div>
            <div
              className={Cx(styles.navigation, {
                [styles.hidden]: this.props.descOpen,
                [styles.logoAnimations]: this.props.logoAnimating
              })}
              ref={c=>{this._el=c}}
            >
              <ul>
                <li><NavLink className={Cx(styles.item)} activeClassName={Cx(styles.active)} exact to="/"><LogoT className={Cx(styles.logoT, styles.item)} /></NavLink></li>
              </ul>
            </div>

            <div
              className={Cx(styles.navMobile, {
                [styles.hidden]: this.props.descOpen,
                [styles.logoAnimations]: this.props.logoAnimating,
                [styles.mobileOpen]: this.state.mobileOpen
              })}
            >
              <div className={Cx(styles.navMobileArea)}>
                <LogoT className={Cx(styles.logoT)} />
              </div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div>
          <div
            className={Cx(styles.navigation, {
              [styles.hidden]: this.props.descOpen,
              [styles.logoAnimations]: this.props.logoAnimating
            })}
            ref={c=>{this._el=c}}
          >
            <ul>
              <li><NavLink className={Cx(styles.item)} activeClassName={Cx(styles.active)} exact to="/"><LogoT className={Cx(styles.logoT, styles.item)} /></NavLink></li>
              <li><NavLink className={Cx(styles.item)} activeClassName={Cx(styles.active)} to="/lenses">BLACKWING<sup>7</sup></NavLink></li>
              <li><NavLink className={Cx(styles.item)} activeClassName={Cx(styles.active)} to="/lensrange">LENS RANGE</NavLink></li>
              <li><NavLink className={Cx(styles.item)} activeClassName={Cx(styles.active)} to="/tuning">TUNING</NavLink></li>
              <li><NavLink className={Cx(styles.item)} activeClassName={Cx(styles.active)} to="/specs">SPECS</NavLink></li>
              <li><NavLink className={Cx(styles.item)} activeClassName={Cx(styles.active)} to="/credits">CREDITS</NavLink></li>
              <li><NavLink className={Cx(styles.item)} activeClassName={Cx(styles.active)} to="/contact">CONTACT</NavLink></li>
            </ul>
          </div>

          <div
            className={Cx(styles.navMobile, {
              [styles.hidden]: this.props.descOpen,
              [styles.logoAnimations]: this.props.logoAnimating,
              [styles.mobileOpen]: this.state.mobileOpen
            })}
          >
            <div className={Cx(styles.navMobileArea)}>
              <LogoT className={Cx(styles.logoT)} />
              <div
                className={styles.navMobileButton}
                onClick={this.handleMobileClick}
              >
                {this.state.mobileOpen ? <IconX/> : '＝'}
              </div>
            </div>
            <ul>
              <li><NavLink activeClassName={Cx(styles.active)} exact to="/">FEATURED</NavLink></li>
              <li><NavLink activeClassName={Cx(styles.active)} to="/lenses">BLACKWING<sup>7</sup></NavLink></li>
              <li><NavLink activeClassName={Cx(styles.active)} to="/lensrange">LENS RANGE</NavLink></li>
              <li><NavLink activeClassName={Cx(styles.active)} to="/tuning">TUNING</NavLink></li>
              <li><NavLink activeClassName={Cx(styles.active)} to="/specs">SPECS</NavLink></li>
              <li><NavLink activeClassName={Cx(styles.active)} to="/credits">CREDITS</NavLink></li>
              <li><NavLink activeClassName={Cx(styles.active)} to="/contact">CONTACT</NavLink></li>
              <li><LogoTribe className={styles.logoTribeNav}/></li>
            </ul>
          </div>
        </div>
      )
    }
  }
}

export default withRouter(Navigation)
