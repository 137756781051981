import React from "react"
import gsap from "gsap"
import { CustomEase } from "gsap/CustomEase"
import Cx from "classnames"

import styles from "./styles.scss"
import Navigation from "~/app/components/Navigation"
import Video from "~/app/components/Video"
import LogoTribe from "~/app/components/LogoTribe"
import ImgBg from "~/app/components/ImgBg"
import background from "~/app/assets/images/bg.jpg"

export default class extends React.Component {
  componentDidMount() {
    this._player.play()

    gsap.registerPlugin(CustomEase)
    gsap.to(document.querySelectorAll(`.${styles.textAreaInner}`), {
      opacity: 1,
      duration: 2.6,
      ease: CustomEase.create(
        "custom",
        "M0,0 C0,0 0.09116,0.26782 0.15086,0.43074 0.15694,0.44733 0.16234,0.45808 0.17283,0.47169 0.19642,0.50228 0.21314,0.52234 0.24249,0.54956 0.31205,0.61403 0.35449,0.65299 0.43055,0.71057 0.51315,0.77311 0.56472,0.80796 0.65357,0.86077 0.72937,0.90584 0.77865,0.92848 0.85807,0.96473 0.88838,0.97856 0.90924,0.98412 0.94124,0.99208 0.96291,0.99746 1,1 1,1"
      ),
      delay: 1.5
    })

    
  }

  render() {
    if (this._player) this._player.play()

    return (
      <div>
        <Navigation />
        <LogoTribe />
        <ImgBg
          src={background}
          className={styles.bg}
          readyClassName={styles.bgReady}
        />
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: "-1"
          }}
        >
          <Video
            // poster='https://i.vimeocdn.com/video/821929072_1920x1080.jpg?r=pad'
            src="https://bwing-dev.s3.amazonaws.com/Blackwing+Lens_Mobile8-Gamma2-422HQ-Loop.mp4"
            height="1920"
            width="1080"
            isCurrentOrNext={true}
            innerRef={c => {
              this._player = c
            }}
            loop={true}
            coverCropReposition={true}
          />
        </div>

        <div className={styles.textArea}>
          <div className={styles.textAreaInner}>
            <br/>
            <br/>
            <div className={Cx(styles.header)}>
              TUNABLE<br/>
              LARGE FORMAT<br/>
              CINE LENSES
            </div>
            <br/>
            <p>
              Purpose-designed for <span>large format motion picture</span> imaging, <span>BLACKWING<sup>7</sup></span> lenses from TRIBE<sup>7</sup> represent an entirely new range of tuned optics for contemporary film makers to use within their creative story telling.
            </p>
            <br />
            <p>
              BLACKWING<sup>7</sup> optics exhibit unique imaging properties which arise from the introduction of controlled distortion during the development and manufacturing processes. This distortion is modular and allows a level of tuneability over key parameters which shape many creative characteristics of the lenses.
            </p>
            <br />
            <p>The lens range comprises nine focal lengths: a core set of seven plus two additional wide lenses, configurable and customisable at point of manufacture.</p>
            <br/>
            <p>BLACKWING<sup>7</sup> lens tuning allows parameters such as sharpness, contrast, roll-off, spherical aberration, field curvature, edge halation and flare to be modified to provide sets of “curated” optics to suit the personality and intent of the owner.</p>
            <br/>
            <p>Many of the design cues for BLACKWING<sup>7</sup> primes originate in optics manufactured during the 1930 - 1960s, but the real inspiration behind the lenses lies in the evolutionary nature of experimental music and audio synthesis, and the process of creating distortion through sound amplification.</p>
            <br/>
            <p>Conceptually, the ability to tune each BLACKWING<sup>7</sup> lens is identical to how EQ adjustment is used in music production - to control the range, or shape of tonality in specific bands across the audio frequency spectrum. The image produced by a BLACKWING<sup>7</sup>  lens can be described as having a musical fidelity to it; with each focal length having an underlying level of distortion which can be controlled to produce strong artistic resonances including unique flaring characteristics.</p>
            <br/>
            <p>Ten sets of BLACKWING<sup>7</sup> BINARIES were created by TRIBE<sup>7</sup> to help introduce the film making community to the concept of audio merging into imaging. These limited-edition BINARIES are named in recognition of creative imaging not being a black or white process – film makers accessing a wide choice of optics is essential to serve creative storytelling. BLACKWING<sup>7</sup> BINARIES have been widely used on commercials, music videos, TV and feature productions and all feedback from these unique lenses has contributed to the production tuning of the BLACKWING<sup>7</sup> prime lenses which are now shipping.</p>
            <br />
          </div>
        </div>
      </div>
    )
  }
}
