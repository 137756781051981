import React from "react"
import Cx from "classnames"
import styles from "./styles.scss"

const FormField = ({
  label,
  component,
  className,
  name,
  value,
  error,
  onChange,
  attrs,
}) => {
  const fieldClass = Cx(styles.formField, {
    [styles.error]: error,
  }, className)

  return (
    <div className={fieldClass}>
      <label htmlFor={name}>{label}</label>
      {component === "textarea" ? (
        <textarea
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          {...attrs}
        />
      ) : (
        <input
          id={name}
          name={name}
          type={attrs.type || "text"}
          value={value}
          onChange={onChange}
          {...attrs}
        />
      )}
      {error && <p className={styles.errorText}>{error}</p>}
    </div>
  )
}

export default FormField
