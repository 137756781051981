@import "~/app/helpers/helpers";


.navigation {

  opacity: 1;
  transition: .3s opacity;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 2;

  &.hidden {
    opacity: 0;
  }

  @include mq($until: tablet) {
    display: none;
  }

  @include mq($until: desktop, $and: '(max-height: 420px)') {
    display: none;
  }

  .logoT {
    width: 34px;
    margin-bottom: 5px;
  
    @include mq($from: xwide) {
      width: 42px !important;
    }
  
    path {
      fill: var(--gold);
      transition: .3s all;
    }
  }
  
  ul {
    display: flex;
    justify-content: space-between;
    padding: 20px calc(1 / 26 * 100%) 20px;

    @include mq($from: xxwide) {
      padding-top: 26px;
    }
  }

  &.logoAnimations .item {
    opacity: 0;
  }

  li {
    font-size: 14px;
    color: var(--gold);
    list-style: none;
    font-weight: bold;

    @include mq($from: xxwide) {
      font-size: 16px;
    }

    @include mq($until: tablet) {
      font-size: 12.5px;
    }

    &:first-child {
      @include mq($until: tablet) {
        // display: none;
      }
    }

    a {
      display: block;
      padding: 8px;
      transition: .3s color;
    }
    
    a:hover {
      color: var(--white);
    }
    
    a:hover .logoT path {
      fill: var(--white);
    }
  }



  .active {
    color: var(--white);

    .logoT path {
      fill: var(--white);
    }
  }

}



.navMobile {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 101; // must be above fp-nav navigation dots
  padding-top: 15px;

  @include mq($until: tablet) {
    display: block;
  }
  @include mq($until: desktop, $and: '(max-height: 420px)') {
    display: block;
  }

  &.logoAnimations {
    opacity: 0;
  }


  .navMobileArea {
    position: relative;
    z-index: 2;
    height: 50px;
  }

  .logoT {
    display: block;
    margin: 0 auto;
    width: 44px;
    margin-bottom: 5px;  
  }

  .navMobileButton {
    width: 20px;
    margin: 2px auto 0;
    padding-top: 40px;
    top: -40px;
    left: -1.5px;
    text-align: center;
    position: relative;
    opacity: .8;
    font-size: 24px;
    line-height: 0.6;
    color: var(--gold);
  }

  &.mobileOpen .navMobileButton {
    left: 0px;
    width: 14px;
  }
  
  ul {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: black;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    padding: 140px 0;
    text-align: center;
    opacity: 0;

    @include mq($until: tablet, $and: '(orientation: landscape)') {
      padding: 70px 0;
    }

    @include mq($until: desktop, $and: '(max-height: 420px)') {
      padding: 70px 0;
    }
  }

  &.mobileOpen ul {
    display: flex;
  }

  li {
    color: white;
    opacity: 1;
    letter-spacing: 5px;
    opacity: 0;
    list-style: none;
  }

  .active {
    color: var(--gold);
  }

  .logoTribeNav {
    display: block;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
  }
}

