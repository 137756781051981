@import "~/app/helpers/helpers";

.videoDetail {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);  
  z-index: 10002;
  text-transform: uppercase;
  transition: .3s opacity;
  pointer-events: none;

  @include mq($from: xxwide) {
    font-size: 19px;
  }

  @include mq($until: tablet ){
    padding: 0 56px;
    font-size: 14px;    
  }

  h3 {
    font: var(--f2);
    font-weight: bold;
    font-size: 22px;
    letter-spacing: 7px;
    text-align: center;
    margin-bottom: 45px;
    opacity: 0;
    transition: .3s opacity;
  
    @include mq($from: xxwide) {
      font-size: 24px;
    }

    @include mq($until: tablet ){
      font-size: 18px;
    }
  }  
}
.videoDetail.open {
  pointer-events: auto;
  /* opacity: 1; */
}
.open h3 {
  opacity: 1;
}
.videoDetailRow {
  display: table;
  width: 100%;
  color: var(--gold);
  line-height: 1.6;
  opacity: 0;
  transition: .3s opacity;
  transition-delay: 0s;
}
.open .videoDetailRow {
  opacity: 1;
  transition-delay: .25s;
}
.videoDetailRow > div {
  display: table-cell;
  width: 50%;

}
.videoDetailRow span {
  font-variant: small-caps;
  text-transform: none;
}
.videoDetailLenses {
  color: white;
  opacity: .9;
}
.videoDetailLeft {
  text-align: right;
  padding: 0 20px 0 0;
}