@import "~/app/helpers/helpers";

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--brownFaded);
  z-index: 10001;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1.05s cubic-bezier(0.13, 0.69, 0, 0.93),
    visibility 1.05s cubic-bezier(0.13, 0.69, 0, 0.93);
  -webkit-tap-highlight-color: transparent;
}

.overlay.active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.posterDetail {
  opacity: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10002;
  text-transform: uppercase;
  transition: 0.3s opacity;
  pointer-events: none;
  padding: 12px;
  overflow: hidden;
  @include mq($from: tablet, $until: desktop) {
    font-size: 16px;
    padding: 12px;
  }

  hr {
    width: 90%;
    margin: 12px auto 20px auto;
    @include mq($from: tablet, $until: desktop) {
      margin: 8px auto 16px auto;
    }
  }
}

.posterDetail.open {
  pointer-events: auto;
  opacity: 1;
}

.posterDetailTitle {
  font-size: 26px;
  @include mq($from: tablet, $until: xwide) {
    font-size: 20px;
  }
  @include mq($from: xwide, $until: xxwide) {
    font-size: 18px;
  }
  @include mq($from: xxwide) {
    font-size: 23px;
  }
}

.posterDetailYear {
  font-size: 22px;
  color: var(--gold);
  @include mq($from: tablet, $until: xwide) {
    font-size: 16px;
  }
}

.posterDetailRow {
  margin-bottom: 20px;
  font-size: 18px;
  overflow-wrap: break-word;
  @include mq($from: tablet, $until: xwide) {
    font-size: 14px;
    margin-bottom: 12px;
  }
  @include mq($from: xwide, $until: xxwide) {
    font-size: 12px;
  }
  @include mq($from: xxwide) {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .posterDetailHeader {
    color: var(--gold);
  }
}

.posterDetailContent {
  letter-spacing: 6px;
  margin-bottom: 50px;
  @include mq($from: tablet, $until: desktop) {
    letter-spacing: 3px;
    margin-bottom: 20px;
  }
  @include mq($from: desktop) {
    margin-bottom: 15px;
  }
}

.posterDetailButton {
  border: 2px solid var(--darkGoldFaded);
  border-radius: 5%;
  padding: 8px 16px;
  font-size: 14px;
  p {
    display: inline;
  }
  @include mq($from: mobile, $until: tablet) {
    font-size: 10px;
  }
  @include mq($from: tablet) {
    font-size: 10px;
  }
}
