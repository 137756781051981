@import "~/app/helpers/helpers";

.logo {
  width: 93px;
  display: block;
  position: fixed;
  bottom: 20px;
  right: 20px;
  opacity: .6;
  padding: 20px;
  z-index: 1;

  @include mq($until: tablet) {
    display: none;
  }

  @include mq($until: desktop, $and: '(orientation: landscape)') {
    display: none;
  }

  &:hover {
    svg {
      @include mq($from: tablet) {
        opacity: 0;
      }
    }
    .hover {
      @include mq($from: tablet) {
        transform: translate(-50%, 0);
        opacity: 1;
      }
    }
  }
}
.logo svg {
  display: block;
  transition: .3s opacity;
}
.logo path {
  fill: var(--gold);
  transition: .3s fill;
}
.logo:hover path {
  fill: white;
}
.landingOnLensed {
  opacity: 0;
}

.hover {
  position: absolute;
  top: 27px;
  left: 50%;
  transform: translate(-50%, 2px);
  opacity: 0;
  transition: .3s all;
}