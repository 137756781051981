@import "~/app/helpers/helpers";

/* .lensesArea {
  background: black;

  background-image: radial-gradient(circle, rgba(149,122,63,.3) 0%, rgba(0,0,0,1) 56%);
  background-size: 80vw 80vw;
  background-position: 55% 50%;
  background-repeat: no-repeat;

}
.lensesAreaNoise {
  height: 100vh;

  background-image: url(/assets/images/noise.png);
  background-position: left top;
  background-repeat: repeat;
  opacity: .1;
} */

.firstSection {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 140px;
}

.header {
  margin-top: 17px;
  text-align: center;
  @extend %header;
}

.moreInfo {
  margin-top: 7px;
  font-size: 14px;
  text-align: center;
  color: var(--gold);

  span {
    color: white;
  }
}

.lengths {
  margin-top: 42px;
}

.length {
  color: var(--gold);
  opacity: 1;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
}
.length span {
  color: var(--white);
  font: var(--f3);
  font-size: 49px;
  letter-spacing: 0;

  @include mq($until: desktop) {
    font-size: 36px;
  }

  @media (max-height: 768px) {
    font-size: 40px;
  }
}
.lengthActive, .length:hover {
  opacity: 1;
}

.diagram img { 
  margin-top: 12px;
  width: 100%;
  opacity: .8;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left:0;
  z-index: 110;

  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('~/app/assets/images/bg.jpg');
  background-position: 50% 50%;

  display: none;

  
  &.open {
    display: block;
  }
}

.popupInner {
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  z-index: 111;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  padding: 80px 32px 20px;
}


.x {
  position: fixed;
  top: 15px;
  right: 18px;
  padding: 5px;
  color: var(--gold);
  opacity: .7;
  width: 38px;
}

.diagramSectionOuter {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  opacity: 0;
}

.diagramSectionInner {
  margin: 0 auto;
  width: calc(18 / 26 * 100vw);
  max-width: 1420px;
  transition: 4s opacity, 4s transform;

  @include mq($until: xwide) {
    width: calc(100vw - 80px - 80px);
  }

  @include mq($until: desktop) {
    width: auto;
    padding: 0 calc(2 / 26 * 100vw);
  }
  
}

.diagramText {
  margin-top: 18px;
  line-height: 1.25;
  letter-spacing: 0.6px;
  opacity: .7;
  font-size: 16px;
}

.inspiration {
  ul {
    list-style-type: none;
    letter-spacing: 0.6px;
    li::before {
      content: "—";
      margin-right: 5px;
    }
  }
  margin-bottom: 20px;
  opacity: .7;
  sup {
    top: -0.7em;
  }
}

.inspirationHeader {
  margin-bottom: 10px;
  font-weight: bold;
  letter-spacing: 1.6px;
}

.testimonialQuote {
  font: var(--f1);
  margin-top: 40px;
  line-height: 1.25;
  letter-spacing: 0.6px;
}

.author {
  text-align: right;
  text-transform: uppercase;
  margin-top: 12px;
  letter-spacing: 1.6px;
  .authorName {
    font-weight: bold;
    letter-spacing: 2.5px;
  }
  .authorCompany {
    font-style: italic;
  }
}

.bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('~/app/assets/images/bg.jpg');
  background-position: 50% 50%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0;
  transition: .5s opacity;
}
.bgReady {
  opacity: 1;
}

.lensesBg {
  position: absolute;
}


.arrowWrap {
  opacity: 0;
  transition: .3s opacity;
}
.arrowWrap.visible {
  opacity: 1;
}
