import React from 'react'
import Cx from 'classnames'
import styles from './styles.scss'
import { gsap } from 'gsap'
import { isTouch } from '~/app/helpers.js'
import blackwingLogo from '~/app/assets/images/blackwing-logo.png'


class Logo extends React.Component {
  componentDidMount() {
    let tl = gsap.timeline()

    tl.to(
      this._el,
      {
        opacity: 1,
        duration: 1.8,
        delay: .7
      }
    )
    
    if(!this.props.persist) {
      tl.to(
        this._el,
        {
          opacity: 0,
          duration: 1.2,
          delay: 1
        }
      )
    }
  }

  render() {
    return (
      <img
        ref={c=>{this._el=c}}
        className={Cx('logo', styles.logo)} 
        alt="Blackwing7 logo"
        src={blackwingLogo}
      />
    )
  }
}

export default Logo