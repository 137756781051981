@import "~/app/helpers/mq";

.logo {
  width: 560px;
  max-width: 100%;
  display: block;
  opacity: 0;

  @include mq($from: xwide) {
    width: calc(8.5 / 26 * 100vw);
    max-width: 1000px;
  }

  @include mq($until: desktop, $and: '(max-height: 420px)') {
    width: 400px;
    margin: 0 auto;
  }
}

svg path {
  fill: var(--white);
}