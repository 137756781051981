import React, { useState, useEffect } from "react"
import Cx from "classnames"
import styles from "./styles.scss"
import { apiBaseUrl } from "~/app/utils/apiConfig.js"
import CreditOverlay from "~/app/components/CreditOverlay"
import { isMobile } from "~/app/helpers.js"

// This component displays the poster from data fetched from Directus CMS.
// It includes behaviour for rendering the credits.
const Poster = (props) => {
  // We can modify the image from Directus is query params.
  // format - allows us to specify a format
  // quality - takes a value from 1 to 100
  const baseSrcUrl = `${apiBaseUrl}/assets/${props.image}?format=webp&quality=`
  const lowQualitySrcUrl = `${baseSrcUrl}1`
  const highQualitySrcUrl = `${baseSrcUrl}90`

  const [blur, setBlur] = useState(true)
  const [src, setSrc] = useState(lowQualitySrcUrl)
  const [showCredits, setShowCredits] = useState(false)

  useEffect(() => {
    swapImgToHighRes()
  }, [])

  // Load the high resolution data in the background
  // And swap from low resolution when loaded
  const swapImgToHighRes = () => {
    const img = new Image()
    img.src = highQualitySrcUrl
    img.onload = () => {
      setSrc(highQualitySrcUrl)
      setBlur(false)
    }
  }

  const handleToggleCredits = () => {
    setShowCredits(!showCredits)
  }

  const tuning_copy = () => {
    const tuningName = props.tuning.name
    if (tuningName === "Custom") {
      return `Custom ${props.custom_tuning_type}`
    } else if (tuningName === "Binaries") {
      return `Binary ${props.custom_tuning_type}`
    }
    return tuningName
  }

  return (
    <div className={Cx(styles.posterWithTuning)}>
      <div className={Cx(styles.tuning)}>
        <p>
          <span>{tuning_copy()}</span>-Tuned
        </p>
      </div>
      <div
        className={Cx(styles.poster)}
        key={props.id}
        onClick={isMobile() ? handleToggleCredits : undefined}
        // Blocked mouse enter/leave behaviour on mobile
        // To prevent odd behaviour of credits overlay
        onMouseEnter={isMobile() ? undefined : handleToggleCredits}
        onMouseLeave={isMobile() ? undefined : handleToggleCredits}
      >
        <img
          src={src}
          className={Cx(
            styles.poster,
            { [styles.blurred]: blur },
            { [styles.clear]: !blur }
          )}
        />
        <CreditOverlay {...props} showCredits={showCredits} />
      </div>
    </div>
  )
}

export default Poster
