@import "~/app/helpers/helpers";

.page {
  padding: 150px calc(1 / 26 * 100vw) 0;
  // max-width: 1096px;
  margin: 0 auto;
  text-align: center;

  @include mq($until: tablet) {
    font-size: 14px;
    padding: 96px 26px 0;
  }
}

.list {
  max-width: 810px;
  margin: 85px auto 16px;
  text-align: left;
  list-style-type: decimal;
  list-style-position: outside;

  li {
    padding: 20px 0 0 4px;

    &:first-child {
      padding-top: 0;
    }

    &::marker {
      color: rgba(var(--goldValue), 0.8);
    }

    h3{
      color: rgba(var(--goldValue), 0.8);
      font-size: 17px;
      font-variant: small-caps;
      font-weight: normal;
    }

    p {
      color: rgba(var(--white), 0.8);
      font-size: 17px;
      letter-spacing: 0.6px;
      line-height: 21.25px;
      opacity: 0.7;
      padding: 8px 0;
    }
  }
}

.header {
  text-align: center;
  @extend %header;
}

.footer {
  color: var(--gold);
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 2px;
  margin: 85px auto 16px;
  max-width: 810px;
  padding: 5px;
  text-align: left;
  transition: opacity .3s;
}

.bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('~/app/assets/images/bg.jpg');
  background-position: 50% 50%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0;
  transition: .5s opacity;
}
.bgReady {
  opacity: 1;
}

