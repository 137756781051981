import React, { useEffect, useState } from "react"
import Cx from "classnames"
import styles from "./styles.scss"
import Navigation from "~/app/components/Navigation"
import LogoTribe from "~/app/components/LogoTribe"
import ImgBg from "~/app/components/ImgBg"
import Filter from "~/app/components/Filter"
import Poster from "~/app/components/Poster"
import background from "~/app/assets/images/bg.jpg"
import { useCredits } from "~/app/hooks/useCredits"
import { useProjectTypes } from "~/app/hooks/useProjectTypes"
import { useTuningTypes } from "~/app/hooks/useTuningTypes"
import { Helmet } from "react-helmet"

export default function Credits() {
  const { credits, creditsError, isLoadingCredits } = useCredits()
  const { projectTypes, projectTypesError, isLoadingProjectTypes } =
    useProjectTypes()
  const { tuningTypes, tuningTypesError, isLoadingTuningTypes } =
    useTuningTypes()

  const [selectedProjectType, setSelectedProjectType] = useState({})
  const [selectedTuning, setSelectedTuning] = useState({})

  const meta = {
    title: "Credits",
    description: "Discover the creative possibilities of using BLACKWING7 lenses."
  }

  // We want to set the default credit type to 'Film' when we first get onto the credits page
  useEffect(() => {
    if (projectTypes) {
      const featureType = projectTypes.find((type) => type.id === 1)
      setSelectedProjectType(featureType)
    }
  }, [projectTypes])

  // We want to set the default tuning type to 'All' when we first get onto the credits page
  useEffect(() => {
    if (tuningTypes) {
      const tuningType = tuningTypes.find((type) => type.name === "All")
      setSelectedTuning(tuningType)
    }
  }, [tuningTypes])

  // We filter credits based on their tuning type and their project type
  const filteredResults = () => {
    return credits.filter((credit) => {
      // If the project type doesn't match return false
      if (credit.project_type_id !== selectedProjectType.id) {
        return false
      }
      // If the tuning type is All, return true
      if (selectedTuning.name === "All") {
        return true
      }
      // Otherwise return true if the tuning type matches the selected tuning type
      return credit.tuning_type_id === selectedTuning.id
    })
  }

  const renderContent = () => {
    // TODO: We need to handle the loading and error state.
    // But we do not have any designs yet.
    // Let's see what things look like once we have the posters rendering.
    if (creditsError || projectTypesError || tuningTypesError) {
      return <div>Please refresh the page</div>
    }
    if (isLoadingCredits || isLoadingProjectTypes || isLoadingTuningTypes) {
      return <div>Loading...</div>
    }

    return (
      <>
        <div className={Cx(styles.filterOptions)}>
          <Filter
            onSelect={setSelectedProjectType}
            filterName={"Type"}
            options={projectTypes}
            selected={selectedProjectType}
          />
          <Filter
            onSelect={setSelectedTuning}
            filterName={"Tuning"}
            options={tuningTypes}
            selected={selectedTuning}
          />
        </div>
        <div className={Cx(styles.posters)}>
          {filteredResults().map((credit) => {
            const tuning = tuningTypes.find(
              (type) => type.id === credit.tuning_type_id
            )
            return <Poster key={credit.id} {...credit} tuning={tuning} />
          })}
        </div>
      </>
    )
  }

  return (
    <div>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
        <link rel="canonical" href={`${window.location.origin}/credits`} />
      </Helmet>
      <Navigation />
      <ImgBg src={background} className={"bg"} readyClassName={"bgReady"} />
      <LogoTribe />
      <div className={Cx(styles.page)}>
        <div className={Cx(styles.pageTitle)}>Production Credits</div>
        {renderContent()}
      </div>
    </div>
  )
}
