@import "~/app/helpers/helpers";

/* .lensesArea {
  background: black;

  background-image: radial-gradient(circle, rgba(149,122,63,.3) 0%, rgba(0,0,0,1) 56%);
  background-size: 80vw 80vw;
  background-position: 55% 50%;
  background-repeat: no-repeat;

}
.lensesAreaNoise {
  height: 100vh;

  background-image: url(/assets/images/noise.png);
  background-position: left top;
  background-repeat: repeat;
  opacity: .1;
} */

.firstSection {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 140px;
}

.header {
  text-align: center;
  @extend %header;
}



.pageBody {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;

  @include mq($from: xwide) {
    margin-top: 80px;
  }

}



.bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('~/app/assets/images/bg.jpg');
  background-position: 50% 50%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0;
  transition: .5s opacity;
}
.bgReady {
  opacity: 1;
}

.lensesBg {
  position: absolute;
}


.arrowWrap {
  opacity: 0;
  transition: .3s opacity;
}
.arrowWrap.visible {
  opacity: 1;
}

.arrow polyline {
  stroke: white;
}





.textArea {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  mask-image: linear-gradient(to bottom, transparent 45vh, black 59vh);

  @include mq($until: desktop, $and: '(orientation: landscape)') {
    mask-image: linear-gradient(to bottom, transparent 5vh, black 15vh);
  }
 }
.textAreaInner {
  opacity: 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  padding: 51vh 32px 0;

  line-height: 1.3;
  letter-spacing: 0.6px;
  font-size: 16px;
  color: var(--gold);

  @include mq($until: desktop, $and: '(orientation: landscape)') {
    padding-top: 7px;
    padding-right: 54%;
  }
}

.header {
  color: white;
  opacity: .8;
  text-align: center;
  letter-spacing: 3.2px;
}

