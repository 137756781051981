import React from "react"
import Cx from "classnames"
import styles from "./styles.scss"

// Displays a styled heading, currently only really intended for h1-h3.
// TODO: get the heading styles for h1-h6 from client in more detail.
const Heading = ({ level = '1', children, light, className }) => {
  const Tag = `h${level}`;

  return (
    <Tag className={Cx(styles.heading, { [styles['light']]: light }, className)}>
      {children}
    </Tag>
  )
}

export default Heading
