.logo {
  width: 34px;
  margin: 0 auto 32px;
  display: block;
}

/* .hidden .topPath {
  transform: scaleX(0);
  transition: 1.2s transform;
  transform-origin: center center;
}
.hidden .topAnimating {
  transform: scaleX(1);
}

.hidden .circlePath {
  transform: scale(0);
  transform-origin: 50% 68%;  
  transition: 1.2s transform;
}
.hidden .circleAnimating {
  transform: scale(1);
} */

.svg line {
  fill: none;
  stroke: #957a3f;
  stroke-width: 2px;
}