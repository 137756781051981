import React from 'react'
import Cx from 'classnames'
import styles from './styles.scss'
import { gsap } from 'gsap'
import STXS from "~/app/assets/images/STX-S.png"
import STXT from "~/app/assets/images/STX-T.png"
import STXX from "~/app/assets/images/STX-X.png"

class Circles extends React.Component {
  componentDidMount() {
    let delay = 0.2

    gsap.fromTo(this._circles, { opacity: 0 }, { opacity: .9, delay: delay, duration: 3 })
    gsap.fromTo(this._circle1, { opacity: .5 }, { opacity: .5, y: "-=15", delay: delay, duration: 15 })
    gsap.fromTo(this._circle2, { opacity: .5, filter: 'blur(0.5px)' }, { opacity: .5, filter: 'blur(1.5px)', delay: delay, duration: 15 })
    gsap.fromTo(this._circle3, { y: "+=0" }, { y: "+=15", delay: delay, duration: 15 })

    gsap.fromTo(
      [this._letter1, this._letter2, this._letter3], 
      { opacity: 0 }, 
      { opacity: .9, delay: delay + .1, duration: 3, stagger: .2 }
    )
    gsap.to(this._letter1, { y: "-=15", delay: delay, duration: 15 })
    gsap.to(this._letter3, { y: "+=15", delay: delay, duration: 15 })

    if (this._circle3) {
      let position = this._circle3.getBoundingClientRect()
    }
  }
  render() {
    return (
      <div className={Cx(styles.circlesWrap)}>
        <div ref={c=>{this._circles=c}} className={Cx(styles.circles)}>
          <svg
            className={Cx(styles.circle)}
            viewBox="0 0 100 100"
            ref={c=>{this._circle1=c}}
          >   
            <circle className={Cx(styles.circleStroke)} cx="50" cy="50" r="49"/>/>
          </svg>
          <svg
            style={{filter: "blur(1px)"}}
            className={Cx(styles.circle)}
            viewBox="0 0 100 100"
            ref={c=>{this._circle2=c}}
          >   
            <circle className={Cx(styles.circleStroke)} cx="50" cy="50" r="49"/>/>
          </svg>
          <svg
            style={{filter: "blur(3px)"}}
            className={Cx(styles.circle)}
            viewBox="0 0 100 100"
            ref={c=>{this._circle3=c}}
          >   
            <circle className={Cx(styles.circleStroke)} cx="50" cy="50" r="49"/>/>
          </svg>
        </div>

        <div ref={c=>{this._letters=c}} className={Cx(styles.letters)}>
            <img 
              className={Cx(styles.letter)}
              ref={c=>{this._letter1=c}}
              src={STXS}
            />
            <img 
              className={Cx(styles.letter, styles.letter2)}
              ref={c=>{this._letter2=c}}
              src={STXT}
            />
            <img 
              className={Cx(styles.letter)}
              ref={c=>{this._letter3=c}}
              src={STXX}
            />
        </div>
      </div>
    )
  }
}

export default Circles