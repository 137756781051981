const _temp0 = require("../img/F107.png");
const _temp1 = require("../img/F137.png");
const _temp2 = require("../img/F17.png");
const _temp3 = require("../img/F207.png");
const _temp4 = require("../img/F237.png");
const _temp5 = require("../img/F27.png");
const _temp6 = require("../img/F37.png");
const _temp7 = require("../img/F47.png");
const _temp8 = require("../img/F57.png");
const _temp9 = require("../img/F77.png");
module.exports = {
  "F107": _temp0,
  "F137": _temp1,
  "F17": _temp2,
  "F207": _temp3,
  "F237": _temp4,
  "F27": _temp5,
  "F37": _temp6,
  "F47": _temp7,
  "F57": _temp8,
  "F77": _temp9
}