import React from "react"
import Cx from "classnames"
import styles from "./styles.scss"

// This component creates a dark overlay over the poster.
// It also displays the credits from data fetched from Directus CMS.
const CreditOverlay = (props) => {
  const releaseDate = new Date(props.release_date)
  const releaseYear = releaseDate.getUTCFullYear()

  return (
    <div className={Cx(styles.overlay, { [styles.active]: props.showCredits })}>
      <div
        className={Cx(styles.posterDetail, {
          [styles.open]: props.showCredits,
        })}
      >
        <div className={Cx(styles.posterDetailContent)}>
          <div>
            <div className={Cx(styles.posterDetailTitle)}>{props.title}</div>
            <div className={Cx(styles.posterDetailYear)}>{releaseYear}</div>
          </div>
          <hr />
          <div>
            {props.director && (
              <div className={Cx(styles.posterDetailRow)}>
                <div className={Cx(styles.posterDetailHeader)}>Director</div>
                <div>{props.director}</div>
              </div>
            )}
            <div className={Cx(styles.posterDetailRow)}>
              <div className={Cx(styles.posterDetailHeader)}>
                Cinematographer
              </div>
              <div>{props.cinematographer}</div>
            </div>
          </div>
        </div>
        <a
          target="blank"
          href={props.trailer_url}
          className={Cx(styles.posterDetailButton)}
        >
          Watch
        </a>
      </div>
    </div>
  )
}

export default CreditOverlay
