@import "~/app/helpers/mq";

.logoArea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  pointer-events: none;

  @include mq($until: tablet) {
    width: calc(100% - 60px);
  }
}
.logoArea.hidden {
  opacity: 0;
}
.logoAreaCaption {
  margin-top: 38px;
  text-align: center;
  opacity: 0;

  @include mq($from: xxwide) {
    font-size: 19px;
  }
}