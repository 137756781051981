@import "~/app/helpers/helpers";

.circlesWrap {
  position: relative;
  /* max-width: 880px; */
  margin: 0 auto;
}

.circles {
  opacity: 0;  
}


.letters {
  position: relative;
  transform: translateY(-6%);

  @include mq($from: xxwide) {
    transform: translateY(-7.5%);
  }

}

.circle {
  width: 41.5%;
  position: absolute;
  /* transition: .1s filter; */
}
.circlesFill {
  stroke: none;
  fill: rgba(0,0,0,.2);
}
.circleStroke {
  stroke: var(--gold);
  fill: none;
  stroke-width: 0.2px;
}

.letter {
  width: 41.5%;
  position: absolute;
  top: 0;
  opacity: 0;
}